import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueries } from "react-query";
import { useMatch, useNavigate, useParams } from "react-router";
import {
  iserr,
  q_bikeinfo,
  q_splicing_add_txnque,
  q_splicing_enter_pair_validate,
  q_splicing_enter_pair_validate_red,
  q_splicing_pair_info,
  q_splicing_splice_requests,
  q_splicing_splicedocdb,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import {
  base64_to_json,
  cdelay,
  dec,
  from_time_mini,
  getv,
  jstr,
  nils,
  toeth,
  tofeth,
} from "../utils/utils.js";
import {
  Card,
  HeadC2L,
  Img,
  InpText,
  Tag,
} from "../components/utilityComps.js";
import { logoimg } from "../components/Layout.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faBookmark,
  faBox,
  faCheck,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faHashtag,
  faHatHard,
  faMars,
  faSquare,
  faTimesCircle,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import _, { set } from "lodash";
import { faHashnode } from "@fortawesome/free-brands-svg-icons";
import { useVaultContext } from "./Vault.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import SpliceArena from "./SpliceArena.js";
import { useAppContext } from "../App.js";
import { Loader01c } from "../components/anims.js";
import { elementmap, tablecn } from "../utils/cn_map.js";
import Splicing from "../contracts/Splicing/SplicingContract.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { ethers } from "ethers";
import { ElementTag, GenderTag } from "../components/ShortComps.js";
import moment from "moment";
import { SetBikeName } from "../components/BikeCard";
import { contractAddress_list } from "../contracts/constants.js";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { BikeImg } from "../components/BikeImg.js";
import colors from "tailwindcss/colors.js";
import { Link } from "react-router-dom";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { polygon } from "thirdweb/chains";

const SpliceContext = createContext();
const useSpliceContext = () => useContext(SpliceContext);

const genderbase = (gender) =>
  gender == "male"
    ? ["bg-blue-400", "text-blue-400", faMars]
    : ["bg-pink-400", "text-pink-400", faVenus];

const VaultBikes = ({ gender, set_popup }) => {
  const scon = useSpliceContext();
  const { selbikes, set_selbikes } = scon;

  const vcon = useAccountContext();
  const { bikesob } = vcon;

  const [searchtxt, set_searchtxt] = useState(null);

  const filtbikes = useMemo(() => {
    if (nils(searchtxt))
      return _.chain(bikesob)
        .values()
        .filter((e) => e.gender == gender)
        .value();
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(bikesob)
      .values()
      .map((h) => {
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [h, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [h, 2];
        else if (hname.startsWith(sear)) return [h, 3];
        else if (hname.includes(sear)) return [h, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .filter((e) => e.gender == gender)
      .map(0)
      .value();
    return filt;
  }, [jstr(bikesob), searchtxt]);

  return (
    <>
      <InpText
        {...{
          id: "search-splice-bike",
          placeholder: "Search Core",
          inpprops: {
            className: "w-full  text-white",
          },
          contprops: {
            className: "w-full bg-r2dark/80 text-white",
          },
          setter: (v) => {
            console.log("setter", v);
            if (nils(v)) v = null;
            set_searchtxt(v);
          },
        }}
      />
      <div className="h-[40vh] overflow-auto">
        {_.values(filtbikes).map((h) => {
          let in_stud = getv(h, "splice_core.in_stud");
          return (
            <div
              onClick={() => {
                if (in_stud) {
                  set_selbikes((o) => ({ ...o, [gender]: h.hid }));
                  set_searchtxt("");
                  set_popup(false);
                }
              }}
              className={twMerge(
                "fc-ss w-full resp-gap-2 bg-r2lig/20 rounded-md p-2 my-2",
                in_stud ? "cursor-pointer" : "",
              )}
            >
              <div className="fr-ss w-full resp-gap-2 min-w-[20rem] mx-2">
                <span className="resp-text--3">{h.hid}</span>
                <span className="resp-text-0">{h.name}</span>
              </div>
              <div className="fr-sc w-full resp-gap-2 text-acc0">
                <span className="resp-text--1">{h.type}</span>
                <span className="resp-text--1">{`F${h.fno}`}</span>
                <div className="flex-1"></div>
                {in_stud == true ? (
                  <span className="resp-text--1 text-green-400">{`IN STUD`}</span>
                ) : (
                  <span className="resp-text--1 text-red-400">{`NOT IN STUD`}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ArenaBikes = ({ gender, set_popup }) => {
  const scon = useSpliceContext();
  const { set_selbikes } = scon;

  return (
    <div className="w-[60vw] h-[50vh]">
      <SpliceArena
        {...{
          update_href: false,
          init_filt: {
            gender: [gender],
          },
          on_selection: (hid) => {
            set_selbikes((o) => ({ ...o, [gender]: hid }));
            set_popup(false);
          },
        }}
      />
    </div>
  );
};

const TemplateBike = ({ gender }) => {
  const [bg, text, icon] = genderbase(gender);
  const scon = useSpliceContext();
  const { selbikes, set_selbikes } = scon;
  const selbike = getv(selbikes, gender);

  const [popup, set_popup] = useState(false);

  const [q_seldoc] = useQueries([q_bikeinfo({ hid: selbike })]);
  const selhdoc = useMemo(() => {
    if (qissuccesss(q_seldoc)) return getv(q_seldoc, "data.result");
    else return null;
  }, [q_seldoc.dataUpdatedAt]);
  // useEffect(() => {
  //   console.log({ selbike }, selhdoc);
  // }, [selbike, jstr(selhdoc)]);

  const dets = nils(selbike) ? (
    <></>
  ) : q_seldoc.isLoading ? (
    <span className="text-acc4 resp-text--2">loading details...</span>
  ) : (
    <Card className={"bg-transparent w-full xs:max-w-[10rem] lg:max-w-[15rem]"}>
      <div className="fc-ss resp-text-0 w-max mx-auto">
        {[
          [faBookmark, _.capitalize(selhdoc.type), text],
          [faHashtag, `F${selhdoc.fno}`, text],
          [
            elementmap[selhdoc.element].icon,
            _.capitalize(selhdoc.element),
            elementmap[selhdoc.element].text,
          ],
          [faCircle, _.kebabCase(selhdoc.color), `text-${selhdoc.color}`],
        ].map(([icon, text, icon_cn]) => {
          return (
            <div className="fr-sc">
              <div className="w-[1.5rem]">
                <FontAwesomeIcon icon={icon} className={icon_cn} />
              </div>
              <span>{text}</span>
            </div>
          );
        })}
      </div>
    </Card>
  );

  const [seltab, set_seltab] = useState("vault");

  const selection_popup = (
    <>
      <div className="fr-cc my-2">
        <Tag
          onClick={() => {
            set_popup(true);
          }}
          className={twMerge(
            gender == "male"
              ? "bg-blue-400/30 border border-blue-400 text-white"
              : "bg-pink-400/30 border border-pink-400 text-white",
            " xs:w-[10rem] lg:w-[15rem] text-center h-[4rem] fc-cc",
          )}
        >
          <div class="fc-cc">
            {!nils(selhdoc) && (
              <div class="fr-sc">
                <span className="resp-text--1">{getv(selhdoc, "name")}</span>
              </div>
            )}
            <span className="resp-text--3">{`Click to select ${gender} bike`}</span>
          </div>
        </Tag>
      </div>
      <PopUp
        openstate={popup}
        overlayclose={false}
        className="w-full h-full bg-r2dark/60 fc-cc"
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
      >
        <Card className="w-full h-full card-dark-bg backdrop-blur-md border border-acc4 fc-cc resp-gap-2 overflow-auto">
          <PopupCloseBtn closepopup={() => set_popup(false)} />
          <div className="fr-cc resp-gap-2">
            <Tag
              onClick={() => set_seltab("vault")}
              className={twMerge(
                seltab == "vault"
                  ? "bg-acc0/40 text-white -skew-x-12"
                  : "bg-transparent text-acc0 skew-x-0",
                "",
              )}
            >
              Vault Bikes
            </Tag>
            <Tag
              onClick={() => set_seltab("arena")}
              className={twMerge(
                seltab == "arena"
                  ? "bg-acc0/40 text-white -skew-x-12"
                  : "bg-transparent text-acc0 skew-x-0",
                "",
              )}
            >
              Arena Bikes
            </Tag>
          </div>
          {seltab == "vault" && (
            <VaultBikes gender={gender} {...{ set_popup }} />
          )}
          {seltab == "arena" && (
            <ArenaBikes gender={gender} {...{ set_popup }} />
          )}
        </Card>
      </PopUp>
    </>
  );

  return (
    <div className="h-full">
      {selection_popup}
      <div
        class={twMerge(
          "w-max mx-auto rounded-md",
          gender == "male"
            ? "bg-blue-400/20 border-2 border-blue-400"
            : "bg-pink-400/20 border-2 border-pink-400",
        )}
      >
        <div
          className={twMerge(
            "mx-auto h-[15rem] xs:w-[10rem] lg:w-[15rem] relative ",
          )}
        >
          <div className="h-[10rem] xs:w-[6rem] lg:w-[10rem] absolute top-[2.5rem] xs:left-[2rem] lg:left-[2.5rem] bg-r2lig/20 backdrop-blur-md rounded-md">
            <div className="fr-cc">
              <div className="h-[10rem] w-[4rem] fc-cc">
                <FontAwesomeIcon
                  icon={icon}
                  className={twMerge(text, "text-[3.5rem]")}
                />
              </div>
              <div class="xs:hidden lg:block">
                <GenderBikeImg {...{ gender }} />
              </div>
            </div>
          </div>
        </div>
        {dets}
      </div>
    </div>
  );
};

const GenderBikeImg = ({ gender }) => {
  const gender_hex = useMemo(() => {
    let hex = gender == "male" ? colors.blue[400] : colors.pink[400];
    if (nils(hex)) return "FFFFFF";
    else return hex.slice(1);
  }, [gender]);
  return <BikeImg hex_code={gender_hex} />;
};

const FixedBike = ({ dir }) => {
  const scon = useSpliceContext();
  const { hdoc } = scon;
  const gender = hdoc.gender;
  const [bg, text, icon] = genderbase(gender);

  const dets = (
    <Card className={"w-full xs:max-w-[10rem] lg:max-w-[15rem]"}>
      <div className="fc-ss resp-text--2">
        {[
          [faBookmark, _.capitalize(hdoc.type), text],
          [faHashtag, `F${hdoc.fno}`, text],
          [
            elementmap[hdoc.element].icon,
            _.capitalize(hdoc.element),
            elementmap[hdoc.element].text,
          ],
          [faCircle, _.kebabCase(hdoc.color), `text-${hdoc.color}`],
        ].map(([icon, text, icon_cn]) => {
          return (
            <div className="fr-sc">
              <div className="w-[1.5rem]">
                <FontAwesomeIcon icon={icon} className={icon_cn} />
              </div>
              <span>{text}</span>
            </div>
          );
        })}
      </div>
    </Card>
  );
  return (
    <div className={twMerge("h-full")}>
      <div class="fc-sc w-max mx-auto">
        <div className="fr-cc my-2">
          <Tag
            className={
              "border border-white text-white xs:w-[10rem] lg:w-[12rem] text-center h-[4rem] fc-cc"
            }
          >
            <span className="font-regular resp-text--1">{hdoc.name}</span>
            <span className="font-thin resp-text--3">{`${hdoc.gender} ready to splice`}</span>
          </Tag>
        </div>
      </div>
      <div className={twMerge("fc-sc w-max mx-auto rounded-md", `${bg}/30`)}>
        <div>
          <div
            className={twMerge(
              "mx-auto h-[15rem] xs:w-[10rem] lg:w-[15rem] relative ",
            )}
          >
            <div className="h-[10rem] xs:w-[6rem] lg:w-[10rem] absolute top-[2.5rem] xs:left-[2rem] lg:left-[2.5rem] bg-r2dark/60 rounded-md">
              <div className="fr-cc">
                <div className="h-[10rem] w-[4rem] fc-cc">
                  <FontAwesomeIcon
                    icon={icon}
                    className={twMerge(text, "text-[3.5rem]")}
                  />
                </div>
                <div class="xs:hidden lg:block">
                  <GenderBikeImg {...{ gender }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {dets}
      </div>
    </div>
  );
};

const Stage_0 = ({ stage, set_next_allowed, pairinfo }) => {
  const scon = useSpliceContext();
  useEffect(() => {
    console.log({ stage });
    if (stage !== 0) return;
    if (
      !_.isEmpty(pairinfo.f) &&
      !_.isEmpty(pairinfo.m) &&
      _.isEmpty(pairinfo.f.errs) &&
      _.isEmpty(pairinfo.m.errs)
    ) {
      setTimeout(() => {
        set_next_allowed(true);
      }, 500);
    }
  }, [stage, jstr(pairinfo)]);

  const HInfo = (h) => {
    return (
      <>
        <p
          className={twMerge(
            "my-2 text-center resp-text-0",
            h.gender == "male" ? "text-blue-300" : "text-pink-300",
          )}
        >
          {h.name}#{h.hid}
        </p>
        <table className={twMerge(tablecn.table, "resp-text--1")}>
          <tbody>
            <tr className="thintdrow">
              <td>
                <span>FNo</span>
              </td>
              <td>
                <span>F{h.fno}</span>
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Type</span>
              </td>
              <td>
                <span>{_.capitalize(h.type)}</span>
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Element</span>
              </td>
              <td>
                <ElementTag element={h.element} className={"resp-text--3"} />
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Gender</span>
              </td>
              <td>
                <GenderTag gender={h.gender} className={"resp-text--3"} />
              </td>
            </tr>
            <tr className="thintdrow">
              <td>
                <span>Color</span>
              </td>
              <td>
                <div className="fr-sc resp-gap-2">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={`text-${h.color}`}
                  />
                  <span>{_.capitalize(h.color)}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="h-[2rem]"></div>
        {!_.isEmpty(h.errs) && (
          <div className="p-2 text-red-300 resp-text--1">
            <p className="text-center">Errors!!</p>
            {h.errs.map((e) => {
              return <p className="my-1"></p>;
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Parent Details
      </p>

      <div className="grid grid-cols-2 resp-gap-4">
        <Card
          className={twMerge(
            "w-full h-full bg-r2dark/60 border-t-2",
            "border-blue-500",
          )}
        >
          {HInfo(pairinfo.f)}
        </Card>
        <Card
          className={twMerge(
            "w-full h-full bg-r2dark/60 border-t-2",
            "border-pink-500",
          )}
        >
          {HInfo(pairinfo.m)}
        </Card>
      </div>
    </div>
  );
};

const Stage_1 = ({ stage, set_next_allowed, pairinfo }) => {
  const scon = useSpliceContext();
  useEffect(() => {
    console.log({ stage });
    if (stage !== 1) return;
    if (!_.isEmpty(pairinfo.baby_info)) {
      setTimeout(() => {
        set_next_allowed(true);
      }, 500);
    }
  }, [stage, jstr(pairinfo)]);

  const h = pairinfo.baby_info;

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Baby Traits
      </p>

      <div className="grid grid-cols-1 resp-gap-4">
        <Card
          className={twMerge(
            "min-w-[50%] w-max mx-auto h-full bg-r2dark/60 border-t-2",
            "border-green-500",
          )}
        >
          <table className={twMerge(tablecn.table, "resp-text--1")}>
            <tbody>
              <tr className="thintdrow">
                <td>
                  <span>FNo</span>
                </td>
                <td>
                  <span>F{h.fno}</span>
                </td>
              </tr>
              <tr className="thintdrow">
                <td>
                  <span>Type</span>
                </td>
                <td>
                  <span>{_.capitalize(h.type)}</span>
                </td>
              </tr>
              <tr className="thintdrow">
                <td>
                  <span>Element</span>
                </td>
                <td>
                  <ElementTag element={h.element} className={"resp-text--3"} />
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

const Stage_2 = ({
  stage,
  set_next_allowed,
  pairinfo,
  popdata,
  set_popdata,
}) => {
  const mmcon = useMetaMaskContext();

  const { aumode, vault, auth } = useAuthContext();
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const scon = useSpliceContext();
  const [stage_err, set_stage_err] = useState(null);

  const HInfo = (h) => {
    return (
      <table className={tablecn.table}>
        <tbody className="resp-text--2">
          <tr className="thintdrow">
            <td>Set Price:</td>
            <td>
              <span className="text-white">${dec(h.price, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>Type:</td>
            <td>
              <span className="text-white">{h.elty}</span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>Type Base:</td>
            <td>
              <span className="text-white">${dec(h.base, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>
              <div className="fc-ss">
                <span>Excess:</span>
                <span>=price - (3 x base)</span>
              </div>
            </td>
            <td>
              <span className="text-white">
                ${dec(Math.max(h.price - h.base * 3, 0), 2)}
              </span>
            </td>
          </tr>
          <tr className="thintdrow">
            <td>
              <div className="fc-ss">
                <span>Overage:</span>
                <span>=excess / 2</span>
              </div>
            </td>
            <td>
              <span className="text-white">${dec(h.overage, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow text-acc0">
            <td>
              <div className="fc-ss">
                <span>Owner Gets:</span>
              </div>
            </td>
            <td>
              <span className="text-acc0">${dec(h.finfee, 2)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  const DNAInfo = (h) => {
    return (
      <table className={tablecn.table}>
        <tbody className="resp-text--2">
          <tr className="thintdrow">
            <td>Base:</td>
            <td>
              <span className="text-white">${dec(h.base, 2)}</span>
            </td>
          </tr>

          <tr className="thintdrow">
            <td>
              <div className="fc-ss">
                <span>Overage Fee:</span>
                <span>=(DAD + MOM Overage)</span>
              </div>
            </td>
            <td>
              <span className="text-white">${dec(h.overage, 2)}</span>
            </td>
          </tr>
          <tr className="thintdrow text-acc0">
            <td>
              <div className="fc-ss">
                <span>DNA Gets:</span>
              </div>
            </td>
            <td>
              <span className="text-acc0">${dec(h.finfee, 2)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const [bgcpart, set_bgcpart] = useState(getv(popdata, "bgcpart") ?? null);

  const [bgcbal, set_bgcbal] = useState(null);
  const [bgcalw, set_bgcalw] = useState(null);
  const [updref, set_updref] = useState(1);
  const [approving, set_approving] = useState(false);
  useEffect(() => {
    const fn = async () => {
      try {
        const token = "BGC";
        const con =
          aumode == "thirdweb"
            ? await t3_asset_signer(token)
            : await mm_asset_signer(token);
        let bal = await con.balanceOf(vault);
        bal = parseFloat(tofeth(bal));

        let alw = await con.allowance(vault, contractAddress_list.splicing);
        alw = parseFloat(tofeth(alw));

        set_bgcbal(bal);
        set_bgcalw(alw);
      } catch (err) {
        console.log(err);
        set_bgcbal(null);
        set_bgcalw(null);
      }
    };
    fn();
  }, [!nils(bgcpart), aumode, updref]);

  const bgc_useresp = useMemo(() => {
    if (bgcpart == null) return null;
    if (nils(bgcbal)) return;

    let usebgc = parseFloat(getv(bgcpart, "usebgc"));
    if (usebgc > parseFloat(bgcbal))
      return { status: "error", err: "Insufficient BGC" };
    if (usebgc < 0) return { status: "error", err: "Invalid BGC" };

    let dnafee = parseFloat(getv(pairinfo, "prices.dna.finfee"));
    if (usebgc > dnafee)
      return { status: "error", err: `Can set max:${dec(dnafee, 2)}` };

    return { status: "success" };
  }, [jstr(bgcpart), bgcbal, jstr(pairinfo)]);

  const use_max_bgc = async () => {
    let dna = parseFloat(getv(pairinfo, "prices.dna.finfee"));
    let bal = Math.max(parseFloat(dec(bgcbal, 2) - 0.01), 0);
    let usebgc = Math.min(dna, bal);
    set_bgcpart({ usebgc });
  };

  useEffect(() => {
    console.log({ stage }, pairinfo.prices);
    if (stage !== 2) return;
    set_stage_err(null);

    if (!_.isEmpty(pairinfo.prices)) {
      if (nils(bgcpart)) {
        setTimeout(() => {
          set_next_allowed(true);
        }, 1000);
      } else {
        if (
          bgc_useresp?.status == "success" &&
          !nils(parseFloat(getv(bgcpart, "usebgc")))
        ) {
          if (bgcalw < parseFloat(getv(bgcpart, "usebgc")))
            set_next_allowed(false);
          else set_next_allowed(true);
        } else {
          set_next_allowed(false);
        }
      }
    } else {
      setTimeout(() => {
        set_stage_err("No Prices Found");
      }, 1000);
    }
  }, [stage, jstr(pairinfo), jstr(bgcpart), jstr(bgc_useresp)]);

  useEffect(() => {
    if (bgc_useresp?.status == "success" && !nils(getv(bgcpart, "usebgc")))
      set_popdata({ ...popdata, bgcpart });
    else set_popdata({ ...popdata, bgcpart: null });
  }, [jstr(bgc_useresp), jstr(bgcpart)]);

  const approve_bgc = async () => {
    try {
      set_approving(true);
      const token = "BGC";
      const con =
        aumode == "thirdweb"
          ? await t3_asset_signer(token)
          : await mm_asset_signer(token);

      let toval = parseFloat(getv(bgcpart, "usebgc"));
      toval = toval * 1;
      if (aumode == "thirdweb") {
        const approval = await t3_contract_call(
          token,
          "approve",
          [contractAddress_list.splicing, toeth(dec(toval, 6))],
          "txn",
          true,
          { active_account: active_account },
        );
        await cdelay(5 * 1e3);
      } else {
        const approval = await con.approve(
          contractAddress_list.splicing,
          toeth(dec(toval, 6)),
        );
        await approval.wait();
        await cdelay(5000);
      }
      set_updref(updref + 1);
    } catch (err) {
      console.log(err);
    }
    set_approving(false);
  };

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Price Chart
      </p>

      {stage_err && (
        <p className="text-red-300 my-2 border border-red- resp-p-1">
          {stage_err}
        </p>
      )}

      <div className="grid grid-cols-2 resp-gap-2 resp-p-2">
        <Card className={"w-full bg-r2dark/60 border-t-2 border-blue-400"}>
          {HInfo(pairinfo.prices.f)}
        </Card>
        <Card className={"w-full bg-r2dark/60  border-t-2 border-pink-400"}>
          {HInfo(pairinfo.prices.m)}
        </Card>
        <div className="col-span-2">
          <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
            {DNAInfo(pairinfo.prices.dna)}
          </Card>
        </div>

        <div className="col-span-2">
          <Card
            className={twMerge(
              "w-full",
              nils(bgcpart) ? "" : "bg-r2dark/60 border-t-2 border-acc0",
            )}
          >
            <div className="my-1">
              {!nils(bgcpart) && (
                <div className="resp-text--2">
                  {bgc_useresp && bgc_useresp.status == "error" && (
                    <p className="text-red-300 p-2 border border-red-300 rounded-md">
                      {bgc_useresp.err}
                    </p>
                  )}
                  {bgc_useresp && bgc_useresp.status == "msg" && (
                    <p className="text-green-300 p-2 border border-green-300 rounded-md">
                      {bgc_useresp.msg}
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="fr-sc p-2">
              {!nils(bgcpart) && (
                <div class="fc-ss">
                  <Tag className="text-acc0 font-digi">
                    {`balance: ${nils(bgcbal) ? "--" : dec(bgcbal, 2)} BGC`}
                  </Tag>

                  <Tag className="text-acc0 font-digi">
                    {`allowance: ${nils(bgcalw) ? "--" : dec(bgcalw, 2)} BGC`}
                  </Tag>
                </div>
              )}
              <div className="flex-1"></div>
              <Tag
                onClick={() => {
                  if (nils(bgcpart)) {
                    set_bgcpart({
                      use: true,
                    });
                  } else set_bgcpart(null);
                }}
                className={twMerge("text-slate-300 fr-sc resp-gap-1")}
              >
                <FontAwesomeIcon
                  className={twMerge(
                    "text-white",
                    !nils(bgcpart) ? "text-acc0" : "text-slate-300",
                  )}
                  icon={!nils(bgcpart) ? faCheck : faBox}
                />
                <span>Pay DNA Fee with BGC coins</span>
              </Tag>
            </div>

            {!nils(bgcpart) && !nils(bgcbal) && (
              <div className="fr-sc resp-gap-2">
                <span className="text-acc0 resp-text--1">Use BGC:</span>
                <InpText
                  {...{
                    id: `dnafee-bgc`,
                    placeholder: "...",
                    def_val: getv(bgcpart, "usebgc") ?? "",
                    // label: "Use BGC",
                    inpprops: {
                      className:
                        "w-[8rem] bg-r2dark/60 text-white resp-text--1",
                    },
                    contprops: {
                      className:
                        "w-[8rem] bg-r2dark/60 text-white resp-text--1",
                    },
                    setter: (v) => {
                      v = parseFloat(v);
                      if (nils(v)) v = null;
                      set_bgcpart({
                        ...bgcpart,
                        usebgc: v,
                      });
                    },
                  }}
                />
                <Tag
                  onClick={() => use_max_bgc()}
                  className={twMerge("bg-acc0/40 font-digi resp-text--1")}
                >
                  Use Max
                </Tag>
              </div>
            )}

            {!nils(bgcpart) &&
              bgc_useresp?.status == "success" &&
              getv(bgcpart, "usebgc") && (
                <div className="fr-cc resp-text--1 text-acc0 p-2">
                  <span>You Pay</span>
                  <span>
                    $
                    {`${dec(
                      parseFloat(getv(pairinfo, "prices.tot.finfee")) -
                        parseFloat(getv(bgcpart, "usebgc")),
                      2,
                    )} `}
                    [with WETH tokens]
                  </span>
                  <span>+</span>
                  <span>
                    ${`${dec(parseFloat(getv(bgcpart, "usebgc")), 2)} `}
                    [with BGC tokens]
                  </span>
                </div>
              )}

            {!nils(getv(bgcpart, "usebgc")) &&
              bgcalw < parseFloat(getv(bgcpart, "usebgc")) && (
                <div class="fr-sc">
                  <div class="flex-1"></div>
                  <p className="resp-text--1 font-digi text-acc4">
                    Need to approve to spending BGC
                  </p>
                  <Tag
                    onClick={() => {
                      if (approving) return;
                      approve_bgc();
                    }}
                    className={twMerge(
                      "fr-sc resp-gap-1 bg-acc4/40 text-white font-digi resp-text--1",
                      approving ? "bg-r2dark" : "",
                    )}
                  >
                    {approving ? (
                      <>
                        <Loader01c size="s" />
                        <span>Approving</span>
                      </>
                    ) : (
                      <span>Approve</span>
                    )}
                  </Tag>
                </div>
              )}
          </Card>
        </div>

        <div className="col-span-2">
          <div className="fr-cc resp-gap-4 resp-text-2">
            <span className="resp-text-1">Total Cost</span>
            <Tag className="border border-acc0 bg-r2dark/60 text-acc0 resp-text-2">
              <span>${dec(getv(pairinfo, "prices.tot.finfee"), 2)}</span>
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );
};

const Stage_3 = ({
  stage,
  set_next_allowed,
  pair_params,
  pairinfo,
  popdata,
  set_popdata,
}) => {
  const scon = useSpliceContext();
  const { aumode, vault } = useAuthContext();
  const { thirdweb_client, active_account } = useThirdWebLoginContext();
  const [stage_err, set_stage_err] = useState(null);

  const [qo_splice_requests] = useQueries([
    q_splicing_splice_requests(
      {
        father_coreid: pair_params.father_coreid,
        mother_coreid: pair_params.mother_coreid,
        vault,
      },
      { enabled: stage == 3 },
    ),
  ]);

  useEffect(() => {
    console.log({ stage }, pairinfo.prices);
    if (stage !== 3) return;
    set_stage_err(null);
    if (qo_splice_requests.isLoading) return;
    if (qiserr(qo_splice_requests)) {
      set_stage_err(`Error:${qiserr(qo_splice_requests)}`);
      return;
    }
  }, [stage, jstr(pairinfo), qo_splice_requests.dataUpdatedAt]);

  const spreqs = useMemo(() => {
    if (qissuccesss(qo_splice_requests))
      return getv(qo_splice_requests, "data.result");
    return [];
  });

  const gen_new_request = async () => {
    const splicing =
      aumode == "thirdweb"
        ? await Splicing.get_contract({ rpc: polygon.rpc, nosigner: true })
        : await Splicing.get_contract({});
    let resp = null;

    // const weth = await WETH_MockToken.get_contract();
    if (aumode == "thirdweb") {
      resp = await t3_contract_call(
        "splicing",
        "spliceRequest",
        [pair_params.father_coreid, pair_params.mother_coreid],
        "txn",
        true,
        { active_account: active_account },
      );
      await cdelay(2 * 1e3);
    } else {
      resp = await splicing.spliceRequest(
        pair_params.father_coreid,
        pair_params.mother_coreid,
      );
      console.log("spliceRequest resp", resp);
    }

    let logs = await splicing.contract.queryFilter(
      splicing.contract.filters.SpliceRequested,
      resp.blockNumber,
    );
    // console.log("logs", logs);
    let docid = null;
    for (let l of logs) {
      console.log(l);
      if (l.transactionHash == resp.hash) docid = l.args[0];
    }
    if (nils(docid))
      throw new Error(`Sorry couldnt Locate your requests,\n please retry`);
    docid = Number(docid);
    console.log("docid", docid);
    return { docid, hash: resp.hash };
  };

  const [genloading, set_genloading] = useState(false);

  const auto_gen_new_request = async () => {
    set_genloading(true);
    try {
      set_stage_err(null);
      let resp = await gen_new_request();
      let { docid, hash } = resp;
      let ext = {};

      if (!nils(getv(popdata, "bgcpart")))
        ext = { ...ext, bgcpart: getv(popdata, "bgcpart") };

      await q_splicing_add_txnque({ vault, hash, docid, ext }).queryFn();

      set_popdata({ ...popdata, stage: 4, docid });
    } catch (err) {
      if (!nils(err.reason)) set_stage_err(`Chain Error:${err.reason}`);
      else set_stage_err(`Error:${err.message}`);
    }
    await cdelay(2000);
    set_genloading(false);
  };

  const select_prevreq = async (docid) => {
    set_stage_err(null);
    set_popdata({ ...popdata, stage: 4, docid });
  };

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Splicing Requests
      </p>

      {stage_err && (
        <p className="text-red-300 resp-text--2 my-2 border border-red-300 resp-p-1 rounded-md">
          {stage_err}
        </p>
      )}

      {qo_splice_requests.isLoading ? (
        <Loader01c />
      ) : qissuccesss(qo_splice_requests) ? (
        <>
          <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
            <p className="my-2 resp-text--1">Generate a New Request</p>

            {spreqs.new_alw == true ? (
              <div className="fr-cc">
                {genloading ? (
                  <p className="text-center text-acc0 resp-text--2">
                    {"Generating Request..."}
                  </p>
                ) : (
                  <Tag
                    onClick={auto_gen_new_request}
                    className="bg-acc0/40 -skew-x-12 font-digi"
                  >
                    New Request
                  </Tag>
                )}
              </div>
            ) : (
              <p className="text-center text-yellow-300 resp-text--2">
                {spreqs.new_msg}
              </p>
            )}
          </Card>
          <Card className={"w-full bg-r2dark/60  border-t-2 border-acc0"}>
            <p className="my-2 resp-text--1">
              Your Previous Pending Requests on Pair
            </p>

            {spreqs.old_alw == true ? (
              <>
                {!_.isEmpty(spreqs.requests) ? (
                  spreqs.requests.map((s) => {
                    return (
                      <div className="resp-p-2 bg-reg rounded-sm my-2 resp-text--2">
                        <div className="fr-sc">
                          <p className="text-center text-acc0">{`DocID: ${s.docid}`}</p>
                          <div className="flex-1"></div>
                          <Tag
                            onClick={() => select_prevreq(s.docid)}
                            className="bg-acc0/40 resp-text--2"
                          >
                            Select
                          </Tag>
                        </div>
                        <div className="fr-sc">
                          <div className="flex-1"></div>
                          <p className="">
                            {from_time_mini(s.update_time)} ago
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <p className="text-center text-yellow-200 resp-text--2">
                      No Pending Splice Requests
                    </p>
                  </>
                )}
              </>
            ) : (
              <p className="text-center text-yellow-300 resp-text--2">
                {spreqs.old_msg}
              </p>
            )}
          </Card>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const Stage_4 = ({ stage, popdata, set_popdata, closepopup }) => {
  const scon = useSpliceContext();
  const { vault } = useAccountContext();
  const [stage_err, set_stage_err] = useState(null);

  const { aumode } = useAuthContext();
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const docid = popdata.docid;
  const [qosplicedoc] = useQueries([
    q_splicing_splicedocdb(
      { docid },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);

  useEffect(() => {
    console.log({ stage }, { docid });
    if (stage !== 4) return;
    set_stage_err(null);
    if (nils(docid)) {
      set_stage_err(`No DocID found`);
      return;
    }
    if (qosplicedoc.isLoading) return;
  }, [stage, jstr(popdata)]);

  const splicedoc = useMemo(() => {
    if (qissuccesss(qosplicedoc)) return getv(qosplicedoc, "data.result");
    return {};
  }, [qosplicedoc.dataUpdatedAt]);

  const [genloading, set_genloading] = useState(false);

  const mxwaittime = useMemo(() => {
    console.log(splicedoc.splice);
    if (nils(splicedoc?.splice))
      return moment().add(10, "minutes").toISOString();
    if (splicedoc.invalid === true) return null;
    let ut = getv(splicedoc, "update_time");
    let st = getv(splicedoc, "splice.stage");
    if (st == 1) return moment(ut).add(2, "minutes").toISOString();
    else if (st >= 2) return null;
    return null;
  }, [jstr(splicedoc)]);

  useEffect(() => {
    let s = getv(splicedoc, "splice.stage");
    if (s == 3) set_popdata({ ...popdata, stage: 5 });
  }, [jstr(splicedoc)]);

  const pay = async () => {
    let total_fee = getv(splicedoc, "splice.total_fee");
    total_fee = parseFloat(tofeth(total_fee));
    console.log("total_fee", total_fee);

    const token = "WETH";
    const splicing =
      aumode == "thirdweb"
        ? await Splicing.get_contract({ rpc: polygon.rpc, nosigner: true })
        : await Splicing.get_contract({});
    const paycon =
      aumode == "thirdweb"
        ? await t3_asset_signer(token)
        : await mm_asset_signer(token);

    console.log(aumode, token, splicing.contractAddress, splicing, paycon);

    let alw = await paycon.allowance(vault, splicing.contractAddress);
    alw = parseFloat(tofeth(alw));

    console.log("allowance", alw);

    if (alw < total_fee) {
      let toalwval = total_fee * 1.01;
      if (aumode == "thirdweb") {
        const approval = await t3_contract_call(
          token,
          "approve",
          [splicing.contractAddress, toeth(dec(toalwval, 6))],
          "txn",
          true,
          { active_account: active_account },
        );
        await cdelay(5 * 1e3);
      } else {
        const approval = await paycon.approve(
          splicing.contractAddress,
          toeth(dec(toalwval, 6)),
        );
        await approval.wait();
        await cdelay(5000);
      }
    }

    let resp = null;
    if (aumode == "thirdweb") {
      resp = await t3_contract_call(
        "splicing",
        "splicePayUp",
        [docid],
        "txn",
        true,
        { active_account: active_account },
      );
    } else {
      resp = await splicing.splicePayUp(docid);
    }
    console.log("splicePayUp resp", resp);
    return resp.hash;
  };

  const auto_pay = async () => {
    set_stage_err(null);
    set_genloading(true);
    try {
      let hash = await pay();
      await q_splicing_add_txnque({ vault, hash, docid }).queryFn();
      set_popdata({ ...popdata, stage: 5 });
    } catch (err) {
      console.log(err);
      if (err.reason) set_stage_err(`Chain Error:${err.reason}`);
      else set_stage_err(`Error:${err.message}`);
      await cdelay(3000);
    }
    set_genloading(false);
  };

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Splicing Requests <span className="text-acc0">#{docid}</span>
      </p>

      {stage_err && (
        <p className="text-red-300 resp-text--2 my-2 border border-red-300 resp-p-1 rounded-md">
          {stage_err}
        </p>
      )}

      {!nils(docid) && (
        <>
          {qosplicedoc.isLoading || qiserr(qosplicedoc) ? (
            <>
              <p className="text-acc0 resp-text--2 text-center">
                Waiting for your Splice Document to generate
              </p>
              <Loader01c />
            </>
          ) : qissuccesss(qosplicedoc) ? (
            <>
              {getv(splicedoc, "splice.stage") == 2 ? (
                <>
                  <p className="text-acc0 resp-text--2 text-center">
                    Please Pay to Mint your splice
                  </p>

                  <div className="fr-cc my-2 resp-gap-4 resp-text--2">
                    <span>Total Cost</span>
                    <span className="text-acc0 resp-text-1">
                      {dec(tofeth(getv(splicedoc, "splice.total_fee")), 4)}
                      {" WETH"}
                    </span>
                  </div>
                  <div className="fr-cc my-2">
                    {genloading ? (
                      <>
                        <Loader01c size="s" />
                        <p className="text-center text-acc0 resp-text--2">
                          {aumode == "thirdweb"
                            ? "Sending Transaction "
                            : "Please confirm the transaction on metamask..."}
                        </p>
                      </>
                    ) : (
                      <Tag onClick={auto_pay} className="bg-acc0/40 -skew-x-12">
                        Mint Splice
                      </Tag>
                    )}
                  </div>
                </>
              ) : splicedoc.invalid === true ? (
                <>
                  <div className="fc-cc resp-gap-2">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-red-300 resp-text-2"
                    />
                    <div className="resp-text--1 text-center text-red-300 w-full">
                      {splicedoc.invalid_msg}
                    </div>

                    <Tag
                      onClick={closepopup}
                      className="bg-red-400 resp-text--1"
                    >
                      Exit
                    </Tag>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-acc0 text-center">
                    Waiting for your Prices Confirmation
                  </p>
                  <Loader01c />
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <div className="fr-sc resp-text--2">
            <div className="flex-1"></div>
            {!nils(mxwaittime) && (
              <div className="text-yellow-300 fc-ss resp-gap-1">
                <span>Est Waiting Time</span>
                <span>{from_time_mini(mxwaittime)}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Stage_5 = ({ stage, popdata, set_popdata }) => {
  const history = useNavigate();
  const scon = useSpliceContext();
  const { vault } = useAccountContext();
  const [stage_err, set_stage_err] = useState(null);

  const docid = popdata.docid;
  const [qosplicedoc] = useQueries([
    q_splicing_splicedocdb(
      { docid },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);

  useEffect(() => {
    console.log({ stage }, { docid });
    if (stage !== 5) return;
    set_stage_err(null);
    if (nils(docid)) {
      set_stage_err(`No DocID found`);
      return;
    }
    if (qosplicedoc.isLoading) return;
  }, [stage, jstr(popdata)]);

  const splicedoc = useMemo(() => {
    if (qissuccesss(qosplicedoc)) return getv(qosplicedoc, "data.result");
    return {};
  }, [qosplicedoc.dataUpdatedAt]);

  const [genloading, set_genloading] = useState(false);

  const mxwaittime = useMemo(() => {
    console.log(splicedoc.splice);
    if (nils(splicedoc)) return moment().add(10, "minutes").toISOString();
    if (splicedoc.minted == true) return null;
    let ut = getv(splicedoc, "update_time");
    let st = getv(splicedoc, "splice.stage");
    if (st <= 2) return moment().add(3, "minutes").toISOString();
    else if (st == 3) return moment().add(2, "minutes").toISOString();
    return null;
  }, [jstr(splicedoc)]);

  return (
    <div className="w-full">
      <p className="my-2 text-center font-digi italic resp-text-1">
        Mint Splice<span className="text-acc0">#{docid}</span>
      </p>

      {stage_err && (
        <p className="text-red-300 resp-text--2 my-2 border border-red-300 resp-p-1 rounded-md">
          {stage_err}
        </p>
      )}

      {!nils(docid) && (
        <>
          {qosplicedoc.isLoading ||
          qiserr(qosplicedoc) ||
          splicedoc.minted != true ? (
            <>
              <p className="text-acc0 resp-text--2 text-center">
                Waiting for your Mint
              </p>
              <Loader01c />
            </>
          ) : qissuccesss(qosplicedoc) && splicedoc.minted === true ? (
            <>
              <p className="text-center text- my-2">
                Congratulations on brand new Splice
              </p>
              <div className="fc-cc my-2">
                <SetBikeName
                  h={{ hid: docid }}
                  after_setting={() => {
                    history(`/bike/${docid}`);
                  }}
                />
              </div>
              <p className="text-center text-white resp-text-2">-- OR --</p>
              <div className="fc-cc my-2">
                <Tag
                  onClick={() => {
                    history(`/bike/${docid}`);
                  }}
                  className="bg-acc0/40 -skew-x-12"
                >
                  View Bike
                </Tag>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="fr-sc resp-text--2">
            <div className="flex-1"></div>
            {!nils(mxwaittime) && (
              <div className="text-yellow-300 fc-ss resp-gap-1">
                <span>Est Waiting Time</span>
                <span>{from_time_mini(mxwaittime)}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const SpliceProceed = ({ ext = {} }) => {
  const accon = useAccountContext();
  const { vault } = accon;
  const scon = useSpliceContext();
  const { pair_params, qo_pairinfo, pairinfo } = scon;

  const [pop, set_pop] = useState(false);
  const [popdata, set_popdata] = useState({});
  const [popresp, set_popresp] = useState({});

  const closepopup = () => {
    set_pop(false);
    set_popdata({
      stage: 0,
    });
    set_popresp({});
  };
  const open_popup = () => {
    set_pop(true);
    set_popdata({
      stage: 0,
      ...ext,
    });
    set_popresp({});
  };

  /*
  stage 0: view parent details
  stage 1: view baby generated details
  stage 2: prices breakdown
  stage 3: either check for previous requests else enter new request
  stage 3: view waiting for fees keep the popup open
  stage 4: pay now
  stage 5: waiting for the mint to finish and redirect link to new bike page
  */

  const prevstage = () => {
    let n_s = Math.max(0, popdata.stage - 1);
    set_popdata({ ...popdata, stage: n_s });
  };
  const nextstage = () => {
    let n_s = Math.min(5, popdata.stage + 1);
    set_popdata({ ...popdata, stage: n_s });
  };

  const stage = popdata.stage;
  const mxstage = 5;

  const [next_allowed, set_next_allowed] = useState(false);
  useEffect(() => {
    set_next_allowed(false);
  }, [stage]);

  const stageprops = {
    pair_params,
    stage,
    pop,
    popdata,
    set_popdata,
    popresp,
    set_popresp,
    prevstage,
    nextstage,
    next_allowed,
    set_next_allowed,
    pairinfo,
    closepopup,
  };

  const StageComp = {
    0: Stage_0,
    1: Stage_1,
    2: Stage_2,
    3: Stage_3,
    4: Stage_4,
    5: Stage_5,
  };

  return (
    <>
      <div className="fr-cc">
        <Tag
          onClick={() => {
            open_popup();
          }}
          className="bg-r2lig shadow-lg shadow-acc1 font-digi fr-sc resp-gap-2 resp-p-2 resp-text-1 -skew-x-12"
        >
          <span>Proceed Splicing</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Tag>

        <PopUp
          overlayclose={false}
          openstate={pop}
          onClose={closepopup}
          wrapcn={"top-[2rem]"}
          innercn={"translate-y-[0%]"}
        >
          <Card
            className={
              "max-w-[95vw] w-[50rem] xs:max-h-[70vh] lg:max-h-[60vh] overflow-auto"
            }
          >
            <div className="fr-sc">
              <div className="flex-1"></div>
              <PopupCloseBtn closepopup={closepopup} />
            </div>
            <div className="h-[0.5rem]"></div>
            <div className="w-full h-[3rem] relative">
              <div className="absolute top-[20%] left-[2.5%] z-[10] w-[95%]">
                <div
                  style={{ width: `${(stage / mxstage) * 100}%` }}
                  className="h-[1rem] rounded-md transition duration-1000 bg-acc0/30"
                ></div>
              </div>
              <div className="absolute w-full z-[20]">
                <div className="flex flex-row justify-between items-center z-[20]">
                  {[0, 1, 2, 3, 4, 5].map((_s) => (
                    <Tag
                      className={twMerge(
                        "fc-cc rounded-full resp-p-2 border",
                        _s <= popdata.stage
                          ? "text-white bg-acc0/40 border-transparent"
                          : "text-acc0 border-acc0",
                        popdata.stage == _s ? "shadow-md shadow-acc0" : "",
                      )}
                    >
                      <span className="resp-text--1 font-digi">{_s}</span>
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
            <div className="h-[0.5rem]"></div>
            <div className="h-[2rem]">
              {stage > 0 && (
                <div className="fr-sc my-2">
                  <Tag
                    onClick={prevstage}
                    className="border border-acc0 resp-text--1 fr-sc resp-gap-2 -skew-x-12 text-acc0"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span className="font-digi">Back</span>
                  </Tag>
                </div>
              )}
            </div>

            <div className="min-h-[18rem]">
              {qissuccesss(qo_pairinfo) && (
                <>
                  {StageComp[stage] &&
                    React.createElement(StageComp[stage], stageprops)}
                </>
              )}
            </div>

            <div className="h-[2rem]">
              {next_allowed && (
                <div className="fr-sc my-2">
                  <div className="flex-1"></div>
                  <Tag
                    onClick={nextstage}
                    className="border border-acc0 resp-text--1 fr-sc resp-gap-2 -skew-x-12 text-acc0"
                  >
                    <span className="font-digi">Next</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Tag>
                </div>
              )}
            </div>
          </Card>
        </PopUp>
      </div>
    </>
  );
};

function SplicePage() {
  const appcon = useAppContext();
  const { s_acc_config, g_acc_config } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  const toback = psearch.toback;
  const [selbikes, set_selbikes] = useState({
    male: parseInt(psearch.male),
    female: parseInt(psearch.female),
  });

  const pair_params = {
    mother_coreid: selbikes.female,
    father_coreid: selbikes.male,
  };
  const [q_pair_validate] = useQueries([
    q_splicing_enter_pair_validate(pair_params, {
      enabled: !nils(selbikes.male) && !nils(selbikes.female),
    }),
  ]);
  useEffect(() => {
    upd_psearch(selbikes);
  }, [jstr(selbikes)]);

  const [qo_pairinfo] = useQueries([
    q_splicing_pair_info(
      {
        father_coreid: pair_params.father_coreid,
        mother_coreid: pair_params.mother_coreid,
      },
      { enabled: true },
    ),
  ]);
  const pairinfo = useMemo(() => {
    if (qissuccesss(qo_pairinfo)) return getv(qo_pairinfo, "data.result");
    return {};
  }, [qo_pairinfo.dataUpdatedAt]);
  const totfee = getv(pairinfo, "prices.tot.finfee");

  const scon = {
    selbikes,
    set_selbikes,
    q_pair_validate,
    pair_params,

    qo_pairinfo,
    pairinfo,
  };

  return (
    <SpliceContext.Provider value={scon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[1rem]"></div>
          <div class="fr-sc mx-auto max-w-[40rem] w-full">
            <Link
              to={!nils(toback) ? base64_to_json(toback)?.url : "/splice-arena"}
            >
              <div class="md:h-[3rem] xs:h-[2rem] fc-cc aspect-[1/1] rounded-full border border-acc4 bg-acc4/20">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-white resp-text-2 "
                />
              </div>
            </Link>
          </div>
          <div className="h-[1rem]"></div>

          <p className="text-center resp-text-2 font-digi">
            Select Bike to Splice
          </p>
          <div className="h-[2rem]"></div>
          <div class="relative">
            {!nils(totfee) && (
              <div class="absolute top-0 left-[50%] translate-x-[-50%] font-mon resp-p-2 resp-text--1 text-acc4 border border-acc4 bg-acc4/20 rounded-md">
                {`Total Cost $${totfee}`}
              </div>
            )}
            <div className=" grid grid-cols-2 resp-gap-4">
              <TemplateBike gender={"male"} dir={"left"} />
              <TemplateBike gender={"female"} dir={"left"} />
            </div>
          </div>
          {/* <SpliceProceed ext={{ stage: 2 }} /> */}
          {/* <SpliceProceed ext={{ stage: 4, docid: 9576 }} /> */}
          <Card className="w-full max-w-[40rem] my-4">
            {nils(selbikes.male) || nils(selbikes.female) ? (
              <>
                <p className="text-center text-acc0">
                  Please select both Parent Bikes to proceed
                </p>
              </>
            ) : (
              <>
                {q_pair_validate.isLoading ? (
                  <Loader01c size="s" />
                ) : qiserr(q_pair_validate) ? (
                  <p className="text-center resp-text--1 text-red-400 ">
                    {qiserr(q_pair_validate)}
                  </p>
                ) : qissuccesss(q_pair_validate) ? (
                  <SpliceProceed />
                ) : (
                  <></>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
    </SpliceContext.Provider>
  );
}

export default SplicePage;

import React from "react";
import { colormap } from "./colormap.js";

function NoColor() {
  return (
    <div>
      <div className="h-0 xs:col-span-1 lg:col-span-1"></div>
      <div className="h-0 xs:col-span-6 lg:col-span-6"></div>
      <div className="h-0 xs:col-span-12 lg:col-span-12"></div>
      <div className="h-0 hide bg-red-400 shadow shadow-red-400"></div>
      <div className="h-0 hide bg-blue-400 shadow shadow-blue-400"></div>
      <div className="h-0 hide bg-acc0 shadow shadow-acc0"></div>
      <div className="h-0 hide bg-c1 text-c1 border-c1 shadow shadow-c1"></div>
      <div className="h-0 hide bg-c2 text-c2 border-c2 shadow shadow-c2"></div>
      <div className="h-0 hide bg-c3 text-c3 border-c3 shadow shadow-c3"></div>
      <div className="h-0 hide bg-c4 text-c4 border-c4 shadow shadow-c4"></div>
      <div className="h-0 hide bg-c5 text-c5 border-c5 shadow shadow-c5"></div>
      <div className="h-0 hide bg-c70 text-c70 border-c70 shadow shadow-c70"></div>
      <div className="h-0 hide bg-c72 text-c72 border-c72 shadow shadow-c72"></div>
      <div className="h-0 hide bg-r2dark text-r2dark border-r2dark shadow shadow-r2dark"></div>
      <div className="h-0 hide bg-r2reg text-r2reg border-r2reg shadow shadow-r2reg"></div>
      <div className="h-0 hide bg-r2lig text-r2lig border-r2lig shadow shadow-r2lig"></div>
      <div className="h-0 hide bg-r2lig/20 text-r2lig border-r2lig shadow shadow-r2lig"></div>
      <div className="h-0 hide bg-40 text-40 border-40 shadow shadow-40"></div>
      <div className="h-0 hide bg-c30 text-c30 border-c30 shadow shadow-c30"></div>
      <div className="h-0 hide bg-c40 text-c40 border-c40 shadow shadow-c40"></div>
      <div className="h-0 hide bg-c50 text-c50 border-c50 shadow shadow-c50"></div>
      <div className="h-0 hide bg-c60 text-c60 border-c60 shadow shadow-c60"></div>
      <div className="h-0 hide bg-c70 text-c70 border-c70 shadow shadow-c70"></div>
      <div className="h-0 hide bg-c80 text-c80 border-c80 shadow shadow-c80"></div>
      <div className="h-0 hide bg-c90 text-c90 border-c90 shadow shadow-c90"></div>
      <div className="h-0 hide bg-pos1 text-pos1 border-pos1 shadow shadow-pos1"></div>
      <div className="h-0 hide bg-pos2 text-pos2 border-pos2 shadow shadow-pos2"></div>
      <div className="h-0 hide bg-pos3 text-pos3 border-pos3 shadow shadow-pos3"></div>
      <div className="h-0 hide bg-alice-blue text-alice-blue shadow-alice-blue border-alice-blue" />
      <div className="h-0 hide bg-apricot text-apricot shadow-apricot border-apricot" />
      <div className="h-0 hide bg-aquamarine text-aquamarine shadow-aquamarine border-aquamarine" />
      <div className="h-0 hide bg-arctic-blue text-arctic-blue shadow-arctic-blue border-arctic-blue" />
      <div className="h-0 hide bg-atomic-tangerine text-atomic-tangerine shadow-atomic-tangerine border-atomic-tangerine" />
      <div className="h-0 hide bg-baby-pink text-baby-pink shadow-baby-pink border-baby-pink" />
      <div className="h-0 hide bg-banana-mania text-banana-mania shadow-banana-mania border-banana-mania" />
      <div className="h-0 hide bg-barbie-pink text-barbie-pink shadow-barbie-pink border-barbie-pink" />
      <div className="h-0 hide bg-beige text-beige shadow-beige border-beige" />
      <div className="h-0 hide bg-bisque text-bisque shadow-bisque border-bisque" />
      <div className="h-0 hide bg-black text-black shadow-black border-black" />
      <div className="h-0 hide bg-blizzard-blue text-blizzard-blue shadow-blizzard-blue border-blizzard-blue" />
      <div className="h-0 hide bg-blue-bell text-blue-bell shadow-blue-bell border-blue-bell" />
      <div className="h-0 hide bg-blue-gray text-blue-gray shadow-blue-gray border-blue-gray" />
      <div className="h-0 hide bg-blue-green text-blue-green shadow-blue-green border-blue-green" />
      <div className="h-0 hide bg-blue-violet text-blue-violet shadow-blue-violet border-blue-violet" />
      <div className="h-0 hide bg-blush text-blush shadow-blush border-blush" />
      <div className="h-0 hide bg-brick-red text-brick-red shadow-brick-red border-brick-red" />
      <div className="h-0 hide bg-bright-green text-bright-green shadow-bright-green border-bright-green" />
      <div className="h-0 hide bg-brown text-brown shadow-brown border-brown" />
      <div className="h-0 hide bg-burnt-orange text-burnt-orange shadow-burnt-orange border-burnt-orange" />
      <div className="h-0 hide bg-cadet-blue text-cadet-blue shadow-cadet-blue border-cadet-blue" />
      <div className="h-0 hide bg-carnation-pink text-carnation-pink shadow-carnation-pink border-carnation-pink" />
      <div className="h-0 hide bg-carrot-orange text-carrot-orange shadow-carrot-orange border-carrot-orange" />
      <div className="h-0 hide bg-cerulean text-cerulean shadow-cerulean border-cerulean" />
      <div className="h-0 hide bg-champagne text-champagne shadow-champagne border-champagne" />
      <div className="h-0 hide bg-chartreuse text-chartreuse shadow-chartreuse border-chartreuse" />
      <div className="h-0 hide bg-cherry-red text-cherry-red shadow-cherry-red border-cherry-red" />
      <div className="h-0 hide bg-coral text-coral shadow-coral border-coral" />
      <div className="h-0 hide bg-crimson text-crimson shadow-crimson border-crimson" />
      <div className="h-0 hide bg-dandelion text-dandelion shadow-dandelion border-dandelion" />
      <div className="h-0 hide bg-dark-cherry text-dark-cherry shadow-dark-cherry border-dark-cherry" />
      <div className="h-0 hide bg-dark-sky text-dark-sky shadow-dark-sky border-dark-sky" />
      <div className="h-0 hide bg-dazzle-violet text-dazzle-violet shadow-dazzle-violet border-dazzle-violet" />
      <div className="h-0 hide bg-denim-blue text-denim-blue shadow-denim-blue border-denim-blue" />
      <div className="h-0 hide bg-desert-sand text-desert-sand shadow-desert-sand border-desert-sand" />
      <div className="h-0 hide bg-emerald-green text-emerald-green shadow-emerald-green border-emerald-green" />
      <div className="h-0 hide bg-fire-fuchsia text-fire-fuchsia shadow-fire-fuchsia border-fire-fuchsia" />
      <div className="h-0 hide bg-flamingo-pink text-flamingo-pink shadow-flamingo-pink border-flamingo-pink" />
      <div className="h-0 hide bg-forest-green text-forest-green shadow-forest-green border-forest-green" />
      <div className="h-0 hide bg-gold text-gold shadow-gold border-gold" />
      <div className="h-0 hide bg-goldenrod text-goldenrod shadow-goldenrod border-goldenrod" />
      <div className="h-0 hide bg-granny-smith text-granny-smith shadow-granny-smith border-granny-smith" />
      <div className="h-0 hide bg-grape-purple text-grape-purple shadow-grape-purple border-grape-purple" />
      <div className="h-0 hide bg-grass-green text-grass-green shadow-grass-green border-grass-green" />
      <div className="h-0 hide bg-green-marble text-green-marble shadow-green-marble border-green-marble" />
      <div className="h-0 hide bg-green-yellow text-green-yellow shadow-green-yellow border-green-yellow" />
      <div className="h-0 hide bg-honeydew text-honeydew shadow-honeydew border-honeydew" />
      <div className="h-0 hide bg-hot-pink text-hot-pink shadow-hot-pink border-hot-pink" />
      <div className="h-0 hide bg-indian-red text-indian-red shadow-indian-red border-indian-red" />
      <div className="h-0 hide bg-ivory text-ivory shadow-ivory border-ivory" />
      <div className="h-0 hide bg-jade text-jade shadow-jade border-jade" />
      <div className="h-0 hide bg-lavender text-lavender shadow-lavender border-lavender" />
      <div className="h-0 hide bg-light-blue text-light-blue shadow-light-blue border-light-blue" />
      <div className="h-0 hide bg-light-coral text-light-coral shadow-light-coral border-light-coral" />
      <div className="h-0 hide bg-light-cyan text-light-cyan shadow-light-cyan border-light-cyan" />
      <div className="h-0 hide bg-light-fire text-light-fire shadow-light-fire border-light-fire" />
      <div className="h-0 hide bg-light-green text-light-green shadow-light-green border-light-green" />
      <div className="h-0 hide bg-light-salmon text-light-salmon shadow-light-salmon border-light-salmon" />
      <div className="h-0 hide bg-lime-green text-lime-green shadow-lime-green border-lime-green" />
      <div className="h-0 hide bg-maroon text-maroon shadow-maroon border-maroon" />
      <div className="h-0 hide bg-money text-money shadow-money border-money" />
      <div className="h-0 hide bg-navy-blue text-navy-blue shadow-navy-blue border-navy-blue" />
      <div className="h-0 hide bg-neon-blue text-neon-blue shadow-neon-blue border-neon-blue" />
      <div className="h-0 hide bg-olive text-olive shadow-olive border-olive" />
      <div className="h-0 hide bg-orchid text-orchid shadow-orchid border-orchid" />
      <div className="h-0 hide bg-pale-blue text-pale-blue shadow-pale-blue border-pale-blue" />
      <div className="h-0 hide bg-pale-green text-pale-green shadow-pale-green border-pale-green" />
      <div className="h-0 hide bg-papaya-whip text-papaya-whip shadow-papaya-whip border-papaya-whip" />
      <div className="h-0 hide bg-peach text-peach shadow-peach border-peach" />
      <div className="h-0 hide bg-periwinkle text-periwinkle shadow-periwinkle border-periwinkle" />
      <div className="h-0 hide bg-plum text-plum shadow-plum border-plum" />
      <div className="h-0 hide bg-race-blue text-race-blue shadow-race-blue border-race-blue" />
      <div className="h-0 hide bg-rage-red text-rage-red shadow-rage-red border-rage-red" />
      <div className="h-0 hide bg-raspberry text-raspberry shadow-raspberry border-raspberry" />
      <div className="h-0 hide bg-russet text-russet shadow-russet border-russet" />
      <div className="h-0 hide bg-salmon-pink text-salmon-pink shadow-salmon-pink border-salmon-pink" />
      <div className="h-0 hide bg-scarlet text-scarlet shadow-scarlet border-scarlet" />
      <div className="h-0 hide bg-sea-green text-sea-green shadow-sea-green border-sea-green" />
      <div className="h-0 hide bg-sim-metal text-sim-metal shadow-sim-metal border-sim-metal" />
      <div className="h-0 hide bg-sky-yellow text-sky-yellow shadow-sky-yellow border-sky-yellow" />
      <div className="h-0 hide bg-slate-blue text-slate-blue shadow-slate-blue border-slate-blue" />
      <div className="h-0 hide bg-soft-cyan text-soft-cyan shadow-soft-cyan border-soft-cyan" />
      <div className="h-0 hide bg-soft-pink text-soft-pink shadow-soft-pink border-soft-pink" />
      <div className="h-0 hide bg-speed-blue text-speed-blue shadow-speed-blue border-speed-blue" />
      <div className="h-0 hide bg-spring-green text-spring-green shadow-spring-green border-spring-green" />
      <div className="h-0 hide bg-strawberry text-strawberry shadow-strawberry border-strawberry" />
      <div className="h-0 hide bg-tan text-tan shadow-tan border-tan" />
      <div className="h-0 hide bg-turquoise text-turquoise shadow-turquoise border-turquoise" />
      <div className="h-0 hide bg-very-orange text-very-orange shadow-very-orange border-very-orange" />
      <div className="h-0 hide bg-vivid-tangerine text-vivid-tangerine shadow-vivid-tangerine border-vivid-tangerine" />
      <div className="h-0 hide bg-navy-blue text-navy-blue shadow-navy-blue border-navy-blue" />
      <div className="h-0 hide bg-lime-green text-lime-green shadow-lime-green border-lime-green" />
      <div className="h-0 hide bg-rose text-rose shadow-rose border-rose" />
      <div className="h-0 hide bg-gold text-gold shadow-gold border-gold" />
      <div className="h-0 hide bg-white text-white shadow-white border-white" />

      <div class="h-0 w-0 transform rotate-[10deg]" />
      <div class="h-0 w-0 transform rotate-[20deg]" />
      <div class="h-0 w-0 transform rotate-[30deg]" />
      <div class="h-0 w-0 transform rotate-[40deg]" />
      <div class="h-0 w-0 transform rotate-[50deg]" />
      <div class="h-0 w-0 transform rotate-[60deg]" />
      <div class="h-0 w-0 transform rotate-[70deg]" />
      <div class="h-0 w-0 transform rotate-[80deg]" />
      <div class="h-0 w-0 transform rotate-[90deg]" />
      <div class="h-0 w-0 transform rotate-[-10deg]" />
      <div class="h-0 w-0 transform rotate-[-20deg]" />
      <div class="h-0 w-0 transform rotate-[-30deg]" />
      <div class="h-0 w-0 transform rotate-[-40deg]" />
      <div class="h-0 w-0 transform rotate-[-50deg]" />
      <div class="h-0 w-0 transform rotate-[-60deg]" />
      <div class="h-0 w-0 transform rotate-[-70deg]" />
      <div class="h-0 w-0 transform rotate-[-80deg]" />
      <div class="h-0 w-0 transform rotate-[-90deg]" />
    </div>
  );
}

export default NoColor;

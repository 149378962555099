import { Card } from "../components/utilityComps";
import { ContactTag2 } from "../utils/raceutils2";

const InfoCard = ({ head, ...props }) => {
  return (
    <Card className="w-full resp-p-2 border border-acc4 bg-r2lig/20 backdrop-blur-md resp-my-3">
      <p class="text-center text-acc4 resp-text-1">{head}</p>
      {props.children}
    </Card>
  );
};

export const SponsorPage = () => {
  return (
    <div className="h-page resp-text--2 overflow-auto">
      <div className="w-[60rem] max-w-[95vw] mx-auto">
        <div class="h-[2rem]"></div>
        <p class="resp-text-2 my-2 text-center">Sponsor Package</p>
        <div class="img-obey-cont resp-my-3 mx-auto xs:w-[10rem] md:w-[20rem] lg:w-[30rem] aspect-[12/4.9]">
          <img src="https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/bike-sponsor.png" />
        </div>

        <div class="my-2">
          {[
            "Stand out from the pack and dominate the track with a unique sponsorship package from DNA Racing.",
            "We offer complete customization to ensure your brand gets the ultimate exposure.",
          ].map((t) => {
            return <p class="resp-text-0 text-center my-2">{t}</p>;
          })}
        </div>

        <InfoCard head="Here's what you get:">
          <ul class="ul-pointer-list list-disc resp-m-2 resp-px-4">
            {[
              `20 Custom Skins: Become a rolling billboard! We'll design eye-catching skins for our racing bikes featuring your logo, website, or any message you want.`,

              `"Your Brand" Tournament: Get your name in the lights! We'll host a special tournament named after you, complete with a link to your website and a custom message to promote your product or brand.
`,

              `Amplified Engagement: We'll fuel the excitement. The entire sponsorship cost you pay goes directly towards the tournament prize pool, incentivizing players to participate and discuss your brand. All day exposure is guaranteed as they track their ranking on the leaderboards.`,

              `Twitch Stream Finale: Reach a wider audience. Witness the thrilling finals of your custom tournament streamed live on Twitch. We'll sweeten the deal with exciting giveaways for viewers!`,
            ].map((t) => {
              return <li class="resp-text--1 resp-my-3">{t}</li>;
            })}
            <li>test</li>
          </ul>
        </InfoCard>

        <InfoCard head="Benefits for You:">
          <ul class="ul-pointer-list list-disc resp-m-2 resp-px-4">
            {[
              `High Visibility: Your custom skins will be seen on the track by players and on our platforms.`,
              `Long-Term Exposure: The skins become permanent collectibles players can trade or sell, keeping your brand in circulation.`,
              `Repeated Brand Recognition: Players will see your name and message on leaderboards throughout the tournament, driving brand awareness.`,
            ].map((t) => {
              return <li class="resp-text--1 resp-my-3">{t}</li>;
            })}
            <li>test</li>
          </ul>
        </InfoCard>

        <InfoCard head="Ready to get started?">
          <p class="text-center resp-text-0 resp-px-4 resp-my-3">
            Contact Danshan directly on Discord or Twitter to design your custom
            sponsorship package and take your brand racing towards success!
          </p>
          <div class="w-max mx-auto grid grid-cols-2 resp-gap-3">
            <div class="fc-cc">
              <div class="xs:w-[4rem] lg:w-[8rem] aspect-[1/1]">
                <img
                  src="https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-skins/danshan-img.png"
                  alt=""
                />
              </div>
            </div>
            <div class="fc-cs">
              <ContactTag2
                redirect={"https://discord.gg/dnaracing"}
                img={
                  "https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/con-discord.png"
                }
                txt="Join Discord Now"
              />

              <ContactTag2
                redirect={"https://twitter.com/Spotonparts"}
                img={
                  "https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/con-x.png"
                }
                txt="@Spotonparts"
              />
            </div>
          </div>
        </InfoCard>
      </div>
      <div class="h-[5rem]"></div>
    </div>
  );
};

import {
  faCheck,
  faCheckCircle,
  faCircle,
  faInfoCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  forwardRef,
  createContext,
  useContext,
} from "react";
import { useQueries, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../App";
import { Loader01c } from "../components/anims";
import { useLayoutContext } from "../components/Layout";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { PageWrapper } from "../components/ShortComps";
import { Card, Img, Tag } from "../components/utilityComps";
import {
  polytxnidlink,
  qiserr,
  qissuccesss,
  q_quest_cards_burn,
  q_quest_cards_burnall,
  q_quest_cards_cardslist,
  q_quest_cards_cashout,
  q_quest_cards_est_prize,
  q_quest_cards_reveal,
  q_quest_cards_rewards,
  q_vaultinfo,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { polychainimg } from "../utils/links";
import {
  cdelay,
  getv,
  iso_format,
  jstr,
  nils,
  snake_str_to_caps,
  trim_n,
} from "../utils/utils";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { useAuthContext } from "../wrappers/AuthWrapper";

const QuestCardsContext = createContext({});
const useQuestCardsContext = () => useContext(QuestCardsContext);

const sel_cards_n = 5;
const tot_cards_n = 5;
const imgbase =
  "https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/playing-cards-factions";
const gen_img = (k) => {
  let link = null;
  if (k == "back" || k == "brightback" || nils(k))
    link = `${imgbase}/card_back_p01.png`;
  else {
    let [a, b] = k.split("-");
    link = `${imgbase}/${a}s_${b}.png`;
  }
  // console.log("gen_img", k, link);
  return link;
};
export const gen_faction_playinc_card_img = gen_img;

export const format_prizename = (p) => {
  if (p == "no-prize" || nils(p)) return "No Prize";
  let [a, b, c] = p.split("-");
  if (p.includes("USD")) {
    return `${a} USD`;
  } else {
    return `${a}x ${_.capitalize(b)} `;
  }
};

class PokerHandEvaluator {
  constructor(hand) {
    this.hand = _.uniq(hand);
    this.valueMap = {
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
      J: 11,
      Q: 12,
      K: 13,
      A: 14,
    };
  }

  getCardValue(card) {
    return card.split("-")[1];
  }

  getCardSuit(card) {
    return card.split("-")[0];
  }

  is_valid() {
    if (this.hand.length !== 5) return false;

    let dups = _.chain(this.hand)
      .countBy((e) => e)
      .entries()
      .map(([k, v]) => {
        if (v > 1) {
          return `${k}=x${v}`;
        }
        return null;
      })
      .compact()
      .value();
    if (dups.length > 0) {
      console.log("has duplicates", this.hand, dups);
      return false;
    }
    // return this.hand.length >= 2 && this.hand.length <= 5;
    return true;
  }

  get_first_mxn_usingcards(valueCounts, mxn) {
    let ks = _.chain(valueCounts)
      .entries()
      .sortBy(([k, v]) => -v)
      .slice(0, mxn)
      .map(0)
      .value();
    let using = this.hand.filter((c) => {
      let match = false;
      for (let k of ks) {
        if (c.endsWith(`-${k}`)) {
          match = true;
          break;
        }
      }
      return match;
    });
    return using;
  }

  evaluateHand() {
    if (!this.is_valid()) {
      return "Invalid hand size";
    }

    const values = this.hand.map((card) => this.getCardValue(card));
    const suits = this.hand.map((card) => this.getCardSuit(card));
    const valueCounts = this.countOccurrences(values);
    const suitCounts = this.countOccurrences(suits);

    const counts = Object.values(valueCounts);
    const isFlush = Object.values(suitCounts).some(
      (count) => count === this.hand.length,
    );
    const isStraight = this.checkStraight(values);
    const maxCount = Math.max(...counts);
    const cards_n = this.hand.length;

    return this.determineCombo(
      isFlush,
      isStraight,
      counts,
      maxCount,
      valueCounts,
      cards_n,
    );
  }

  countOccurrences(arr) {
    return arr.reduce((acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});
  }

  checkStraight(values) {
    const numericValues = values
      .map((value) => this.valueMap[value])
      .sort((a, b) => a - b);

    for (let i = 0; i < numericValues.length - 1; i++) {
      if (numericValues[i + 1] !== numericValues[i] + 1) {
        return false;
      }
    }

    return true;
  }

  get_tot_values(using) {
    let tot = 0;
    for (let c of using) {
      let val = this.getCardValue(c);
      tot += this.valueMap[val];
    }
    return tot;
  }

  determineCombo(isFlush, isStraight, counts, maxCount, valueCounts, cards_n) {
    const isFullHand = cards_n === 5; // Check if the hand has exactly 5 cards
    const values = Object.values(valueCounts);
    const ks = Object.keys(valueCounts);
    let using = [];
    let combo = null;

    // console.log( "determineCombo", { isFullHand, isFlush, isStraight }, values.join(","), ks.join(","), valueCounts,);

    // if (
    //   isFullHand &&
    //   isFlush &&
    //   isStraight &&
    //   ks.includes("A") &&
    //   ks.includes("K")
    // ) {
    //   using = this.hand;
    //   combo = "royal-flush";
    // } else
    //   if (isFullHand && isFlush && isStraight) {
    //   using = this.hand;
    //   combo = "straight-flush";
    // } else
    if (maxCount === 4) {
      using = this.get_first_mxn_usingcards(valueCounts, 1);
      combo = "four of a kind";
    } else if (maxCount === 3 && counts.includes(2)) {
      using = this.get_first_mxn_usingcards(valueCounts, 2);
      combo = "full-house";
    } else if (isFlush && isFullHand) {
      using = this.hand;
      combo = "flush";
    }
    // else if (isFullHand && isStraight) {
    //   using = this.hand;
    //   combo = "straight";
    // }
    else if (maxCount === 3) {
      using = this.get_first_mxn_usingcards(valueCounts, 1);
      combo = "three-of-a-kind";
    } else if (maxCount === 2 && counts.length === 3) {
      using = this.get_first_mxn_usingcards(valueCounts, 2);
      combo = "two-pair";
    } else if (maxCount === 2) {
      using = this.get_first_mxn_usingcards(valueCounts, 1);
      combo = "pair";
    } else {
      combo = null;
    }
    if (!combo) return [null, [], 0];

    let totval = this.get_tot_values(using, combo);

    if (combo == "pair" && totval < 22) return [null, [], 0];
    return [combo, using, totval];
  }
}

const CardImg = forwardRef(({ k, cn, delay, auto_flip = true }, ref) => {
  const img = useMemo(() => gen_img(k), [k]); // Assuming gen_img is defined elsewhere

  const [reveal, set_reveal] = useState(false);

  useEffect(() => {
    if (!auto_flip) return;
    const timer = setTimeout(() => {
      set_reveal(true);
    }, delay);
    return () => clearTimeout(timer); // Clear timeout on unmount
  }, [delay, k]);

  return (
    <div class="w-full">
      <div
        ref={ref}
        className={twMerge(
          "playcard relative w-full aspect-[242/340]",
          reveal ? "reveal" : "",
          cn,
        )}
      >
        <div
          className={twMerge(
            "playcard-front absolute w-full h-full",

            k == "brightback" ? "" : k == "back" ? "grayscale" : "",
            cn,
          )}
        >
          <Img img={img} className="w-full h-full" />
        </div>
        <div className="playcard-back absolute w-full h-full">
          <Img img={gen_img("back")} className="w-full h-full" />
        </div>
      </div>
    </div>
  );
});
export const QuestCardImg = CardImg;
const RevealerCard = ({ id }) => {
  const ccon = useQuestCardsContext();
  const {
    cards_ob,
    cards,
    set_cards,
    opened,
    use_cards,
    set_use_cards,
    revealing,
    set_revealing,
  } = ccon;

  const ref = useRef();
  const [cn, set_cn] = useState("");
  const [c, set_c] = useState(cards_ob[id]);

  const reveal_card = async () => {
    try {
      set_revealing(id);
      if (opened.length >= tot_cards_n) {
        throw new Error(
          `max open cards you can have is ${tot_cards_n}, please burn some olds cards first`,
        );
      }
      set_revealing(id);
      let res = await q_quest_cards_reveal({ id }).queryFn();
      console.log("reveal_card", id, res?.result);
      if (!nils(res.err)) throw new Error(res.err);
      let nc = res.result;
      set_c(nc);
      setTimeout(() => {
        set_cn("reveal");
      }, 1 * 1e3);
      setTimeout(() => {
        let ncs = _.chain(cards)
          .filter((e) => e.id !== id)
          .value();
        set_cards([...ncs, nc]);
        console.log("new cards", ncs);
      }, 5 * 1e3);
    } catch (err) {
      console.log("reveal_card", err);
    }
    setTimeout(() => {
      set_revealing(null);
    }, 3 * 1e3);
  };

  return (
    <div
      onClick={() => {
        if (!nils(revealing)) return;
        reveal_card();
      }}
      className={twMerge(
        "cusor-pointer col-span-1 w-full transition duration-300",
        nils(revealing)
          ? ""
          : revealing == id
            ? ""
            : "grayscale opacity-50 blur-md",
      )}
    >
      <CardImg
        {...{
          k: c?.card ?? "back",
          cn,
          delay: 1 * 1e3,
          auto_flip: false,
        }}
      />
    </div>
  );
};

const BurnableCard = ({ id, cn: defcn }) => {
  const ccon = useQuestCardsContext();
  const {
    opened = [],
    cards,
    set_cards,
    cards_ob,
    use_cards,
    set_use_cards,
  } = ccon;

  const ref = useRef();
  const [cn, set_cn] = useState(defcn);
  const [c, set_c] = useState(cards_ob[id]);

  const [hovering, set_hovering] = useState(false);
  const [burning, set_burning] = useState(false);

  const burneligible = false; // opened.length >= tot_cards_n;

  const burn_card = async () => {
    try {
      set_burning(true);
      let res = await q_quest_cards_burn({ id }).queryFn();
      console.log("burn_card", id, res?.result);
      if (!nils(res.err)) throw new Error(res.err);
      let nc = res.result;
      set_c(nc);
      setTimeout(() => {
        set_burning(false);
      }, 2 * 1e3);
      setTimeout(() => {
        let ncs = _.chain(cards)
          .filter((e) => e.id !== id)
          .value();
        set_cards([...ncs, nc]);
        console.log("new cards", ncs);
      }, 2 * 1e3);
    } catch (err) {
      console.log("burn_card", err);
      set_cn("burn");
    }
  };
  useEffect(() => {
    console.log("burn_card:useEffect", c.card);
  }, [jstr(c)]);

  return (
    <div
      onClick={() => {
        if (!burneligible) return;
        if (burning) return;
        burn_card();
      }}
      onMouseEnter={() => set_hovering(true)}
      onMouseLeave={() => set_hovering(false)}
      class="cursor-pointer relative col-span-1 w-full"
    >
      {!burneligible ? null : burning ? (
        <div class="absolute h-full top-[20%] z-[10]  w-full">
          <div
            // style="width:100%;height:0;padding-bottom:100%;position:relative;"
            style={{
              width: "100%",
              height: "10rem",
              paddingBottom: "100%",
              position: "relative",
            }}
          >
            <iframe
              src="https://giphy.com/embed/l1J9QZXasDUhbX0fC"
              width="100%"
              height="100%"
              // style="position:absolute"
              style={{ position: "absolute" }}
              frameBorder="0"
              class="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ) : (
        <div class="absolute top-[40%] z-[10] w-full">
          <div class="w-full fr-cc ">
            <Tag
              className={twMerge(
                "text-red-400 border-2 bg-r2dark/30 border-red-400 w-max",
                hovering ? "opacity-100" : "opacity-5",
              )}
            >
              <p className="resp-text-1 font-digi text-center">{"BURN"}</p>
            </Tag>
          </div>
        </div>
      )}

      <CardImg
        {...{
          k: c?.card ?? "back",
          cn: "reveal",
          delay: 1 * 1e3,
          auto_flip: false,
        }}
      />
    </div>
  );
};

const SelectionRow = ({}) => {
  const ccon = useQuestCardsContext();
  const { cards_ob, opened, use_cards, set_use_cards } = ccon;

  return (
    <div class="p-2 my-2 lg:max-w-[60vw] xs:max-w-[95vw] mx-auto">
      <div class="fr-sc gap-2 w-full flex-wrap">
        {opened.map((id, i) => {
          let c = cards_ob[id];
          let k = nils(c) ? "back" : c.card;
          let cn = "reveal";
          let active = use_cards.includes(id);
          return (
            <div
              onClick={() => {
                let nuse = _.clone(use_cards);
                if (active) {
                  nuse = _.without(nuse, id);
                } else {
                  if (nuse.length >= sel_cards_n)
                    nuse = nuse.slice(1, sel_cards_n);
                  nuse = [...nuse, id];
                }
                set_use_cards(nuse);
              }}
              class={twMerge(
                "xs:w-[4rem] lg:w-[8rem] xs:border-[0.2rem] lg:border-[0.3rem] relative resp-p-1 rounded-md",
                active
                  ? "border-acc0 text-acc0"
                  : "border-slate-500 text-slate-500",
              )}
            >
              <div class="absolute top-[5px] right-[5px] w-max z-[2]">
                <FontAwesomeIcon
                  icon={active ? faCheckCircle : faCircle}
                  className="resp-text-2"
                />
              </div>
              <CardImg
                {...{
                  key: i,
                  k,
                  cn,
                  auto_flip: false,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CreateComboSection = () => {
  const ccon = useQuestCardsContext();
  const { cards, cards_ob, set_cards, use_cards, set_use_cards } = ccon;
  const card_refs = useRef([]);

  return (
    <div class="w-full fr-sc xs:gap-[0.5rem] lg:gap-[1rem] my-[1rem]">
      {use_cards === undefined
        ? null
        : [...new Array(sel_cards_n)].map((a, i) => {
            let id = use_cards[i];
            let c = cards_ob[id];
            let k = nils(c) ? "back" : c.card;
            let posfromcenter = Math.abs(i - Math.floor(sel_cards_n / 2));
            let delay = (2 + posfromcenter * 0.3) * 1e3;
            return (
              <CardImg
                key={i}
                ref={(re) => card_refs.current[i]}
                k={k}
                delay={delay}
              />
            );
          })}
    </div>
  );
};

const ResultSection = () => {
  const ccon = useQuestCardsContext();
  const { auth } = useAuthContext();
  const { vault } = useAccountContext();
  const lcon = useLayoutContext();

  const {
    cards,
    set_cards,
    use_cards,
    set_use_cards,

    hand,
    hand_valid,
    hand_result,

    qcs,
    qrs,
  } = ccon;

  const [resp, set_resp] = useState({});
  const [popup, set_popup] = useState(false);

  const open_cashout_popup = () => {
    set_popup(true);
    set_resp({});
  };
  const close_cashout_popup = () => {
    set_popup(false);
    set_resp({});
  };

  const post_cashout = () => {
    set_resp({});
    set_cards(
      _.chain(cards)
        .map((e) => {
          if (use_cards.includes(e.id)) {
            e.burned = true;
          }
          return e;
        })
        .value(),
    );
    set_use_cards([]);
    close_cashout_popup();
    qcs.refetch();
    qrs.refetch();
  };
  const do_cashout = async () => {
    try {
      set_resp({ type: "loading", msg: "sending cashout request..." });
      await cdelay(2 * 1e3);
      console.log("do_cashout", use_cards, vault);
      let res = await q_quest_cards_cashout({
        cardids: use_cards,
        vault,
      }).queryFn();
      if (res?.err) throw new Error(res.err);

      set_resp({
        type: "success",
        msg: "cashout request sent, please contact admin to process",
      });
      setTimeout(() => {
        post_cashout();
      }, 3 * 1e3);
    } catch (err) {
      console.log("do_cashout", err);
      set_resp({ type: "error", msg: err.message });
      setTimeout(() => {
        set_resp({});
      }, 2 * 1e3);
    }
  };

  const enabled = !nils(vault) && popup;
  const [qoprize] = useQueries([
    q_quest_cards_est_prize({ hand }, { enabled }),
  ]);
  const handprize = useMemo(
    () => getv(qoprize, "data.result"),
    [qoprize.dataUpdatedAt],
  );

  return (
    <div class="fc-cc">
      {false && (
        <>
          <p className="resp-text-1">{hand.join(", ")}</p>
          <p class="text-center resp-text-2">
            valid: {hand_valid ? "yes" : "no"}
          </p>
          <div class="fr-sc">
            <p class="text-center resp-text-2">Combo: {hand_result}</p>
          </div>
        </>
      )}
      {hand_valid && (
        <>
          {auth ? (
            <Tag
              onClick={open_cashout_popup}
              className={twMerge("border-2 border-acc4 text-acc4")}
            >
              <p class="resp-text-1 font-digi font-reg ">CASHOUT</p>
            </Tag>
          ) : (
            <Tag
              onClick={() => lcon.set_loginpop(true)}
              className={twMerge("border-2 border-acc4 text-acc4")}
            >
              <p class="resp-text-1 font-digi font-reg ">Login to Cashout</p>
            </Tag>
          )}
        </>
      )}
      <PopUp
        wrapcn={"xs:top-[1rem] lg:top-[5rem]"}
        innercn={"translate-y-[0%]"}
        openstate={popup}
        onclose={close_cashout_popup}
      >
        <PopupCloseBtn {...{ closepopup: close_cashout_popup }} />
        <Card
          className={twMerge(
            "xs:w-[95vw] lg:w-[60rem] bg-r2dark/40 backdrop-blur-md border border-acc4 p-4 overflow-auto h-[40rem]",
          )}
        >
          <div class="w-full p-2 my-2 overflow-auto grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
            <div class="col-span-1 ">
              <div class="w-full relative flex-wrap h-[30rem]">
                {hand.map((e, i) => {
                  let posfromcenter = i - Math.floor(hand.length / 2) - 1;
                  let off = 20;
                  return (
                    <div
                      class={twMerge(
                        "w-[10rem] aspect-[242/340] absolute top-[5rem] left-[50%]",
                        `transform rotate-[${posfromcenter * off}deg] cardtransform-origin`,
                        "z-[10] hover:z-[20] transition duration-300",
                      )}
                    >
                      <CardImg
                        key={i}
                        k={e}
                        cn=""
                        delay={(2 + i * 0.3) * 1e3}
                        auto_flip={true}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div class="col-span-1 fc-cc h-full">
              <p className="resp-text-3 font-digi text-acc4">
                {snake_str_to_caps(hand_result)}
              </p>
              {qoprize.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qoprize) ? (
                <p className="resp-text-1 font-digi text-red-400">
                  {qiserr(qoprize)}
                </p>
              ) : qissuccesss(qoprize) ? (
                <div className="fr-sc resp-gap-2 resp-text-1 font-digi text-white">
                  <span>Est Prize: </span>
                  <span className="font-reg text-green-300">
                    {format_prizename(getv(handprize, "prize"))}
                  </span>
                </div>
              ) : null}
              <div class="h-[1rem]"></div>
              {qissuccesss(qoprize) &&
                !nils(getv(handprize, "prize")) &&
                resp.type !== "loading" && (
                  <>
                    <Tag
                      onClick={() => {
                        if (resp?.type == "loading") return;
                        do_cashout();
                      }}
                      className={twMerge(
                        "border-2 border-acc4 text-acc4 hover:bg-acc4/30 hover:text-white",
                      )}
                    >
                      <p className="resp-text-1 font-digi text-white">
                        CASHOUT NOW
                      </p>
                    </Tag>
                    <p class="my-2 text-acc0">
                      This operation will reward you with{" "}
                      {format_prizename(getv(handprize, "prize"))}
                      and burn your selected cards.
                    </p>
                  </>
                )}
              {!_.isEmpty(resp) && (
                <div
                  class={twMerge(
                    "fr-sc resp-gap-2 border rounded-md w-full mx-4 px-4 py-2 resp-text-1 font-digi",
                    nils(resp)
                      ? "text-white border-white"
                      : resp.type == "loading"
                        ? "text-blue-400 border-blue-400"
                        : resp.type == "info"
                          ? "text-blue-400 border-blue-400"
                          : resp.type == "success"
                            ? "text-green-300 border-green-300"
                            : resp.type == "error"
                              ? "text-red-400 border-red-400"
                              : "text-white border-white",
                  )}
                >
                  {resp.type == "loading" ? (
                    <Loader01c size="s" />
                  ) : (
                    <FontAwesomeIcon
                      className="resp-text-2"
                      icon={
                        resp.type == "info"
                          ? faInfoCircle
                          : resp.type == "success"
                            ? faCheckCircle
                            : resp.type == "error"
                              ? faTimesCircle
                              : null
                      }
                    />
                  )}
                  <p className="">{resp.msg}</p>
                </div>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </div>
  );
};

const RewardsSection = () => {
  const ccon = useQuestCardsContext();
  const { qrs } = ccon;
  const card_refs = useRef([]);
  const rewards = useMemo(
    () => getv(qrs, "data.result", []),
    [qrs.dataUpdatedAt],
  );

  return (
    <div class="w-full fr-sc gap-[1rem] my-[1rem] overflow-auto resp-text--1">
      {_.isEmpty(rewards) ? (
        <></>
      ) : (
        <div className="w-full">
          <Card className={"w-full bg-r2lig/20 backdrop-blur-md"}>
            <p class="resp-text-2 font-digi">Rewards</p>
            <table
              className={twMerge(tablecn.table, "thintdrowp4-table-r2lig")}
            >
              <tbody>
                {rewards.map((e, i) => {
                  return (
                    <tr key={e.id}>
                      <td>
                        {e.minted ? (
                          <div class="xs:w-[1rem] lg:w-[3rem] aspect-[1/1]">
                            <Link to={polytxnidlink(getv(e, "mint.hash"))}>
                              <Img img={polychainimg} />
                            </Link>
                          </div>
                        ) : (
                          <div class="">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="text-acc4 resp-text-2"
                            />
                          </div>
                        )}
                      </td>

                      <td>
                        <div class="fr-sc gap-2">
                          {e.hand.map((c) => {
                            return (
                              <div class="xs:w-[2rem] lg:w-[4rem]">
                                <CardImg key={i} k={c} cn="reveal" />
                              </div>
                            );
                          })}
                        </div>
                      </td>

                      <td>
                        <div class="fc-ss resp-text--1 font-digi">
                          <p className="text-acc4">
                            Combo: {snake_str_to_caps(e.combo)}
                          </p>
                          <p className="text-acc4">
                            Prize: {format_prizename(getv(e, "prize"))}
                          </p>
                          <div class="xs:bloc lg:hidden resp-text--2">
                            {iso_format(e.cashout_at)}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="xs:hidden lg:block">
                          {iso_format(e.cashout_at)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      )}
    </div>
  );
};

export const QuestCards = () => {
  const { psearch } = useAppContext();
  const accon = useAccountContext();
  const { vault: auth_vault } = accon;
  const { auth } = useAuthContext();

  const vault = useMemo(() => {
    let v = nils(psearch.v) ? null : psearch.v.toLowerCase();
    if (nils(v)) return auth_vault;
    return v;
  }, [auth_vault, psearch.v]);

  const [qo_vaultinfo] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
  ]);
  const v = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);

  const page_title_fn = () => "Quest Cards";

  const enabled = !nils(vault) && auth == true;
  const [qcs, qrs] = useQueries([
    q_quest_cards_cardslist({ vault }, { enabled }),
    q_quest_cards_rewards({ vault }, { enabled }),
  ]);

  const [cards, set_cards] = useState([]);
  useEffect(() => {
    set_cards(getv(qcs, "data.result", []));
  }, [qcs.dataUpdatedAt]);

  const [cards_ob = {}, packed = [], opened = []] = useMemo(() => {
    let cs = cards ?? [];
    let cso = _.keyBy(cs, "id");
    let packed = _.chain(cs)
      .filter((e) => {
        return e.burned !== true && e.allotted !== true;
      })
      .map("id")
      .value();
    let opened = _.chain(cs)
      .filter((e) => {
        return e.burned !== true && e.allotted === true;
      })
      .map("id")
      .value();
    return [cso, packed, opened];
  }, [jstr(cards)]);

  const [use_cards, set_use_cards] = useState([]);
  useEffect(() => {
    if (use_cards !== undefined && _.isEmpty(cards)) return;
    let acs = _.chain(cards)
      .filter((e) => {
        return e.burned !== true && e.allotted == true;
      })
      .map((e) => e.id)
      .slice(0, sel_cards_n)
      .value();
    console.log("QuestCards:active_cards", acs);
    set_use_cards(acs);
  }, [jstr(cards)]);
  const rewards = useMemo(
    () => getv(qrs, "data.result", []),
    [qrs.dataUpdatedAt],
  );

  const [revealer_popup, set_revealer_popup] = useState(false);
  const open_revealer_popup = () => {
    set_revealer_popup(true);
  };
  const close_revealer_popup = () => {
    qcs.refetch();
    set_revealer_popup(false);
  };
  const hand = useMemo(() => {
    let hand = use_cards.map((e) => cards_ob[e]?.card);
    return hand;
  }, [jstr(use_cards)]);
  const [hand_valid, hand_result] = useMemo(() => {
    let o = new PokerHandEvaluator(hand);
    if (!o.is_valid()) return [false, null];
    let [combo] = o.evaluateHand();
    console.log("hand_result", combo);
    return [true, combo];
  }, [jstr(hand)]);

  const [burnallrunning, set_burnallrunning] = useState(false);
  const [burnerr, set_burnerr] = useState(null);
  const burn_all = async () => {
    try {
      set_burnallrunning(true);
      set_burnerr(null);
      let res = await q_quest_cards_burnall({ cardids: use_cards }).queryFn();
      console.log("burn_all", res?.result);
      if (!nils(res.err)) throw new Error(res.err);
      set_use_cards([]);
      await cdelay(3 * 1e3);
      qcs.refetch();
    } catch (err) {
      console.log("burn_all", err);
      set_burnerr(err.message);
      setTimeout(() => {
        set_burnerr(null);
      }, 6 * 1e3);
    }
    set_burnallrunning(false);
  };

  const open_new_eligible = opened?.length >= tot_cards_n;
  const [revealing, set_revealing] = useState(null);

  const ccon = {
    cards,
    cards_ob,
    packed,
    opened,
    qcs,
    qrs,
    cards,
    set_cards,
    use_cards,
    set_use_cards,
    hand,
    hand_valid,
    hand_result,
    revealing,
    set_revealing,
  };
  // useEffect(() => { console.log("ccon", ccon); }, [jstr(ccon)]);

  const BurnAllSection =
    opened?.length >= tot_cards_n ? (
      <Tag
        onClick={() => {
          if (burnallrunning) return;
          burn_all();
        }}
        className="border fr-sc resp-gap-2 border-red-400 text-red-400 hover:bg-red-400/30 hover:text-white"
      >
        {burnallrunning && <Loader01c size="s" extcn="bg-red-400" />}
        <span className="resp-text--1 font-digi font-reg ">
          {!nils(burnerr) ? `Error: ${burnerr}` : "Burn All"}
        </span>
      </Tag>
    ) : null;

  return (
    <PageWrapper page_title_fn={page_title_fn} cont_cn="xs:w-full lg:w-[80rem]">
      <QuestCardsContext.Provider value={ccon}>
        <div class="fc-cc w-full resp-gap-2">
          <div class="font-digi resp-text-2">Quest Cards</div>
          <p class="text-acc0 font-digi resp-text-1">{v?.vault_name}</p>
          <p class="text-acc0 resp-text--1">{vault}</p>
          <CreateComboSection />
          <ResultSection />

          <div class="my-2 fr-sc mx-auto w-full max-w-[60vw] resp-gap-4">
            <div class="fc-cc resp-gap-1 resp-text-0 font-digi text-acc4">
              <Link to={`/quest-enter?stage=1`}>
                <p className="">You can earn cards by winning quests </p>
              </Link>
              <p>and</p>
              <p>also in races with the card symbol</p>
            </div>
            <div class="flex-1"></div>

            {BurnAllSection}
            <Tag
              onClick={() => {
                open_revealer_popup();
              }}
              className="border border-acc4 text-acc0 hover:bg-acc4/30 hover:text-white"
            >
              <span className="resp-text--1 font-digi font-reg ">
                Open up {packed.length} cards
              </span>
            </Tag>
          </div>
          <PopUp
            wrapcn={"xs:top-[1rem] lg:top-[5rem]"}
            innercn={"translate-y-[0%]"}
            openstate={revealer_popup}
          >
            <PopupCloseBtn {...{ closepopup: close_revealer_popup }} />
            <Card
              className={twMerge(
                "xs:w-[95vw] lg:w-[60rem] bg-r2dark/40 backdrop-blur-md border border-acc4 p-4 overflow-auto xs:h-[60vhh] lg:h-[65rem]",
              )}
            >
              <div class="xs:h-[40vh] lg:h-[45rem] w-full relative  p-2 my-2 overflow-auto">
                <p className="text-white resp-text-1 font-digi pl-2 my-1">
                  Closed Cards: [click to reveal]
                </p>
                {open_new_eligible && (
                  <div class="absolute z-[20] top-[0%] left-[0%] bg-r2dark/20 backdrop-blur-md w-full h-full fc-cc gap-2 resp-text-1 font-digi">
                    <p>You cant have more than {tot_cards_n} open cards</p>
                    <p>Please Burn old cards first</p>
                    {BurnAllSection}
                  </div>
                )}
                <div class={twMerge(open_new_eligible ? "" : "")}>
                  <div class={twMerge("grid grid-cols-4 resp-gap-4 ")}>
                    {packed.map((e) => {
                      return <RevealerCard key={e} id={e} />;
                    })}
                  </div>
                </div>
              </div>
              <hr />
              <div class="xs:h-[20vh] lg:h-[15rem] w-full p-2 ">
                <p className="text-white resp-text-1 font-digi pl-2 my-1">
                  Opened Cards
                </p>
                <div class="fr-cc gap-2">
                  {[...new Array(tot_cards_n)].map((e, i) => {
                    let id = opened[i];
                    let c = cards_ob[id];
                    let k = nils(id) ? "back" : c.card;
                    let cn = "h-full w-auto";
                    if (k == "back")
                      return <CardImg key={i} k={k} cn={cn} delay={0} />;
                    else return <BurnableCard key={i} id={id} cn={cn} />;
                  })}
                </div>
              </div>
            </Card>
          </PopUp>

          <SelectionRow />
          <RewardsSection />
        </div>
      </QuestCardsContext.Provider>
    </PageWrapper>
  );
};

export const RacePage_QuestCard = ({ cs, racing_vaults, vmap }) => {
  const { vault: auth_vault } = useAccountContext();

  return (
    <Link to={`/quest-cards`}>
      <div class="w-full font-digi resp-text--1 resp-gap-2 grid xs:grid-cols-2 lg:grid-cols-3">
        {(cs || []).map((c) => {
          let show = true;
          // console.log(c.card, issamevault);
          return (
            <div class="w-full h-[10rem] fr-cc border-2 bg-r2dark/40 border border-acc4 p-2 rounded-md">
              <div class="w-max p-2">
                <CardImg
                  {...{
                    k: show ? c.card : "back",
                    cn: twMerge(
                      "xs:w-[2rem] lg:w-[5rem]",
                      show ? "reveal" : "",
                    ),
                    auto_flip: show ? false : true,
                  }}
                />
              </div>
              <div class="fc-ss flex-1 text-white">
                <p className="resp-text--2">Rewarded to</p>
                <p className="resp-text--1">
                  {!nils(vmap[c.vault]) ? trim_n(vmap[c.vault], 25) : c.vault}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </Link>
  );
};

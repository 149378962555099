import _ from "lodash";
import { fpost } from "./fetch.js";
import { getv, iso, jparse, jstr, nano, nils } from "../utils/utils.js";
import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import { get_auth_header } from "../wrappers/AuthWrapper.js";
import { get_mm_balance } from "./mm_fns.js";
import {
  get_dez_balance,
  get_t3_dez_balance,
  get_t3_weth_balance,
  get_weth_balance,
} from "../contracts/contract_funcs.js";
import moment from "moment";
import { tokenaumode_locpath } from "../wrappers/MetaMaskWrapper.js";

const usedevloc = 1;

export const qiserr = (q) => {
  if (getv(q, "data.err")) return getv(q, "data.err");
  if (q.status == "error") return "server error";
  if (q.status == "loading") return false;
  return false;
};

export const iserr = (d) => {
  if (getv(d, "err") || d.status == "error") return getv(d, "data.err");
  return false;
};

export const qissuccess = (q) => {
  return getv(q, "status") == "success" && !qiserr(q);
};

export const qissuccesss = (q) => {
  return (
    getv(q, "status") == "success" &&
    getv(q, "data.status") == "success" &&
    !qiserr(q)
  );
};

export const useStepQuery = ({
  key,
  par_ar,
  q_,
  params,
  lim = 1,
  options = {},
  ...props
}) => {
  // useEffect(() => console.log("useStepQuery", par_ar), [par_ar]);

  const [enabled_n, set_enabled_n] = useState(lim);
  const qs = useQueries(
    par_ar?.map((e, idx) => {
      let ext_options = {
        enabled: idx < enabled_n,
        ...options,
      };
      // return () => {return { test: 0 }};
      return q_(...e, ext_options);
    }),
  );
  const c = useMemo(() => {
    let ob = _.countBy(qs, (e) => e.status);
    return {
      success: ob.success ?? 0,
      loading: ob.loading ?? 0,
      error: ob.error ?? 0,
      idle: ob.idle ?? 0,
    };
  }, [qs]);

  useEffect(() => {
    set_enabled_n((parseInt(c.success / lim) + 1) * lim);
  }, [c.success]);

  const datas = useMemo(() => {
    // if (qs_count.loading !== 0) return [];
    let ob = _.chain(qs).filter({ status: "success" }).map("data").value();
    // console.log("all_bikes_data", ob);
    return ob;
  }, [jstr(_.map(qs, "dataUpdatedAt")), c]);

  return {
    qs,
    datas,
    c,
    n: par_ar.length,
    done: c.success,
    tot: par_ar.length,
    loading: c.idle + c.loading > 0,
  };
};

export const btbk =
  usedevloc && process.env.NODE_ENV == "development"
    ? `http://localhost:3005`
    : `https://api.dnaracing.run`;

export const btbk_races = `${btbk}/fbike/races`;
export const btbk_ledger = `${btbk}/fbike/ledger`;

export const polytxnbase = btbk_ledger.includes("dev")
  ? `https://mumbai.polygonscan.com/tx`
  : `https://polygonscan.com/tx`;
export const polytxnlink = (hash) => `${polytxnbase}/${hash}`;
export const polytxnidlink = (id) =>
  `${polytxnbase}/${id ? id.split(":")[0] : null}`;

export const q_auth_get_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_token = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_token`;
  return fpost(api, doc, {});
};

export const q_construct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      return fpost(api, doc);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_cac_construct = (
  k,
  api,
  doc = {},
  ext = {},
  cac_ex,
  cac_id_fn = null,
) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: async () => {
      // console.log("cacfn", { k, doc });
      let now = iso();

      let cacid = nils(cac_id_fn) ? `cac:${k}:${jstr(doc)}` : cac_id_fn(k, doc);

      let exis = localStorage.getItem(cacid);
      if (!nils(exis)) exis = jparse(exis);

      const fetch_n_save = async () => {
        let resp = await fpost(api, doc);
        let expires = moment().add(cac_ex, "seconds").toISOString();
        let o = { cacid, resp, expires };
        // console.log("cacfn:save", cacid, o.expires);
        localStorage.setItem(cacid, jstr(o));
        return resp;
      };

      let expired = nils(exis)
        ? true
        : nils(exis?.expires)
          ? true
          : nano(exis?.expires) < nano(now);
      if (expired) {
        localStorage.removeItem(cacid);

        // console.log("cacfn:expired", cacid);
        if (!nils(exis)) {
          fetch_n_save();
          return exis.resp;
        } else {
          return await fetch_n_save();
        }
      } else {
        // console.log("cacfn:use", cacid);
        return exis.resp;
      }
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_auconstruct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      let headers = get_auth_header();
      return fpost(api, doc, headers);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_vaultinfo = (doc, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct("q_vaultinfo", `${btbk}/fbike/vault/info`, doc, ext);
  return o;
};

export const q_vaultinfo_cc = (doc, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_vaultinfo_cc",
    `${btbk}/fbike/vault/info_cc`,
    doc,
    ext,
  );
  return o;
};

export const q_vaultbikes = (doc, ext = {}) => {
  let o = q_construct("q_vaultbikes", `${btbk}/fbike/vault/bikes`, doc, ext);
  return o;
};

export const q_vaultbikes_cc = (doc, ext = {}) => {
  let o = q_construct(
    "q_vaultbikes_cc",
    `${btbk}/fbike/vault/bikes_cc`,
    doc,
    ext,
  );
  return o;
};

export const q_bikeinfo = (doc, ext = {}) => {
  let o = q_construct("q_bikeinfo", `${btbk}/fbike/i/info`, doc, ext);
  return o;
};
export const q_bikeinfo_cacidfn = (k, doc) => {
  return `cac:q_bikeinfo:${doc.hid}`;
};
export const q_bikeinfo_cac = (doc, ext = {}) => {
  // console.log("q_bikeinfo_cac");
  let o = q_cac_construct(
    "q_bikeinfo",
    `${btbk}/fbike/i/info`,
    doc,
    ext,
    10 * 60,
    q_bikeinfo_cacidfn,
  );
  return o;
};
export const q_bikesearch = (doc, ext = {}) => {
  let o = q_construct("q_bikesearch", `${btbk}/fbike/i/search`, doc, ext);
  return o;
};
export const q_bike_mini_info = (doc, ext = {}) => {
  let o = q_construct("q_bikemini_info", `${btbk}/fbike/i/mini_info`, doc, ext);
  return o;
};
export const q_bikeinfoclear = (doc, ext = {}) => {
  let cacid = q_bikeinfo_cacidfn("q_bikeinfo", doc);
  if (cacid) localStorage.removeItem(cacid);

  let o = q_construct(
    "q_bikeinfoclear",
    `${btbk}/fbike/i/info-clear`,
    doc,
    ext,
  );
  return o;
};
export const q_bikehstats = (doc, ext = {}) => {
  let o = q_construct("q_bikehstats", `${btbk}/fbike/i/hstats`, doc, ext);
  return o;
};
export const q_bikerecentraces = (doc, ext = {}) => {
  let o = q_construct(
    "q_bikerecentraces",
    `${btbk}/fbike/i/recentraces`,
    doc,
    ext,
  );
  return o;
};

export const q_vault_updprofile = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_vault_updprofile",
    `${btbk}/fbike/vault/updprofile`,
    doc,
    ext,
  );
  return o;
};

export const q_vault_transfer = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_vault_transfer",
    `${btbk}/fbike/vault/transfer`,
    doc,
    ext,
  );
  return o;
};

export const q_open_minis = (doc, ext = {}) => {
  let next = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  let o = q_construct("q_open_minis", `${btbk_races}/open_minis`, doc, next);
  return o;
};
export const q_races_payout_report = (doc, ext = {}) => {
  return q_construct(
    "q_races_payout_report",
    `${btbk_races}/payout_report`,
    doc,
    ext,
  );
};

export const q_open_races = (doc, ext = {}) => {
  let next = {
    staleTime: 30 * 1e3,
    refetchInterval: 60 * 1e3,
    ...ext,
  };
  let o = q_construct("q_open_races", `${btbk_races}/open_races`, doc, next);
  return o;
};

export const q_nexttorun = (doc, ext = {}) => {
  let next = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  let o = q_construct("q_nexttorun", `${btbk_races}/nexttorun`, doc, next);
  return o;
};
export const q_finraces = (doc, ext = {}) => {
  let next = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  let o = q_construct("q_finraces", `${btbk_races}/finraces`, doc, next);
  return o;
};

export const q_open_hs_in = (doc, ext = {}) => {
  let next = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  let o = q_construct("q_open_hs_in", `${btbk_races}/open_hs_in`, doc, next);
  return o;
};

export const q_arcade_open_hs_in = (doc, ext = {}) => {
  let next = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_arcade_hs_in",
    `${btbk}/fbike/arcade/open_hs_in`,
    doc,
    next,
  );
  return o;
};

export const q_token_prices = (doc, ext = {}) => {
  let next = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_token_prices",
    `${btbk}/fbike/token_prices`,
    doc,
    next,
  );
  return o;
};

export const q_open_vault_valid_hs = (doc, ext = {}) => {
  let o = q_auconstruct(
    "q_open_vault_valid_hs",
    `${btbk_races}/open_vault_valid_hs`,
    doc,
    ext,
  );
  return o;
};

export const q_open_enter = (doc, ext = {}) => {
  let o = q_auconstruct("q_open_enter", `${btbk_races}/open_enter`, doc, ext);
  return o;
};

export const q_hstats = (doc, ext = {}) => {
  let o = q_construct("q_hstats", `${btbk_races}/hstats`, doc, ext);
  return o;
};

export const q_hraces = (doc, ext = {}) => {
  let o = q_construct("q_hraces", `${btbk}/fbike/i/hraces`, doc, ext);
  return o;
};

export const q_vaultraces = (doc, ext = {}) => {
  let next = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  let o = q_auconstruct("q_vaultraces", `${btbk_races}/vaultraces`, doc, next);
  return o;
};

export const q_race = (doc, ext = {}) => {
  let next = {
    staleTime: 2 * 60 * 1000,
    refetchInterval: 60 * 1000,
    ...ext,
  };
  let o = q_auconstruct("q_race", `${btbk_races}/race`, doc, next);
  return o;
};

export const q_race_result = (doc, ext = {}) => {
  let next = {
    staleTime: 2 * 60 * 1000,
    refetchInterval: 60 * 1000,
    ...ext,
  };
  let o = q_construct("q_race_result", `${btbk_races}/race_result`, doc, next);
  return o;
};

export const q_racerun = (doc, ext = {}) => {
  let next = { ...ext };
  let o = q_construct("q_racerun", `${btbk_races}/run`, doc, next);
  return o;
};

export const q_arcade_race = (doc, ext = {}) => {
  let next = {
    staleTime: 2 * 60 * 1000,
    refetchInterval: 60 * 1000,
    ...ext,
  };
  let o = q_construct("q_arcade_race", `${btbk}/fbike/arcade/race`, doc, next);
  return o;
};
export const q_arcade_race_result = (doc, ext = {}) => {
  let next = {
    staleTime: 2 * 60 * 1000,
    refetchInterval: 60 * 1000,
    ...ext,
  };
  let o = q_construct(
    "q_arcade_race_result",
    `${btbk}/fbike/arcade/race_result`,
    doc,
    next,
  );
  return o;
};

export const q_arcade_enter = (doc = {}, ext = {}) => {
  return q_construct("q_arcade_enter", `${btbk}/fbike/arcade/enter`, doc, ext);
};

export const q_arcade_racerun = (doc, ext = {}) => {
  let next = { ...ext };
  let o = q_construct(
    "q_arcade_racerun",
    `${btbk}/fbike/arcade/run`,
    doc,
    next,
  );
  return o;
};

export const q_arcade_race_hstats = (doc, ext = {}) => {
  return q_construct(
    "q_arcade_racerun",
    `${btbk}/fbike/arcade/hstats`,
    doc,
    ext,
  );
};

export const q_ledger = (doc, ext = {}) => {
  let next = { ...ext };
  let o = q_auconstruct("q_ledger", `${btbk_ledger}/ledger`, doc, next);
  return o;
};

export const q_mm_weth_balance = (doc, ext) => {
  return {
    queryKey: ["q_mm_weth_balance", jstr(doc)],
    queryFn: async () => {
      try {
        let redid = `bal:${jstr(doc)}_weth`;
        let aumode = localStorage.getItem(tokenaumode_locpath);
        let bal =
          aumode == "thirdweb"
            ? await get_t3_weth_balance(doc)
            : await get_weth_balance(doc);
        if (!nils(bal)) localStorage.setItem(redid, jstr({ bal }));
        else bal = getv(jparse(localStorage.getItem(redid)), "bal");
        return { status: "success", result: { bal } };
      } catch (err) {
        return { status: "error", err: err.message };
      }
    },
    staleTime: 60 * 1000,
    ...ext,
  };
};
export const q_mm_dez_balance = (doc, ext) => {
  return {
    queryKey: ["q_mm_dez_balance", jstr(doc)],
    queryFn: async () => {
      try {
        let redid = `bal:${jstr(doc)}_dez`;
        let aumode = localStorage.getItem(tokenaumode_locpath);
        let bal =
          aumode == "thirdweb"
            ? await get_t3_dez_balance(doc)
            : await get_dez_balance(doc);
        if (!nils(bal)) localStorage.setItem(redid, jstr({ bal }));
        else bal = getv(jparse(localStorage.getItem(redid)), "bal");
        return { status: "success", result: { bal } };
      } catch (err) {
        return { status: "error", err: err.message };
      }
    },
    staleTime: 60 * 1000,
    ...ext,
  };
};

export const q_jackpot_pot = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  return q_construct("q_jackpot_pot", `${btbk}/fbike/jackpot/pot`, doc, ext);
};

export const q_jackpot_get = (doc, ext = {}) => {
  ext = {
    staleTime: 15 * 1e3,
    refetchInterval: 15 * 1e3,
    ...ext,
  };
  return q_construct("q_jackpot_get", `${btbk}/fbike/jackpot/get`, doc, ext);
};

export const q_jackpot_history = (doc, ext = {}) => {
  ext = {
    staleTime: 1e14,
    refetchInterval: 1e14,
    ...ext,
  };
  return q_construct(
    "q_jackpot_history",
    `${btbk}/fbike/jackpot/history`,
    doc,
    ext,
  );
};

export const q_jackpot_test_pot = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 1e3,
    refetchInterval: 10 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_jackpot_test_pot",
    `${btbk}/fbike/jackpot_test/pot`,
    doc,
    ext,
  );
};

export const q_jackpot_test_get = (doc, ext = {}) => {
  ext = {
    staleTime: 15 * 1e3,
    refetchInterval: 15 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_jackpot_test_get",
    `${btbk}/fbike/jackpot_test/get`,
    doc,
    ext,
  );
};

export const q_jackpot_test_history = (doc, ext = {}) => {
  ext = {
    staleTime: 1e14,
    refetchInterval: 1e14,
    ...ext,
  };
  return q_construct(
    "q_jackpot_test_history",
    `${btbk}/fbike/jackpot_test/history`,
    doc,
    ext,
  );
};

export const q_logtxnqueue = (doc, ext = {}) => {
  ext = {
    staleTime: 1e14,
    refetchInterval: 1e14,
    ...ext,
  };
  return q_auconstruct("q_logtxnqueue", `${btbk_races}/logtxnqueue`, doc, ext);
};

export const q_fqual_info = (doc, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  return q_construct("q_fqual_info", `${btbk}/fbike/fqual/info`, doc, ext);
};
export const q_fqual_leader = (doc, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  return q_auconstruct(
    "q_fqual_leader",
    `${btbk}/fbike/fqual/leader`,
    doc,
    ext,
  );
};
export const q_fqual_reset_score = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 1e3,
    refetchInterval: 2 * 1e3,
    ...ext,
  };
  return q_auconstruct(
    "q_fqual_reset_score",
    `${btbk}/fbike/fqual/reset_score`,
    doc,
    ext,
  );
};
export const q_fqual_actives = (doc, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_fqual_actives",
    `${btbk}/fbike/fqual/actives`,
    doc,
    ext,
  );
};
export const q_fqual_factionactives = (doc, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_fqual_factionactives",
    `${btbk}/fbike/fqual/factionactives`,
    doc,
    ext,
  );
};

// /fqual/eligraces
export const q_fqual_eligraces = (doc = {}, ext = {}) => {
  return q_construct(
    "q_fqual_eligraces",
    `${btbk}/fbike/fqual/eligraces`,
    doc,
    ext,
  );
};

// /fqual/sheet_allowed
export const q_fqual_sheet_allowed = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_fqual_sheet_allowed",
    `${btbk}/fbike/fqual/sheet_allowed`,
    doc,
    ext,
  );
};
// /fqual/updatesheet
export const q_fqual_updatesheet = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_fqual_updatesheet",
    `${btbk}/fbike/fqual/updatesheet`,
    doc,
    ext,
  );
};

export const q_fqual_vault_scoreboard = (doc, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_fqual_vault_scoreboard",
    `${btbk}/fbike/fqual/vault_scoreboard`,
    doc,
    ext,
  );
};

export const q_ranks = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_ranks", `${btbk}/fbike/ranks/get`, doc, ext);
};

export const q_vaultskins = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_vaultskins", `${btbk}/fbike/skins/byvault`, doc, ext);
};
export const q_skininfo = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_skininfo", `${btbk}/fbike/skins/get`, doc, ext);
};
export const q_skinclear = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_skinclear", `${btbk}/fbike/skins/get-clear`, doc, ext);
};
export const q_skinvalidate = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_skinvalidate",
    `${btbk}/fbike/skins/validate`,
    doc,
    ext,
  );
};
export const q_skinupdate = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_skinupdate", `${btbk}/fbike/skins/update`, doc, ext);
};
export const q_trailupdate = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_trailupdate", `${btbk}/fbike/trails/update`, doc, ext);
};

export const q_pagebanner = (doc, ext = {}) => {
  ext = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_pagebanner", `${btbk}/fbike/info/pagebanner`, doc, ext);
};

export const q_splicing_arena = (doc, ext = {}) => {
  ext = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_arena",
    `${btbk}/fbike/splicing3/arena`,
    doc,
    ext,
  );
};

export const q_splicing_enter_arena_validate = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_enter_arena_validate",
    `${btbk}/fbike/splicing3/enter_arena_validate`,
    doc,
    ext,
  );
};
export const q_splicing_offchain_update = (doc, ext = {}) => {
  return q_auconstruct(
    "q_splicing_offchain_update",
    `${btbk}/fbike/splicing3/offchain_update`,
    doc,
    ext,
  );
};

export const q_splicing_enter_pair_validate = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_enter_pair_validate",
    `${btbk}/fbike/splicing3/enter_pair_validate`,
    doc,
    ext,
  );
};

export const q_splicing_pair_info = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_pair_info",
    `${btbk}/fbike/splicing3/pair_info`,
    doc,
    ext,
  );
};
export const q_splicing_splice_requests = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_splice_requests",
    `${btbk}/fbike/splicing3/splice_requests`,
    doc,
    ext,
  );
};
export const q_splicing_bestpairs = (doc = {}, ext = {}) => {
  return q_construct(
    "q_splicing_bestpairs",
    `${btbk}/fbike/splicing3/bestpairs`,
    doc,
    ext,
  );
};

export const q_splicing_add_txnque = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_add_txnque",
    `${btbk}/fbike/splicing3/add_txnque`,
    doc,
    ext,
  );
};

export const q_splicing_enter_pair_validate_red = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_enter_pair_validate_red",
    `${btbk}/fbike/splicing3/enter_pair_validate_red`,
    doc,
    ext,
  );
};

export const q_splicing_splicedocdb = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_splicing_splicedocdb",
    `${btbk}/fbike/splicing3/splicedocdb`,
    doc,
    ext,
  );
};

export const q_bikename_validate = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_bikename_validate",
    `${btbk}/fbike/i/validate_name`,
    doc,
    ext,
  );
};

export const q_validate_vault_name = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_validate_vault_name",
    `${btbk}/fbike/vault/validate_vault_name`,
    doc,
    ext,
  );
};

export const q_bikename_set = (doc, ext = {}) => {
  ext = {
    staleTime: 10 * 60 * 1e3,
    refetchInterval: 10 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_bikename_validate",
    `${btbk}/fbike/i/set_name`,
    doc,
    ext,
  );
};

export const q_races_options = (doc, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_races_options",
    `${btbk}/fbike/races/open_options`,
    doc,
    ext,
  );
};

export const q_skinteams = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_skinteams",
    `${btbk}/fbike/fqual_skinteams/actives`,
    doc,
    ext,
  );
};

export const q_skinteams_doc = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_skinteams_doc",
    `${btbk}/fbike/fqual_skinteams/doc`,
    doc,
    ext,
  );
};

export const q_satellites = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_satellites",
    `${btbk}/fbike/satellites/actives`,
    doc,
    ext,
  );
};

export const q_satellite = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_satellites", `${btbk}/fbike/satellites/doc`, doc, ext);
};

export const q_satellitesv2 = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_satellitesv2",
    `${btbk}/fbike/satellitesv2/actives`,
    doc,
    ext,
  );
};
export const q_satellitev2_doc = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_satellitev2_doc",
    `${btbk}/fbike/satellitesv2/doc`,
    doc,
    ext,
  );
};
export const q_satellitev2_open_options = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_satellitev2_open_options",
    `${btbk}/fbike/satellitesv2/open_options`,
    doc,
    ext,
  );
};
export const q_satellitev2_open_qualraces = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_satellitev2_open_qualraces",
    `${btbk}/fbike/satellitesv2/open_qualraces`,
    doc,
    ext,
  );
};

export const q_maiden_s = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_maiden", `${btbk}/fbike/maiden/actives`, doc, ext);
};
export const q_maiden_doc = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_maiden_doc", `${btbk}/fbike/maiden/doc`, doc, ext);
};
export const q_maiden_open_options = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_maiden_open_options",
    `${btbk}/fbike/maiden/open_options`,
    doc,
    ext,
  );
};
export const q_maiden_open_qualraces = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_maiden_open_qualraces",
    `${btbk}/fbike/maiden/open_qualraces`,
    doc,
    ext,
  );
};

export const q_os_search = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct("q_os_search", `${btbk}/fbike/opensea/search`, doc, ext);
};

export const q_market_bidsn = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_market_bidsn",
    `${btbk}/fbike/dnamarket/info/bidslist_n`,
    doc,
    ext,
  );
};
export const q_market_list = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_market_list",
    `${btbk}/fbike/dnamarket/info/list`,
    doc,
    ext,
  );
};

export const q_splice_force_remstud = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_splice_force_remstud",
    `${btbk}/fbike/splicing3/force_remstud`,
    doc,
    ext,
  );
};

export const q_vault_trophies = (doc = {}, ext = {}) => {
  return q_construct(
    "q_vault_trophies",
    `${btbk}/fbike/vault/trophies`,
    doc,
    ext,
  );
};

export const q_recentraces = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_recentraces",
    `${btbk}/fbike/races/recentraces`,
    doc,
    ext,
  );
};

export const q_recentpays = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 20 * 1e3,
    refetchInterval: 20 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_recentpays",
    `${btbk}/fbike/races/recentpays`,
    doc,
    ext,
  );
};

export const q_creators = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 1e14,
  };
  return q_construct("q_creators", `${btbk}/fbike/info/creators`, doc, ext);
};

export const q_fqual_signup_hvalid = (doc = {}, ext = {}) => {
  return q_construct(
    "q_fqual_signup_hvalid",
    `${btbk}/fbike/fqual/signup_hvalid`,
    doc,
    ext,
  );
};

export const q_fqual_signup_vault_hvalids = (doc = {}, ext = {}) => {
  return q_construct(
    "q_fqual_signup_vault_hvalids",
    `${btbk}/fbike/fqual/signup_vault_hvalids`,
    doc,
    ext,
  );
};

export const q_fqual_signup_enter = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_fqual_signup_enter",
    `${btbk}/fbike/fqual/signup_enter`,
    doc,
    ext,
  );
};
export const q_fqual_entrants = (doc = {}, ext = {}) => {
  return q_construct(
    "q_fqual_entrants",
    `${btbk}/fbike/fqual/entrants`,
    doc,
    ext,
  );
};

export const q_splicing_activity = (doc = {}, ext = {}) => {
  return q_construct(
    "q_splicing_activity",
    `${btbk}/fbike/splicing3/activity`,
    doc,
    ext,
  );
};

export const q_hstats_doc = (doc = {}, ext = {}) => {
  return q_construct("q_hstats_doc", `${btbk}/fbike/i/hstats_doc`, doc, ext);
};

export const q_price = (doc = {}, ext = {}) => {
  return q_construct("q_price", `${btbk}/fbike/i/price`, doc, ext);
};

export const q_inbox_get = (doc = {}, ext = {}) => {
  return q_construct("q_inbox_get", `${btbk}/fbike/inbox/get`, doc, ext);
};

export const q_inbox_actions = (doc = {}, ext = {}) => {
  return q_construct(
    "q_inbox_actions",
    `${btbk}/fbike/inbox/actions`,
    doc,
    ext,
  );
};

export const q_inbox_mark_read = (doc = {}, ext = {}) => {
  return q_construct(
    "q_inbox_mark_read",
    `${btbk}/fbike/inbox/mark_read`,
    doc,
    ext,
  );
};

export const q_vault_raceresults = (doc = {}, ext = {}) => {
  return q_construct(
    "q_vault_raceresults",
    `${btbk}/fbike/vault/raceresults`,
    doc,
    ext,
  );
};

export const q_vault_claim_training_bike = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_claim_training_bike",
    `${btbk}/fbike/vault/claim_training_bike`,
    doc,
    ext,
  );
};
export const q_vault_delete_trainer_bike = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_delete_trainer_bike",
    `${btbk}/fbike/vault/delete_trainer_bike`,
    doc,
    ext,
  );
};

export const q_dnamarket_activity_marketid = (doc = {}, ext = {}) => {
  return q_construct(
    "q_dnamarket_activity_marketid",
    `${btbk}/fbike/dnamarket/activity/marketid`,
    doc,
    ext,
  );
};
export const q_races_rid = (doc = {}, ext = {}) => {
  return q_construct(
    "q_dnamarket_activity_marketid",
    `${btbk}/fbike/dnamarket/activity/marketid`,
    doc,
    ext,
  );
};
export const q_races_rid_rel_rs = (doc = {}, ext = {}) => {
  return q_construct(
    "q_races_rid_rel_rs",
    `${btbk}/fbike/races/rid_rel_rs`,
    doc,
    ext,
  );
};

export const q_staking_get_dez_staked = (doc = {}, ext = {}) => {
  return q_construct(
    "q_staking_get_dez_staked",
    `${btbk}/fbike/staking/get_dez_staked`,
    doc,
    ext,
  );
};

export const q_staking_dez_stake_info = (doc = {}, ext = {}) => {
  return q_construct(
    "q_staking_dez_stake_info",
    `${btbk}/fbike/staking/dez_stake_info`,
    doc,
    ext,
  );
};

export const q_staking_transactions = (doc = {}, ext = {}) => {
  return q_construct(
    "q_staking_transactions",
    `${btbk}/fbike/staking/transactions`,
    doc,
    ext,
  );
};
export const q_staking_pending_transactions = (doc = {}, ext = {}) => {
  return q_construct(
    "q_staking_pending_transactions",
    `${btbk}/fbike/staking/pending_transactions`,
    doc,
    ext,
  );
};

export const q_godowns = (doc = {}, ext = {}) => {
  return q_construct(
    "q_godowns",
    `${btbk}/fbike/dnamarket/vault/assets`,
    {
      asset_type: "god",
      vault: doc.vault,
    },
    ext,
  );
};

export const q_genesis_hids = (doc = {}, ext = {}) => {
  return q_construct(
    "q_genesis_hids",
    `${btbk}/fbike/staking/genesis_bikes`,
    doc,
    ext,
  );
};

export const q_genesis_rewards = (doc = {}, ext = {}) => {
  return q_construct(
    "q_genrewards",
    `${btbk}/fbike/staking/bike_reward`,
    doc,
    ext,
  );
};

export const q_vault_reward = (doc = {}, ext = {}) => {
  return q_construct(
    "q_vault_reward",
    `${btbk}/fbike/staking/vault_reward`,
    doc,
    ext,
  );
};
export const q_claim_reward = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_claim_reward",
    `${btbk}/fbike/staking/claim_reward`,
    doc,
    ext,
  );
};

export const q_que_txn = (doc, ext = {}) => {
  return q_auconstruct("q_que_txn", `${btbk}/fbike/txns_que/add`, doc, ext);
};

export const q_sheet_pages_season0 = (doc, ext = {}) => {
  return q_construct(
    "q_sheet_pages_season0",
    `${btbk}/fbike/sheet_pages/season0`,
    doc,
    ext,
  );
};
export const q_sheet_pages_season1 = (doc, ext = {}) => {
  return q_construct(
    "q_sheet_pages_season1",
    `${btbk}/fbike/sheet_pages/season1`,
    doc,
    ext,
  );
};
export const q_sheet_pages_season2 = (doc, ext = {}) => {
  return q_construct(
    "q_sheet_pages_season2",
    `${btbk}/fbike/sheet_pages/season2`,
    doc,
    ext,
  );
};

export const q_sheet_pages_funds = (doc, ext = {}) => {
  return q_construct(
    "q_sheet_pages_funds",
    `${btbk}/fbike/sheet_pages/funds`,
    doc,
    ext,
  );
};

export const q_sheet_pages_chainplay = (doc, ext = {}) => {
  return q_construct(
    "q_sheet_pages_chainplay",
    `${btbk}/fbike/sheet_pages/chainplay`,
    doc,
    ext,
  );
};

export const q_rally_list = (doc = {}, ext = {}) => {
  return q_construct("q_rally_list", `${btbk}/fbike/rally/list`, doc, ext);
};

export const q_rally_info = (doc = {}, ext = {}) => {
  return q_construct("q_rally_info", `${btbk}/fbike/rally/info`, doc, ext);
};

export const q_rally_leader = (doc = {}, ext = {}) => {
  return q_construct("q_rally_leader", `${btbk}/fbike/rally/leader`, doc, ext);
};

export const q_rally_edit = (doc = {}, ext = {}) => {
  return q_auconstruct("q_rally_edit", `${btbk}/fbike/rally/edit`, doc, ext);
};

export const q_rally_delete = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_rally_delete",
    `${btbk}/fbike/rally/delete`,
    doc,
    ext,
  );
};

export const q_raceautofiller_txns = (doc = {}, ext = {}) => {
  return q_construct(
    "q_raceautofiller_txns",
    `${btbk}/fbike/raceautofiller/txns`,
    doc,
    ext,
  );
};
export const q_autofiller_active_bike_summary = (doc = {}, ext = {}) => {
  return q_construct(
    "q_autofiller_active_bike_summary",
    `${btbk}/fbike/raceautofiller/active_bike_summary`,
    doc,
    ext,
  );
};

export const q_raceautofiller_reqrefund = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_raceautofiller_reqrefund",
    `${btbk}/fbike/raceautofiller/reqrefund`,
    doc,
    ext,
  );
};

export const q_raceautofiller_bulk_raceinfo = (doc = {}, ext = {}) => {
  return q_construct(
    "q_raceautofiller_bulk_raceinfo",
    `${btbk}/fbike/raceautofiller/bulk_raceinfo`,
    doc,
    ext,
  );
};

export const q_raceautofiller_bulk_vault_validity = (doc = {}, ext = {}) => {
  return q_construct(
    "q_raceautofiller_bulk_vault_validity",
    `${btbk}/fbike/raceautofiller/bulk_vault_validity`,
    doc,
    ext,
  );
};

export const q_vault_settings_get = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_settings_get",
    `${btbk}/fbike/vault/settings/get`,
    doc,
    ext,
  );
};

export const q_vault_settings_set = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_settings_set",
    `${btbk}/fbike/vault/settings/set`,
    doc,
    ext,
  );
};

export const q_lctoken_counts = (doc = {}, ext = {}) => {
  return q_construct(
    "q_lctoken_counts",
    `${btbk}/fbike/lctoken/counts`,
    doc,
    ext,
  );
};

// /factions/agents/owns
export const q_factions_agents_owns = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_agents_owns",
    `${btbk}/fbike/factions/agents/owns`,
    doc,
    ext,
  );
};

// /factions/agents/info
export const q_factions_agents_info = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_agents_info",
    `${btbk}/fbike/factions/agents/info`,
    doc,
    ext,
  );
};

// /factions/agents/assign
export const q_factions_agents_assign = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_agents_assign",
    `${btbk}/fbike/factions/agents/assign`,
    doc,
    ext,
  );
};

// /factions/missions/open_agent
export const q_factions_missions_open_agent = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_missions_open_agent",
    `${btbk}/fbike/factions/missions/open_agent`,
    doc,
    ext,
  );
};

// /factions/missions/reroll
export const q_factions_missions_reroll = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_reroll",
    `${btbk}/fbike/factions/missions/reroll`,
    doc,
    ext,
  );
};

// /factions/missions/agredo
export const q_factions_missions_agredo = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_factions_missions_agredo",
    `${btbk}/fbike/factions/missions/agredo`,
    doc,
    ext,
  );
};

// /factions/bingo/get
export const q_factions_bingo_get = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_bingo_get",
    `${btbk}/fbike/factions/bingo/get`,
    doc,
    ext,
  );
};

// /factions/bingo/history
export const q_factions_bingo_history = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_bingo_history",
    `${btbk}/fbike/factions/bingo/history`,
    doc,
    ext,
  );
};

// /fbike/misc/zed_stimmy
export const q_misc_digh_stimmy = (doc = {}, ext = {}) => {
  return q_construct(
    "q_misc_digh_stimmy",
    `${btbk}/fbike/misc/digh_stimmy/eligible`,
    doc,
    ext,
  );
};

// /zlead/stable_dets
export const q_zlead_stable_dets = (doc = {}, ext = {}) => {
  return q_construct(
    "q_zlead_stable_dets",
    `${btbk}/zlead/stable_dets`,
    doc,
    ext,
  );
};

// /factions/blitz/actives
// /factions/blitz/info
// /factions/blitz/history

export const q_factions_blitz_actives = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_blitz_actives",
    `${btbk}/fbike/factions/blitz/actives`,
    doc,
    ext,
  );
};

export const q_factions_blitz_info = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_blitz_info",
    `${btbk}/fbike/factions/blitz/info`,
    doc,
    ext,
  );
};

export const q_factions_blitz_history = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_blitz_history",
    `${btbk}/fbike/factions/blitz/history`,
    doc,
    ext,
  );
};

// /fbike/quest/create
export const q_quest_create = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_create",
    `${btbk}/fbike/quest/create`,
    doc,
    ext,
  );
};

// /fbike/quest/markenter
export const q_quest_mark_enter = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_mark_enter",
    `${btbk}/fbike/quest/markenter`,
    doc,
    ext,
  );
};

// /fqual/get_likes
export const q_fqual_get_likes = (doc = {}, ext = {}) => {
  return q_construct(
    "q_fqual_get_likes",
    `${btbk}/fbike/fqual/get_likes`,
    doc,
    ext,
  );
};

// /fqual/set_likes
export const q_fqual_set_likes = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_fqual_set_likes",
    `${btbk}/fbike/fqual/set_likes`,
    doc,
    ext,
  );
};

// /fbike/bdetails/advsearch
export const q_bike_advsearch = (doc = {}, ext = {}) => {
  return q_construct("q_bikesearch", `${btbk}/fbike/i/advsearch`, doc, ext);
};

// /fbike/quest/set_user_cashout_option
export const q_quest_set_user_cashout_option = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_set_user_cashout_option",
    `${btbk}/fbike/quest/set_user_cashout_option`,
    doc,
    ext,
  );
};
// /fbike/quest/set_user_cashout_autoupgrade
export const q_quest_set_user_cashout_autoupgrade = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_set_user_cashout_autoupgrade",
    `${btbk}/fbike/quest/set_user_cashout_autoupgrade`,
    doc,
    ext,
  );
};

// /fbike/trainer/vault_stage
export const q_trainer_vault_stage = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_trainer_vault_stage",
    `${btbk}/fbike/trainer/vault_stage`,
    doc,
    ext,
  );
};

// /fbike/trainer/parent_options
export const q_trainer_parent_options = (doc = {}, ext = {}) => {
  return q_construct(
    "q_trainer_parent_options",
    `${btbk}/fbike/trainer/parent_options`,
    doc,
    ext,
  );
};

// /fbike/trainer/generate_trainer
export const q_trainer_generate_trainer = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_trainer_generate_trainer",
    `${btbk}/fbike/trainer/generate_trainer`,
    doc,
    ext,
  );
};

// /fbike/trainer/leaderboards
export const q_trainer_leaderboards = (doc = {}, ext = {}) => {
  return q_construct(
    "q_trainer_leaderboards",
    `${btbk}/fbike/trainer/leaderboards`,
    doc,
    ext,
  );
};

// /fbike/races/myraces
export const q_races_myraces = (doc = {}, ext = {}) => {
  return q_construct(
    "q_races_myraces",
    `${btbk}/fbike/races/myraces`,
    doc,
    ext,
  );
};

// /fbike/trainer/myraces
export const q_trainer_myraces = (doc = {}, ext = {}) => {
  return q_construct(
    "q_trainer_myraces",
    `${btbk}/fbike/trainer/myraces`,
    doc,
    ext,
  );
};

//fbike/quest_cards/cardslist
export const q_quest_cards_cardslist = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_cardslist",
    `${btbk}/fbike/quest_cards/cardslist`,
    doc,
    ext,
  );
};

//fbike/quest_cards/rewards
export const q_quest_cards_rewards = (doc = {}, ext = {}) => {
  return q_construct(
    "q_quest_cards_rewards",
    `${btbk}/fbike/quest_cards/rewards`,
    doc,
    ext,
  );
};

//fbike/quest_cards/burnall
export const q_quest_cards_burnall = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_burnall",
    `${btbk}/fbike/quest_cards/burnall`,
    doc,
    ext,
  );
};

//fbike/quest_cards/reveal
export const q_quest_cards_reveal = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_reveal",
    `${btbk}/fbike/quest_cards/reveal`,
    doc,
    ext,
  );
};

//fbike/quest_cards/burn
export const q_quest_cards_burn = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_burn",
    `${btbk}/fbike/quest_cards/burn`,
    doc,
    ext,
  );
};

//fbike/quest_cards/est_prize
export const q_quest_cards_est_prize = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_est_prize",
    `${btbk}/fbike/quest_cards/est_prize`,
    doc,
    ext,
  );
};

//fbike/quest_cards/cashout
export const q_quest_cards_cashout = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_quest_cards_cashout",
    `${btbk}/fbike/quest_cards/cashout`,
    doc,
    ext,
  );
};
//fbike/trails_cards/cc
export const q_trails_cards_cc = (doc = {}, ext = {}) => {
  return q_construct(
    "q_trails_cards_cc",
    `${btbk}/fbike/trails_cards/cc`,
    doc,
    ext,
  );
};

//fbike/trails_cards/owns_cards
export const q_trails_cards_owns_cards = (doc = {}, ext = {}) => {
  return q_construct(
    "q_trails_cards_owns_cards",
    `${btbk}/fbike/trails_cards/owns_cards`,
    doc,
    ext,
  );
};

//fbike/trails_cards/attempt_block
export const q_trails_cards_attempt_block = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_trails_cards_attempt_block",
    `${btbk}/fbike/trails_cards/attempt_block`,
    doc,
    ext,
  );
};

//fbike/trails_cards/start
export const q_trails_cards_start = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_trails_cards_start",
    `${btbk}/fbike/trails_cards/start`,
    doc,
    ext,
  );
};

//fbike/trails_cards/burn
export const q_trails_cards_burn = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_trails_cards_burn",
    `${btbk}/fbike/trails_cards/burn`,
    doc,
    ext,
  );
};

// /fbike/vault/get_cprofiles
export const q_vault_get_cprofiles = (doc = {}, ext = {}) => {
  return q_construct(
    "q_vault_get_cprofiles",
    `${btbk}/fbike/vault/get_cprofiles`,
    doc,
    ext,
  );
};

// /fbike/vault/save_cprofile
export const q_vault_save_cprofile = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_save_cprofile",
    `${btbk}/fbike/vault/save_cprofile`,
    doc,
    ext,
  );
};

// /fbike/vault/delete_cprofile
export const q_vault_delete_cprofile = (doc = {}, ext = {}) => {
  return q_auconstruct(
    "q_vault_delete_cprofile",
    `${btbk}/fbike/vault/delete_cprofile`,
    doc,
    ext,
  );
};

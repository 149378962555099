import React, { useMemo, useState } from "react";
import {
  BImg,
  Card,
  InpText,
  Tag,
  TokenIcon,
  ToolTip,
} from "./utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faBattery,
  faBiohazard,
  faBolt,
  faBoltLightning,
  faCake,
  faCheckCircle,
  faCircle,
  faDna,
  faEarth,
  faF,
  faFemale,
  faFire,
  faFlag,
  faHammer,
  faInfinity,
  faMale,
  faMars,
  faMask,
  faMaskFace,
  faMinus,
  faPersonWalkingArrowRight,
  faSpinner,
  faTeletype,
  faTimesCircle,
  faTriangleExclamation,
  faTrophy,
  faUsd,
  faVenus,
  faVrCardboard,
  faWater,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { MoVariants } from "../utils/motion_helper.js";
import {
  cbsk,
  cdelay,
  copy_clip,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nils,
  to_time_mini,
} from "../utils/utils.js";
import _, { valuesIn } from "lodash";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { useMask } from "@react-three/drei";
import moment from "moment";
import Splicing from "../contracts/Splicing/SplicingContract.js";
import { PopUp, PopupCloseBtn } from "./popup.js";
import { extract_inp, InpDropdown, set_val_inp } from "./input.js";
import { Loader01c } from "./anims.js";
import {
  q_bike_mini_info,
  q_bikeinfo,
  q_bikeinfoclear,
  q_bikename_set,
  q_bikename_validate,
  q_splice_force_remstud,
  q_splicing_enter_arena_validate,
  qissuccesss,
  q_vault_delete_trainer_bike,
  q_splicing_offchain_update,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { ElementTag, MiniGenderTag } from "./ShortComps.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { BikeImg, RVImg } from "./BikeImg.js";
import { t3_contract_call } from "../contracts/contract_funcs.js";
import { useThirdWebLoginContext } from "../views/ThirdWebLogin.js";

export const MiniStatCard = ({ icon, head, txt, icn, cn, wrapcn, rvmode }) => {
  return (
    <div
      className={twMerge(
        "bg-r2dark/60 border border-acc4",
        "resp-px-2 resp-py-3 rounded-md",
        wrapcn,
      )}
    >
      <div className="w-full fr-sc resp-gap-1 resp-text--2">
        <FontAwesomeIcon icon={icon} />
        <div className="font-mon text-slate-300 w-max">{head}</div>
        <div class="flex-1"></div>
        <div
          className={twMerge("font-mon resp-text--1 text-white font-bold", cn)}
        >
          {txt}
        </div>
      </div>
    </div>
  );
};

const typemap = _.chain([
  ["genesis", Infinity, [45, "days"], 3, 1],
  ["morphed", 21, [60, "days"], 3, 1],
  ["freak", 12, [60, "days"], 3, 1],
  ["xclass", 3, [60, "days"], 3, 1],
])
  .map((e) => {
    let [type, mxlife_splices_n, cycle_dur, m, f] = e;
    return {
      type,
      mxlife_splices_n,
      cycle_dur,
      mxcycle_splices_n: {
        male: m,
        female: f,
      },
    };
  })
  .keyBy("type")
  .value();

const gen_init_doc = (h) => {
  if (nils(h)) return null;
  // console.log(h);
  let o = _.cloneDeep(typemap[h.type]);
  o.hid = h.hid;

  o.mxcycle_splices_n = o.mxcycle_splices_n[h.gender];
  o.mxlife_splices_n = typemap[h.type].mxlife_splices_n;

  o.in_stud = false;

  o.cycle_starts = iso();
  o.cycle_resets = moment(o.cycle_start)
    .add(o.cycle_dur[0], o.cycle_dur[1])
    .toISOString();

  o.life_splices_n = 0;
  o.cycle_splices_n = 0;

  o.mainsplicecore = false;

  return o;
};

const ForceExitStud = ({ hid }) => {
  const { vault } = useAccountContext();
  const [loading, set_loading] = useState(false);
  const exit = async () => {
    try {
      set_loading(true);
      await cdelay(1000);
      await q_splice_force_remstud({ hid, vault }).queryFn();
      await cdelay(1000);
      set_loading(false);
    } catch (err) {
      console.log(err);
      set_loading(false);
    }
  };
  return (
    <>
      <ToolTip
        msg_cn="min-w-[10rem] resp-text--2"
        message={"fix by force exit"}
      >
        <Tag onClick={exit} className="text-red-400 resp-text-1">
          <FontAwesomeIcon icon={faPersonWalkingArrowRight} />
        </Tag>
      </ToolTip>
      {loading && <Loader01c size="s" />}
    </>
  );
};

export const SpliceCoreInfo = ({ h, mybike, contcn = "", acc }) => {
  const { aumode } = useAuthContext();
  const { active_account } = useThirdWebLoginContext();

  const b = useMemo(() => {
    let b = null;
    if (nils(h)) return null;
    if (["trainer"].includes(h.type)) return null;
    if (nils(h.splice_core)) b = gen_init_doc(h);
    else b = h.splice_core;

    if (h.type !== "genesis") {
      let bday = getv(h, "mint.date");
      let diff = moment().diff(moment(bday), "days");
      if (diff < 30) {
        b.elig_entry = false;
        b.elig_entry_msg = `New Born! Eligible to enter arena after ${
          30 - diff
        } days`;
      }
    }

    if (b.life_splices_n >= b.mxlife_splices_n) {
      b.elig_entry = false;
      b.elig_entry_msg = `Max Life Splices Reached`;
    }

    if (b.cycle_splices_n >= b.mxcycle_splices_n) {
      b.elig_entry = false;
      b.elig_entry_msg = `Max Splices Limit Reached for Cycle `;
    }

    return b;
  }, [jstr(h)]);

  const [entering, set_entering] = useState(false);
  const [loading, set_loading] = useState(false);
  const [entering_msg, set_entering_msg] = useState(null);
  const [entering_err, set_entering_err] = useState(null);

  const [data, set_data] = useState({ fee: null, dur: null });

  const enter_arena = async () => {
    if (mybike !== true) return;
    try {
      set_loading(true);
      set_entering_err(null);
      set_entering_msg(null);

      let price_usd = extract_inp(`inp_core_fee_${h.hid}`, "n");
      price_usd = parseInt(price_usd);
      console.log("enter_arena", { price_usd });

      if (!_.inRange(price_usd, 1, 100000 + 0.1))
        throw new Error("Please enter a valid number, Min:1, Mx:100000");
      else set_val_inp(`inp_core_fee_${h.hid}`, price_usd);

      set_entering_msg("Validating your Request...");
      let valid_resp = await q_splicing_enter_arena_validate({
        hid: h.hid,
        price_usd,
      }).queryFn();
      if (!nils(getv(valid_resp, "data.err"))) {
        throw new Error(getv(valid_resp, "data.err"));
      }

      set_entering_msg("Setting Core Fee...");
      await cdelay(2000);

      await q_splicing_offchain_update({ hid: h.hid, data }).queryFn();
      await cdelay(2000);

      if (aumode == "thirdweb") {
        set_entering_msg("Sending Txn...");
        const resp = await t3_contract_call(
          "splicing",
          "enterArena",
          [h.hid, price_usd],
          "txn",
          true,
          { active_account: active_account },
        );
        await cdelay(2 * 1e3);
      } else {
        set_entering_msg("Please Confirm the transaction on metamask...");
        const splicecon = await Splicing.get_contract();
        const resp = await splicecon.enterArena(h.hid, price_usd);
        console.log("entry hash", resp.hash, resp.transactionHash);
      }

      set_entering_msg(
        "It might take a few minutes to update,\n Please retry again after 10minutes if not updated",
      );
      await q_bikeinfoclear({ hid: h.hid }).queryFn();

      await cdelay(2000);
      await q_bikeinfo({ hid: h.hid }).queryFn();

      await cdelay(5000);
      set_loading(false);
      set_entering(false);
      set_entering_msg(null);
      set_entering_err(null);
    } catch (err) {
      if (!nils(err.reason)) set_entering_err(`Metamask: ${err.reason}`);
      else set_entering_err(`Error: ${err.message}`);
      set_loading(false);
      await cdelay(5000);
      set_entering(false);
    }
  };

  if (h.burned === true)
    return (
      <div>
        <p className="text-red-300">Retired core can't splice anymore </p>
      </div>
    );

  if (h.is_trainer === true)
    return (
      <div>
        <p className="text-red-300">Trainer core can't splice </p>
      </div>
    );

  return (
    <div
      style={{
        border: `1px solid ${acc}`,
        background: `${"#000000"}30`,
      }}
      className={twMerge(
        // "bg-r2dark/60",
        //
        " my-2 w-full rounded-md p-4 resp-text--2",
        contcn,
      )}
    >
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-2">
        <div>
          <div className="fr-sc resp-gap-2">
            <span className="text-left xs:min-w-[5rem] lg:min-w-[10rem]">
              Lifetime Splices
            </span>
            <span className="text-fr-sc resp-gap-1 font-digi resp-text--1">
              <span className="text-acc0 resp-text-1">{b.life_splices_n} </span>
              <span className="text-white">
                [max
                {b.mxlife_splices_n >= 1e8 ? (
                  <FontAwesomeIcon icon={faInfinity} />
                ) : (
                  <span>{b.mxlife_splices_n}</span>
                )}
                ]
              </span>
            </span>
          </div>
          <div className="fr-ss resp-gap-2">
            <span className="text-left xs:min-w-[5rem] lg:min-w-[10rem]">
              Cycle Splices
            </span>
            <span className="fr-sc resp-gap-1 font-digi resp-text--1">
              <span className="text-acc0 resp-text-1">
                {b.mxcycle_splices_n - b.cycle_splices_n}
              </span>
              <span>left of </span>
              <span>{b.mxcycle_splices_n}</span>
            </span>
          </div>
          {/* <hr className="my-2" /> */}
        </div>

        {b.mainsplicecore === false ? (
          <div>
            <p className="text-left resp-text--1 text-yellow-300">
              Breed Cycle dates start from date of first breed
            </p>
          </div>
        ) : (
          <div className="">
            <table className="thintdrowp4-table-nb">
              <tbody>
                <tr>
                  <td className="resp-text--1 text-white">
                    {"Cycle Starts: "}
                  </td>
                  <td className="resp-text--1 text-acc0">
                    {b.cycle_starts > iso()
                      ? `in ${from_time_mini(b.cycle_starts)}`
                      : `${iso_format(b.cycle_starts)}`}
                  </td>
                </tr>
                <tr>
                  <td className="resp-text--1 text-white">
                    {"Cycle Resets: "}
                  </td>
                  <td className="resp-text--1 text-acc0">
                    {`in ${from_time_mini(b.cycle_resets)}`}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <hr className="my-2" /> */}
          </div>
        )}
        <div>
          <div className="fr-sc w-full resp-text--1">
            {b.in_stud == true ? (
              <p className="text-green-400 w-full">
                <span className="font-digi resp-text-0 italic">{"IN "}</span>
                <span>{"Splice Arena @ "}</span>
                <span className="font-digi resp-text-0">{`${dec(b.price_usd, 2)} USD`}</span>
              </p>
            ) : (
              <p className="text-red-400 w-full">
                <span className="font-digi resp-text-0 italic">
                  {"NOT IN "}
                </span>
                <span>Splice Arena</span>
              </p>
            )}
          </div>
          {b.elig_entry === false ? (
            <>
              <p className="text-center text-yellow-300 whitespace-pre-wrap my-2">
                {b.elig_entry_msg}
              </p>
            </>
          ) : (
            <>
              {b.in_stud !== true && mybike === true && (
                <div className="my-2 fr-cc ">
                  {
                    <>
                      <Tag
                        onClick={() => {
                          set_entering(true);
                        }}
                        className="bg-acc0/40 text-white -skew-x-12 shadow-md shadow-acc0"
                      >
                        Enter Arena
                      </Tag>
                      <ForceExitStud {...{ hid: h.hid }} />
                    </>
                  }

                  <PopUp openstate={entering} overlayclose={false}>
                    <Card
                      className={"realative bg-r2dark/40 border border-acc4"}
                    >
                      <div className="fr-sc">
                        <div className="flex-1"></div>
                        <PopupCloseBtn closepopup={() => set_entering(false)} />
                      </div>
                      <p className="text-center font-digi resp-text-2 text-acc0">
                        {"Entering Splice Arena"}
                      </p>
                      <p className="text-center font-mon resp-text--1">
                        {"Please Set your Parent Core Fees [in USD] "}
                      </p>
                      <p className="text-center font-mon resp-text--1">
                        {"whole number; Eg: 20, 50, 67, 1000"}
                      </p>
                      <div className="flex flex-col justify-center items-center resp-gap-4 my-2">
                        <InpText
                          {...{
                            id: `inp_core_fee_${h.hid}`,
                            placeholder: "Enter Core Fee in USD",
                            setter: (v) => {
                              set_data({ ...data, fee: v });
                            },
                          }}
                        />
                        <div className="fr-sc w-full">
                          <InpDropdown
                            {...{
                              id: `inp_core_dur_${h.hid}`,
                              options: [5, 10],
                              txt: nils(data.dur)
                                ? "Select Duration"
                                : `${data.dur} days`,
                              text_fn: (v) => `${v} days`,
                              setter: (v) => {
                                set_data({ ...data, dur: v });
                              },
                              option_cn: "resp-text--1 resp-py-2",
                              active_cn: "bg-acc4/5",
                              is_active: (v) => v == data?.dur,
                            }}
                          />
                          <div className="flex-1"></div>
                          {loading ? (
                            <>
                              <Loader01c size="s" />
                            </>
                          ) : (
                            <Tag
                              onClick={() => {
                                enter_arena();
                              }}
                              className="bg-acc0/40 text-center font-digi -skew-x-12"
                            >
                              Enter Arena
                            </Tag>
                          )}
                        </div>
                        {entering_err && (
                          <p className="whitespace-pre-wrap rounded-md border text-red-400 border-red-400 p-2 resp-text--2">
                            {entering_err}
                          </p>
                        )}
                        {entering_msg && (
                          <p className="whitespace-pre-wrap rounded-md border text-green-400 border-green-400 p-2 resp-text--2">
                            {entering_msg}
                          </p>
                        )}
                      </div>
                    </Card>
                  </PopUp>
                </div>
              )}
            </>
          )}
        </div>
        <>
          {b.in_stud == true && (
            <>
              <table className="thintdrowp4-table-nb">
                <tbody>
                  <tr>
                    <td>
                      <span className="resp-text--1 text-white">
                        {"Enters Time"}
                      </span>
                    </td>
                    <td>
                      <span className="resp-text--1 text-acc0">
                        {b.entry_time > iso()
                          ? `in ${from_time_mini(b.entry_time)}`
                          : `${iso_format(b.entry_time)}`}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="resp-text--1 text-white">
                        {"Exits Stud"}
                      </span>
                    </td>
                    <td>
                      <span className="resp-text--1 text-acc0">
                        {`in ${from_time_mini(b.exits_time)}`}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export const MiniBikeCard = ({ hid, className = "" }) => {
  const [q] = useQueries([q_bike_mini_info({ hid })]);
  const h = useMemo(() => getv(q, "data.result") ?? {}, [q.dataUpdatedAt]);
  return (
    <Card className={twMerge("w-full", className)}>
      <Link to={`/bike/${hid}`}>
        {qissuccesss(q) && (
          <>
            <div className="fr-sc resp-gap-2">
              <div
                className={twMerge(
                  "w-[1.2rem] h-[1.2rem] rounded-sm",
                  `bg-${h.color}`,
                )}
              ></div>
              <span className="font-digi resp-text--1">{h.name}</span>
              <span className="font-digi resp-text--3">{hid}</span>
              <MiniGenderTag gender={h.gender} />
            </div>
            <div className="fr-sc resp-gap-1 text-acc0">
              <span className="font-digi resp-text--2">
                {_.capitalize(h.type)}
              </span>
              <span>/</span>
              <span className="font-digi resp-text--2">{`F${h.fno}`}</span>
              <div className="flex-1"></div>
              <ElementTag
                element={h.element}
                className={"resp-text--4 text-white"}
              />
            </div>
          </>
        )}
      </Link>
    </Card>
  );
};

export const ParentsCard = ({ h }) => {
  if (_.isEmpty(h.parents)) return <></>;
  const { father, mother } = h.parents;
  return (
    <Card
      className={"p-2 w-full bg-r2lig/20 backdrop-blur-md border border-acc4"}
    >
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="">
          <p className="resp-text--2 text-center text-blue-300">Father</p>
          <MiniBikeCard hid={father} />
        </div>
        <div className="">
          <p className="resp-text--2 text-center text-pink-300">Mother</p>
          <MiniBikeCard hid={mother} />
        </div>
      </div>
    </Card>
  );
};

export const SetBikeName = ({ h, after_setting = () => {} }) => {
  const [edit, set_edit] = useState(false);
  const [name, set_name] = useState("");
  const [qvalid] = useQueries([q_bikename_validate({ name })]);
  const valid = getv(qvalid, "data.result.valid") && name.length > 3;

  const [loading, set_loading] = useState(false);
  const set_name_fn = async () => {
    set_loading(true);
    try {
      let resp = await q_bikename_set({ hid: h.hid, name }).queryFn();
      await cdelay(2000);
      set_edit(false);
      await q_bikeinfoclear({ hid: h.hid }).queryFn();
      await cdelay(2000);
      await q_bikeinfo({ hid: h.hid }).queryFn();
      if (after_setting) after_setting();
    } catch (err) {}
    set_loading(false);
  };

  return (
    <>
      <p
        onClick={() => {
          set_edit(!edit);
        }}
        className="my-2 resp-text--2 text-acc0/40 hover:text-acc0 cursor-pointer"
      >
        Set Bike Name to start racing..
      </p>
      {edit && (
        <div className="fr-sc flex-wrap resp-gap-4 my-2">
          <InpText
            inpprops={{
              className: `resp-text--2 xs:w-[60vw] lg:w-[25rem] ${""} `,
            }}
            id={`inp_bikename:${h.hid}`}
            setter={(v) => {
              set_name(v);
            }}
          />
          <div className="flex-1"></div>
          {qvalid.isLoading ? (
            <>
              <FontAwesomeIcon
                className="text-acc4 spin-anim"
                icon={faSpinner}
              />
            </>
          ) : valid ? (
            <>
              <FontAwesomeIcon
                className="text-green-400"
                icon={faCheckCircle}
              />
              {loading ? (
                <Loader01c size="s" />
              ) : (
                <Tag
                  onClick={() => {
                    set_name_fn();
                  }}
                  className="bg-acc0/40 resp-text--2 -skew-x-12"
                >
                  Set Name
                </Tag>
              )}
            </>
          ) : (
            <FontAwesomeIcon className="text-red-400" icon={faTimesCircle} />
          )}
        </div>
      )}
    </>
  );
};

export const gen_bikecard_stats_list = (h, rvmode) => {
  const sk = getv(h, "skin");
  const careerk = nils(rvmode) ? "career" : `career_${rvmode}`;

  return [
    ["Type", _.capitalize(h.type), faTeletype],

    h.gender == "male"
      ? [
          "Gender",
          _.capitalize(h.gender),
          faVenus,
          "text-blue-400",
          "text-blue-400",
        ]
      : [
          "Gender",
          _.capitalize(h.gender),
          faVenus,
          "text-pink-400",
          "text-pink-400",
        ],

    ["F.No", `F${h.fno}`, faF],
    [
      "Element",
      _.capitalize(h.element),
      ...((h.element == "earth" && [
        faEarth,
        "text-orange-900",
        "text-orange-900",
      ]) ||
        (h.element == "water" && [faWater, "text-blue-400", "text-blue-400"]) ||
        (h.element == "fire" && [
          faFire,
          "text-orange-400",
          "text-orange-400",
        ]) ||
        (h.element == "metal" && [
          faHammer,
          "text-slate-900",
          "text-slate-400",
        ]) ||
        []),
    ],
    ["Races", getv(h, `${careerk}.races_n`) ?? 0, faFlag],
    ["Win %", `${dec((getv(h, `${careerk}.win_p`) ?? 0) * 100, 1)}%`, faTrophy],
    ["Paid Races", getv(h, `${careerk}.paid_races_n`) ?? 0, faFlag],
    [
      "Paid Win %",
      `${dec((getv(h, `${careerk}.paid_win_p`) ?? 0) * 100, 1)}%`,
      faTrophy,
    ],
    [
      "Color",
      _.chain(h.color).split("-").map(_.capitalize).join(" ").value(),
      faCircle,
      `text-${h.color}`,
      "",
    ],
    ...(h.type !== "trainer"
      ? [
          [
            "Aging",
            <>
              <div className="fr-sc flex-wrap resp-gap-1 w-full">
                <span class="resp-text--1 text-acc4">{getv(h, "ageing")}</span>
                <p className=" text-left resp-text--1">{` Races Remaing`}</p>
              </div>
            </>,

            faBattery,
            `text-${"white"}`,
            "",
          ],
        ]
      : []),
    [
      "Stamina",
      <>
        <div className="fr-sc flex-wrap resp-gap-2 w-full">
          <p className=" text-left resp-text--1">
            {`${getv(h, "stamina.stamina")} / ${getv(
              h,
              "stamina.max_stamina",
            )}`}
          </p>
          <div className="flex-1"></div>
          {!nils(getv(h, "stamina.next_refill")) && (
            <span className=" text-left resp-text--2">
              <span className="text-green-400">
                {"+1 "}
                <FontAwesomeIcon icon={faBolt} />
              </span>
              <span>{" in "}</span>
              <span>
                {moment(getv(h, "stamina.next_refill")).diff(iso(), "minutes")}
                {" mins"}
              </span>
            </span>
          )}
        </div>
      </>,

      faBattery,
      `text-${"white"}`,
      "",
    ],
    ...(h.type == "genesis"
      ? [
          // [
          //   "Claim",
          //   <div className="flex flex-row justify-around items-center w-full resp-gap-2">
          //     <div class="fc-cc">
          //       <TokenIcon token="WETH" />
          //       <span className="text-purple-400">{`${dec(0, 4)} WETH`}</span>
          //       <Tag className="bg-purple-400/40 text-black">Claim</Tag>
          //     </div>
          //     <div class="fc-cc">
          //       <TokenIcon token="DEZ" />
          //       <span className="text-teal-400">{`${dec(0, 1)} DEZ`}</span>
          //       <Tag className="bg-teal-400/40 text-black">Claim</Tag>
          //     </div>
          //   </div>,
          // ],
        ]
      : []),
    // [
    //   "Special Stamina",
    //   <>
    //     <div className="fr-sc flex-wrap resp-gap-2 w-full">
    //       <p className=" text-left resp-text--1">
    //         {`${getv(h, "special_stamina.stamina")} / ${getv(
    //           h,
    //           "special_stamina.mx"
    //         )}`}
    //       </p>
    //       <div className="flex-1"></div>
    //       {
    //         <span className=" text-left resp-text--2">
    //           <span className="text-green-400">
    //             {"resets"}
    //             <FontAwesomeIcon icon={faBolt} />
    //           </span>
    //           <span>{" in "}</span>
    //           <span>
    //             {moment(getv(h, "special_stamina.resets_at")).diff(
    //               iso(),
    //               "minutes"
    //             )}
    //             {" mins"}
    //           </span>
    //         </span>
    //       }
    //     </div>
    //   </>,
    //
    //   faBattery,
    //   `text-${"white"}`,
    //   "",
    // ],

    [
      "Tourney Profits",
      <>
        <div class="grid grid-cols-2 resp-gap-2">
          <div class="fr-sc">
            <TokenIcon token="WETH" size="xs" />
            <span>
              {dec(getv(h, `${careerk}.tourney_profits.WETH`) ?? 0, 3)}
            </span>
          </div>
          <div class="fr-sc">
            <TokenIcon token="DEZ" size="xs" />
            <span>
              {dec(getv(h, `${careerk}.tourney_profits.DEZ`) ?? 0, 0)}
            </span>
          </div>

          <div class="fr-sc resp-gap-1 pl-2  col-span-2">
            <FontAwesomeIcon icon={faUsd} />
            <span>
              {dec(getv(h, `${careerk}.tourney_profits.prize_usd`) ?? 0, 2)}
            </span>
          </div>
        </div>
      </>,
      faTrophy,
      "text-white",
      "",
    ],

    [
      "Skin",
      nils(sk) ? "None" : `#${sk.skinid} - ${sk.name} - ${sk.rarity}`,
      faMask,
      `text-${"white"}`,
      "",
    ],

    [
      "Trail",
      nils(h?.trail) ? "None" : `${_.capitalize(h.trail)}`,
      faBoltLightning,
      `text-${"white"}`,
      "",
    ],

    ...(!nils(h?.tx_date)
      ? [
          [
            "Birthday",
            <div className="fc-ss resp-gap-1">
              <p className="resp-text--2">{iso_format(h.tx_date)}</p>
              <p className="resp-text--3 text-acc0">{iso(h.tx_date)}</p>
            </div>,
            faCake,
            `text-${"white"}`,
            "",
          ],
        ]
      : []),
  ];
};

export const DeleteTrainer = ({ h }) => {
  const [doc, set_doc] = useState({});
  const { vault } = useAuthContext();
  const sumbit_del = async () => {
    try {
      set_doc({ stage: "deleting" });

      let resp = await q_vault_delete_trainer_bike({
        vault,
        hid: h.hid,
      }).queryFn();

      if (getv(resp, "result.done") == 1) {
        await cdelay(2000);
        window.location.reload();
        set_doc({});
      } else {
        set_doc({});
      }
    } catch (err) {}
  };
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
      className="fc-ss resp-gap-1"
    >
      {nils(doc.stage) ? (
        <span
          onClick={() => {
            set_doc({ stage: "show_confirm" });
          }}
          class="font-digi resp-text--1 text-red-300"
        >
          Delete Bike
        </span>
      ) : doc.stage == "show_confirm" ? (
        <>
          <span>Do you want to remove the trainer bike permanentaly</span>
          <Tag
            onClick={() => {
              sumbit_del();
            }}
            className="bg-red-500"
          >
            <span class="resp-text--1">Confirm Delete</span>
          </Tag>
        </>
      ) : doc.stage == "deleting" ? (
        <div className="fr-sc resp-gap-2">
          <Loader01c size="s" />
          <span class="text-red-400 resp-text--1">
            Deleting Trainer Bike...
          </span>
        </div>
      ) : null}
    </div>
  );
};

function BikeCard({
  bike: h,
  rvmode = "bike",
  mybike = false,
  ext_class = "",
  ext_style = {},
}) {
  const [expand, set_expand] = useState(false);

  const trainer_jsx = (
    <>
      {h?.is_trainer === true && (
        <>
          {getv(h, "trainer.exipired") == true ? (
            <div class="my-2 fr-sc w-full">
              <span className="text-red-400 italic font-digi resp-text--1">
                Retired Trainer Bike
              </span>
            </div>
          ) : (
            <>
              <div class="my-2 fr-sc w-full">
                <span className="text-blue-400 italic font-digi resp-text--1">
                  Trainer Bike
                </span>
                <div class="flex-1"></div>
                <span className="resp-text--3 font-digi">
                  {50 - (getv(h, "career.races_n") ?? 0)} Races Left
                </span>
              </div>
              {mybike && <DeleteTrainer {...{ h }} />}
            </>
          )}
        </>
      )}

      <hr className="resp-my-2" />
    </>
  );

  return (
    <Card
      style={{ ...ext_style }}
      className={twMerge(
        "xs:w-[90%] lg:w-[60rem] resp-py-3 backdrop-blur-md",
        `bike-${h.hid} `,
        ext_class,
      )}
    >
      <div
        // onClick={() => copy_clip(b.hid)}
        className="flex flex-row justify-start items-start resp-gap-4 cursor-pointer"
      >
        <div className="xs:hidden lg:block"></div>
        <div className="flex flex-col flex-1 justify-start items-start">
          <div className="fr-sc resp-gap-1 w-full">
            <Link to={`/bike/${h.hid}`} className="flex-1 fr-sc">
              <Link to={`/bike/${h.hid}`}>
                <RVImg rvmode={rvmode} hex_code={h.hex_code} />
              </Link>
              <div class="fc-ss w-full">
                <div className="fr-sc resp-gap-2">
                  <span className="font-digi resp-text-1">
                    {h.name ?? "Unnamed DNA Splice"}
                  </span>
                  {h.is_maiden && (
                    <span class="resp-text--2 text-acc0">ME</span>
                  )}
                  <span className="font-digi resp-text--3">{h.hid}</span>
                </div>
              </div>
            </Link>

            <Tag
              onClick={() => {
                set_expand(!expand);
              }}
              className="fr-sc resp-gap-2 resp-text--1"
            >
              <span className="font-sebold">{h.vault_name}</span>
              {expand ? (
                <FontAwesomeIcon className="resp-text-1" icon={faAngleDown} />
              ) : (
                <FontAwesomeIcon className="resp-text-1" icon={faAngleRight} />
              )}
            </Tag>
          </div>
          {(h.name == "Unnamed DNA Splice" || nils(h.name)) && mybike && (
            <SetBikeName h={h} />
          )}

          <div className="grid grid-cols-4 resp-gap-2"></div>
          <div className="fr-sc resp-gap-4 text-slate-300 w-full">
            <div className="flex-1"></div>
          </div>
          <motion.div
            variants={MoVariants.show_hide}
            initial="hidden"
            animate={expand ? "visible" : "hidden"}
            className="w-full"
          >
            <hr className="resp-my-2" />

            <div class="fc-ss w-full">{trainer_jsx}</div>

            <div className="grid xs:grid-cols-2 lg:grid-cols-4 resp-gap-2 resp-my-2">
              {[
                ...gen_bikecard_stats_list(h, rvmode),
                ...(mybike
                  ? [
                      [
                        "",
                        <Link to={`/my-vault-3d?selhid=${h.hid}`}>
                          <Tag className="text-white bg-acc0/40 transform -skew-x-0">
                            Edit 3D Assets
                          </Tag>
                        </Link>,
                        faVrCardboard,
                        `text-${"white"}`,
                        "",
                      ],
                    ]
                  : []),

                // [
                //   "Splices",
                //   <Link className="w-full" to={`/splice/${b.hid}`}>
                //     <div className="fr-sc resp-gap-2">
                //       <span className="text-white resp-text-1">0</span>
                //       <span className="text-slate-400 resp-text--2">
                //         {b.gender == "male" ? `3 of 3 left` : `1 of 1 left`}
                //       </span>
                //     </div>
                //   </Link>,
                //   faDna,
                //   "",
                // ],
              ].map(([head, txt, icon, icn, cn]) => {
                return (
                  <MiniStatCard
                    {...{
                      icon,
                      head,
                      txt,
                      icn,
                      cn,
                      wrapcn: [
                        "Color",
                        "Skin",
                        "Splices",
                        "Aging",
                        "Stamina",
                        "Special Stamina",
                        "Claim",
                        "Birthday",
                      ].includes(head)
                        ? "col-span-2"
                        : "",
                    }}
                  />
                );
              })}
            </div>

            {h.type !== "genesis" && (
              <div className="">
                <ParentsCard h={h} mybike={mybike} />
              </div>
            )}
            <>
              {h?.is_trainer !== true && (
                <div className="">
                  <SpliceCoreInfo
                    h={h}
                    mybike={mybike}
                    contcn={"border border-acc4"}
                  />
                </div>
              )}
            </>
          </motion.div>
        </div>
      </div>
    </Card>
  );
}

export default BikeCard;

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQueries } from "react-query";
import {
  q_bikeinfo,
  q_bikeinfoclear,
  q_skinclear,
  q_skininfo,
  q_skinupdate,
  q_skinvalidate,
  q_trailupdate,
  q_vaultbikes,
  q_vaultinfo,
  q_vaultskins,
  qissuccesss,
  useStepQuery,
} from "../queries/queries.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import { Card, InpText, Tag, ToolTip } from "../components/utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { elementmap, gendermap } from "../utils/cn_map.js";
import skin_thumbnail from "../data/skin_thumbnail.jpg";
import skin_thumbnail_grayscale from "../data/skin_thumbnail_grayscale.jpg";
import skinsmap from "../data/skinsmap.json";
import { gets_skinbaseurl, gets_skinurl } from "../utils/links.js";
import { Canvas } from "@react-three/fiber";
import { Vault3DPlayer } from "./Vault3DPlayer.js";
import { ModelsStoreWrapper, base_remote } from "../components/ModelsStore.js";
import FBikeSkin from "../contracts/FBikeSkin/FBikeSkin_Contract.js";
import { PopUp } from "../components/popup.js";
import { Loader01c } from "../components/anims.js";
import { useAppContext } from "../App.js";
import { extract_inp } from "../components/input.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { Trails_PreviewMap } from "../components/Trails_resourcemap.js";
import { VaultBackLink } from "./Vault.js";
import { thirdweb_client, useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { polygon } from "thirdweb/chains";
import { t3_contract_call } from "../contracts/contract_funcs.js";
import { waitForReceipt } from "thirdweb";
import { Link } from "react-router-dom";

const trailsData = {};

export const Vault3DContext = createContext({});
export const useVault3DContext = () => useContext(Vault3DContext);

const SkinsAssetList = () => {
  const vcon = useVault3DContext();
  const { skinssob, selskin, set_selskin } = vcon;
  const groupedskins = useMemo(() => {
    let o = _.chain(skinssob).values().groupBy("skin").value();
    return o;
  }, [jstr(skinssob)]);
  return (
    <div className="grid grid-cols-2 gap-1 max-w-[30rem]">
      <div
        onClick={() => set_selskin(null)}
        className="col-span-1 cursor-pointer relative"
      >
        <MiniSkinCard skin={null} />
        <div className="absolute z-[20] left-0 bottom-[10px]">
          <p className="resp-m-2 resp-text--1 font-digi">Current Skin</p>
        </div>
      </div>
      {_.entries(groupedskins).map(([skinname, skins]) => {
        let s0 = {
          skin: skinname,
          rarity: skins[0].rarity,
        };
        let active = selskin == skinname;
        return (
          <>
            <div
              onClick={() => {
                if (active) set_selskin(false);
                else set_selskin(skinname);
              }}
              className={twMerge(
                "col-span-1 relative",
                active ? "border border-acc0" : "",
              )}
            >
              {active && (
                <div className="absolute bottom-[-15px] z-[20] left-[48%]">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-acc0 rotate-180 xs:text-[1rem] md:text-[1.5rem]"
                  />
                </div>
              )}
              <AssetSkinRow
                {...{
                  key: skinname,
                  n: skins.length,
                  skin: skinname,
                  skindets: s0,
                }}
              />
            </div>
            {active && (
              <div className="col-span-2 relative ">
                <SelectedSkinTable skinname={skinname} skinslist={skins} />
              </div>
            )}
          </>
        );
      })}

      {/* {skinsmap
        .slice(0, 30)
        .map((e) => e[1])
        .map((skin) => {
          return <AssetSkinRow {...{ key: skin, skindets: skin }} />;
        })} */}
    </div>
  );
};

const TrailsAssetList = () => {
  const vcon = useVault3DContext();
  const {
    seltrail,
    set_seltrail,
    selhid,
    bikesob,
    qst_bdocs_ob,
    pageblockpopup,
    set_pageblockpopup,
  } = vcon;

  const b = bikesob[selhid] || {};
  const apply = async (trail) => {
    if (nils(selhid)) return;
    await cdelay(1000);
    let b = bikesob[selhid] || {};
    await set_pageblockpopup(
      `Applying [trail]:${_.upperCase(trail)} on [bike#${selhid}]:${b.name}`,
    );
    await cdelay(1000);
    await q_trailupdate({ hid: selhid, trail }).queryFn();
    await cdelay(1000);
    await q_bikeinfoclear({ hid: selhid }).queryFn();
    await cdelay(1000);
    if (qst_bdocs_ob[selhid]) await qst_bdocs_ob[selhid].refetch();
    await cdelay(1000);
    await set_pageblockpopup(false);
  };

  return (
    <div className="grid grid-cols-2 gap-1 max-w-[30rem] w-full">
      {[null, ..._.keys(Trails_PreviewMap)].map((trail) => {
        let t = Trails_PreviewMap[trail];
        const sametrail = b.trail == seltrail;
        let active = trail == seltrail;

        let e =
          trail == null
            ? {
                name: "None",
                preview: `${base_remote}/trails/${"none"}/preview.jpg`,
              }
            : {
                name: t[0],
                preview: t[1],
              };
        return (
          <>
            <div
              className={twMerge(
                "bg-black h-full col-span-1 w-[15rem] max-w-full  p-0 px-0 py-0 m-0 rounded-lg overflow-hidden",
                active ? "border border-acc0" : "",
              )}
            >
              <div
                onClick={() => {
                  if (active) return set_seltrail(false);
                  else return set_seltrail(trail);
                }}
                className={
                  "relative w-full h-full aspect-[400/200] overflow-hidden"
                }
              >
                <div className="absolute left-0 bottom-[1rem] fr-cc w-full">
                  <div className="font-digi resp-text--1 z-[20] p-2 bg-black/20">
                    {_.capitalize(e.name)}
                  </div>
                </div>
                <div className="absolute w-full h-full img-obey-cont">
                  <img src={e.preview} alt="" />
                </div>
              </div>
            </div>
            {active && (
              <div className="col-span-2 resp-text--2">
                <div className="bg-lig resp-p-4 rounded-md w-full">
                  <div className="fr-sc resp-gap-2">
                    <p className="flex-1">Trail: {_.capitalize(trail)}</p>
                    {!nils(parseInt(selhid)) && (
                      <>
                        {sametrail ? (
                          <span className="italic text-acc0">applied!!</span>
                        ) : (
                          <Tag
                            onClick={() => apply(trail)}
                            className="bg-acc0/40 transform -skew-x-12"
                          >
                            Apply
                          </Tag>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

const AssetsList = () => {
  let [assettype, set_assettype] = useState("skin");

  return (
    <div className="w-full h-full card-dark-bg border border-acc4 rounded-lg resp-p-2 fc-cc">
      <VaultBackLink />
      <div className="resp-py-2 border-b border-acc1/30 fr-cc w-full resp-gap-2">
        {["skin", "trail"].map((k) => {
          return (
            <Tag
              onClick={() => set_assettype(k)}
              className={twMerge(
                k == assettype
                  ? "border-b-2 border-r2lig text-r2lig bg-r2lig/20 transform -skew-x-12"
                  : "",
                "font-digi resp-text--1 transition duration-300",
              )}
            >
              {_.capitalize(k)}
            </Tag>
          );
        })}
        <div className="flex-1"></div>
        <Tag
          redirect={`https://www.dnaracing.run/skins`}
          className={"bg-r2lig/40 transform -skew-x-12"}
        >
          Buy Skins
        </Tag>
      </div>
      <div className="flex-1">
        <div className=" overflow-auto xs:max-h-[30vh] md:max-h-[55vh]">
          {assettype == "skin" && <SkinsAssetList />}
          {assettype == "trail" && <TrailsAssetList />}
        </div>
      </div>
    </div>
  );
};
const Canvas3D = () => {
  const vcon = useVault3DContext();
  const { selhid, selskin, bikesob, skinsmapob } = vcon;

  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const observeTarget = containerRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        setDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        resizeObserver.unobserve(observeTarget);
      }
    };
  }, []);

  const h = bikesob[selhid] || {};
  return (
    <div
      ref={containerRef}
      className="relative w-full h-full block bg-transparent"
    >
      <div className="html-overlay">
        <div
          className={twMerge(
            "absolute fr-sc w-max",
            "xs:top-[0.5rem] xs:left-[0.5rem]",
            "lg:top-[2rem] lg:left-[2rem]",
          )}
        >
          <span className="font-digi xs:text-[0.6rem] md:text-[1.2rem] flex-1">
            {h.name}
          </span>
        </div>
      </div>
      <div class="w-[99%] h-[99%]">
        <Canvas
          style={{
            width: `${dimensions.width * 0.99}px`,
            height: `${dimensions.height * 0.99}px`,
          }}
        >
          <ModelsStoreWrapper>
            <Vault3DPlayer />
          </ModelsStoreWrapper>
        </Canvas>
      </div>
    </div>
  );
};

const MiniHCard = ({ hid }) => {
  const vcon = useVault3DContext();
  const { bikesob, selhid, set_selhid, refresh_bike } = vcon;
  const h = bikesob[hid] ?? {};
  const active = hid == selhid;
  const sk = h.skin;
  const skinimg = nils(sk?.name) ? skin_thumbnail : gets_skinurl(sk.name);

  return (
    <div
      onClick={() => {
        set_selhid(hid);
      }}
      // style={{
      //   background: `url(${})`,
      //   backgroundPosition: "center",
      //   backgroundSize: "cover",
      //   backgroundRepeat: "no-repeat",
      // }}
      className={twMerge(
        " max-h-[20rem]",
        "resp-p-1 rounded-md w-full cursor-pointer aspect-[2200/1600] transition duration-200",
        active
          ? "bg-acc0 xs:border-[0px] md:border-2 border-acc0"
          : "xs:border-[0px] md:border-2 border-transparent",
        "resp-text--1",
      )}
    >
      <div className="relative w-full h-full">
        {<img src={skinimg} className="w-full h-full"></img>}
        <div className=" fr-sc absolute left-0 top-0 gap-2 w-full resp-m-2">
          <ToolTip message={"refresh metadata"} msg_cn="resp-text--2">
            <Tag onClick={() => refresh_bike(h.hid)}>
              <FontAwesomeIcon icon={faSyncAlt} className="text-acc0" />
            </Tag>
          </ToolTip>
          <span className="font-digi  resp-text--1 flex-1">{h.hid}</span>
        </div>
        <div className=" fc-ss absolute right-0 top-0 gap-2 m-2">
          <span
            className={twMerge(
              "font-digi  resp-text--2 flex-1 fr-cc gap-1",
              elementmap[h.element]?.text,
            )}
          >
            <FontAwesomeIcon icon={elementmap[h.element]?.icon} />
            <span>{_.capitalize(h.element)}</span>
          </span>
          <span
            className={twMerge(
              "font-digi  resp-text--2 flex-1 fr-cc gap-1",
              gendermap[h.gender]?.text,
            )}
          >
            <FontAwesomeIcon icon={gendermap[h.gender]?.icon} />
            <span>{_.capitalize(h.gender)}</span>
          </span>
        </div>

        <div className=" fc-ss mx-auto flex-wrap absolute left-0 top-[20%] resp-gap-1 w-max rounded-md bg-black/50 resp-p-2">
          <div className="fr-sc resp-gap-4  resp-text--3 resp-m-1">
            <span>Color:</span>
            <div
              style={{ backgroundColor: `#${getv(h, "hex_code")}` }}
              className="xs:w-[0.6rem] xs:h-[0.6rem] lg:w-[1.2rem] lg:h-[1.2rem] rounded-full"
            ></div>
            <div className="xs:hidden md:block">
              <span>{h.color}</span>
            </div>
          </div>
          <div className="fr-sc resp-gap-4 resp-text--3 resp-m-1">
            <span>Skin:</span>
            <span className="font-digi">
              {nils(sk) ? "None" : `${sk.name}`}
            </span>
          </div>
          <div className="fr-sc resp-gap-4 resp-text--3 resp-m-1">
            <span>Trail:</span>
            <span className="font-digi">
              {nils(h.trail) ? "None" : `${_.capitalize(h.trail)}`}
            </span>
          </div>
        </div>
        <div className=" fr-sc absolute box-border left-0 bottom-0 gap-2 w-[90%] resp-m-2">
          <span className="font-digi resp-text--1 flex-1">{h.name}</span>
        </div>
        <div className=" fr-sc absolute box-border right-0 bottom-0 gap-2 w-max resp-m-2">
          <span className="font-digi resp-text--2 text-acc0 italic">
            F{h.fno}
          </span>
        </div>
      </div>
    </div>
  );
};

const AssetSkinRow = ({ skin, n, skinname }) => {
  return (
    <div className="fr-sc w-full relative cursor-pointer">
      <div className="absolute z-[20] font-digi text-acc0 resp-text-2 top-[1rem] right-[1rem]">
        x{n}
      </div>
      <MiniSkinCard skin={skin} />
    </div>
  );
};
export const MiniSkinCard = ({ skin, viewdets = true }) => {
  const vcon = useVault3DContext();
  const { skinsmapob } = vcon;

  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  const url = gets_skinurl(skin);

  const s = skinsmapob[skin] ?? {};

  const sintersect = useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            setLoaded(true);
          };
          observer.disconnect();
        }
      });
    });
    observer.observe(imgRef.current);
    return () => observer.disconnect();
  }, [url]);

  return (
    <div className={"relative w-full aspect-[2200/1600]"}>
      <div
        style={{
          background: `url(${skin_thumbnail})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className={twMerge(
          "img-obey-cont h-auto",
          "aspect-[2200/1600] w-full trasition duration-300",
        )}
      >
        {
          <div
            className={twMerge(
              "absolute w-full h-full fc-cc transition duration-500",
              "opacity-100",
            )}
          >
            <div className="w-full h-[40%]"></div>
            <div className="w-full h-[60%] bg-gradient-to-b from-transparent to-black">
              <div className="fc-ss resp-gap-2 w-full h-full text-white p-1 ">
                <div className="flex-1"></div>
                <div className={twMerge("font-digi italic", "resp-text--2")}>
                  {skin}
                </div>
                <div className="font-digi resp-text--3">
                  {_.upperCase(s.rarity)}
                </div>
              </div>
            </div>
          </div>
        }
        <img
          ref={imgRef}
          src={loaded ? url : ""}
          alt={skin} // Recommended to provide an alt attribute for accessibility
          loading="lazy"
          thumbnailSrc={skin_thumbnail}
          style={loaded ? {} : { visibility: "hidden" }}
        />
      </div>
    </div>
  );
};
const SelectedSkinTable = ({ skinname, skinslist }) => {
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { connected, active_account } = t3con;

  const vcon = useVault3DContext();
  const {
    bikesob,
    selhid,
    // qst_bdocs_ob,
    // qst_sdocs_ob,
    // pageblockpopup,
    set_pageblockpopup,
    refresh_bike,
    refresh_skin,
  } = vcon;

  const release = async (s) => {
    let { skinid, appliedTo } = s;
    await set_pageblockpopup(`Releasing [skin#${skinid}]:${s.name}`);

    let resp = await q_skinvalidate({ skinid }).queryFn();
    resp = getv(resp, "data.result.allowed");
    if (resp === false) {
      set_pageblockpopup("Skin is not allowed to be released");
      setTimeout(() => {
        set_pageblockpopup(false);
      }, 3000);
    }

    let con =
      aumode == "thirdweb"
        ? await FBikeSkin.get_contract({ nosigner: true, rpc: polygon.rpc })
        : await FBikeSkin.get_contract();
    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(
        "fbikeskin",
        "releaseSkin",
        [skinid],
        "txn",
        false,
        { active_account },
      );
      // console.log("tx", tx);
      await cdelay(5000);
      await set_pageblockpopup(false);
      tx = await waitForReceipt({
        client: thirdweb_client,
        transactionHash: tx.transactionHash,
        chain: polygon,
      });
      tx.hash = tx.transactionHash;
    } else {
      await con.releaseSkin(skinid);
      await cdelay(5000);
      await set_pageblockpopup(false);
      await tx.wait();
    }
    // console.log(tx);
    await cdelay(1000);
    await refresh_skin(skinid);
    await cdelay(1000);
    await Promise.all([refresh_bike(appliedTo)]);
    // await set_pageblockpopup(false);
  };
  const apply = async (s) => {
    if (nils(selhid)) return;
    let { skinid, appliedTo } = s;
    await cdelay(1000);
    let b = bikesob[selhid] || {};
    await set_pageblockpopup(
      `Applying [skin#${skinid}]:${s.name} on [bike#${selhid}]:${b.name}`,
    );

    let resp = await q_skinvalidate({ skinid }).queryFn();
    resp = getv(resp, "data.result.allowed");
    if (resp === false) {
      set_pageblockpopup("Skin is not allowed to be released");
      setTimeout(() => {
        set_pageblockpopup(false);
      }, 3000);
    }

    let con =
      aumode == "thirdweb"
        ? await FBikeSkin.get_contract({ nosigner: true, rpc: polygon.rpc })
        : await FBikeSkin.get_contract();
    console.log("con", con);
    let tx = null;
    if (aumode == "thirdweb") {
      tx = await t3_contract_call(
        "fbikeskin",
        "applySkin",
        [skinid, selhid],
        "txn",
        false,
        { active_account },
      );
      // console.log("tx", tx);
      await cdelay(5000);
      await set_pageblockpopup(false);
      tx = await waitForReceipt({
        client: thirdweb_client,
        transactionHash: tx.transactionHash,
        chain: polygon,
      });
      tx.hash = tx.transactionHash;
    } else {
      tx = await con.applySkin(skinid, selhid);
      await cdelay(5000);
      await set_pageblockpopup(false);
      tx = await tx.wait();
    }
    // console.log(tx);
    await cdelay(1000);
    await refresh_skin(skinid);
    await cdelay(1000);
    await Promise.all([refresh_bike(appliedTo), refresh_bike(selhid)]);
  };

  return (
    <div className="bg-r2lig/20 backdrop-blur-md resp-p-4 rounded-md">
      <table className="w-full">
        <thead>
          <tr className="thinrow text-acc0 text-left resp-text--2">
            <th>ID</th>
            <th className="">Applied On Bike</th>
          </tr>
        </thead>
        <tbody>
          {skinslist.map((s) => {
            const isused = !nils(s.appliedTo) && s.appliedTo !== 0;
            const b = bikesob[s.appliedTo] ?? {};
            return (
              <tr className="thinrow">
                <td>
                  <Tag className="bg-acc0/30 resp-text--2">{s.skinid}</Tag>
                </td>
                <td>
                  <div className="fr-sc gap-1 resp-text--2">
                    {s.appliedTo == 0 || nils(s.appliedTo) ? (
                      <>
                        <span className="italic text-acc0 resp-text--2">
                          SPARE
                        </span>
                      </>
                    ) : (
                      <>
                        <span>{s.appliedTo}</span>
                        <span>{"-"}</span>
                        <span>{b.name}</span>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <ToolTip message={"refresh metadata"} msg_cn="resp-text--2">
                    <Tag onClick={() => refresh_skin(s.skinid)}>
                      <FontAwesomeIcon icon={faSyncAlt} className="text-acc0" />
                    </Tag>
                  </ToolTip>
                </td>
                <td>
                  {isused && (
                    <Tag
                      onClick={() => {
                        release(s);
                      }}
                      className="bg-yellow-400/40 transform -skew-x-12"
                    >
                      Release
                    </Tag>
                  )}
                </td>
                <td>
                  {!nils(parseInt(selhid)) && !isused && (
                    <Tag
                      onClick={() => apply(s)}
                      className="bg-acc0/40 transform -skew-x-12"
                    >
                      Apply
                    </Tag>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const BottomRoller = () => {
  const vcon = useVault3DContext();
  const vaucon = useAccountContext();
  const { hids } = vcon;
  const { bikesob = {} } = vaucon;
  const [visst, set_visst] = useState(0);
  const lim = 5;

  const [searchtxt, set_searchtxt] = useState("");

  const filthids = useMemo(() => {
    if (nils(searchtxt)) return hids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(hids)
      .map((hid) => {
        let h = bikesob[hid];
        if (nils(h)) return null;
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [hid, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(hids), jstr(bikesob), searchtxt]);

  const search_jsx = (
    <InpText
      {...{
        id: "inp-search-bike",
        placeholder: "Search Core",
        contprops: { className: "w-full bg-dark resp-text--1" },
        inputprops: { className: "w-full" },
        def_val: searchtxt ?? "",
        autoComplete: "off",
        setter: (v) => {
          if (nils(v)) v = null;
          set_searchtxt(v);
        },
      }}
    />
  );

  return (
    <div className="w-full relative max-w-full h-full max-h-full card-dark-bg border border-acc4 rounded-lg p-2 overflow-auto">
      <div className="xs:hidden md:block">
        <div class="absolute z-[10] top-[1rem] right-[1rem] fr-sc">
          {search_jsx}
        </div>
        {_.isEmpty(hids) ? (
          <div class="fc-cc w-full h-full resp-text-1 font-digi text-center">
            <p>You don't have any bikes yet</p>

            <Link to="/claim-trainer">
              <Tag className="bg-acc4/60 -skew-x-12">
                <span>Claim a Free Trainer Bike Now</span>
              </Tag>
            </Link>
            <div class="fr-sc">
              <span>--- OR ---</span>
            </div>

            <Link to="https://market.dnaracing.run">
              <Tag className="bg-acc4/10 -skew-x-12">
                <span>Buy a Core from Marketplace</span>
              </Tag>
            </Link>
          </div>
        ) : (
          <div className="fr-cc gap-2 w-full max-h-full">
            <div
              onClick={() => {
                set_visst(Math.max(visst - 1, 0));
              }}
              className="h-full max-h-full fc-cc p-2 select-none"
            >
              <FontAwesomeIcon className="resp-text-2" icon={faChevronLeft} />
            </div>
            {filthids.slice(visst, visst + lim).map((hid) => {
              return (
                <div className="max-h-full">
                  <MiniHCard {...{ key: hid, hid }} />
                </div>
              );
            })}

            <div
              onClick={() => {
                set_visst(Math.min(visst + 1, hids.length - lim));
              }}
              className="h-full max-h-full fc-cc p-2 select-none"
            >
              <FontAwesomeIcon className="resp-text-2" icon={faChevronRight} />
            </div>
          </div>
        )}
      </div>

      <div className="xs:block md:hidden max-w-full">
        <div class="w-full max-w-full my-1">{search_jsx}</div>
        <div class="w-full overflow-auto">
          <div className="fr-sc">
            {filthids.slice(0, 20).map((hid) => {
              return (
                <div className="aspect-[2200/1600] min-h-[14vh] h-full max-h-[10rem] relative">
                  <MiniHCard {...{ key: hid, hid }} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Vault3D = () => {
  const appcon = useAppContext();
  const aucon = useAuthContext();
  const { vault: au_vault } = aucon;

  const selvault = getv(appcon.psearch, "selvault");
  const vault = useMemo(() => {
    if (!nils(selvault)) return selvault;
    else return au_vault;
  }, [au_vault, selvault]);

  const [qo_vaultinfo, qo_vaultbikes, qo_vaultskins] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_vaultbikes({ vault }, { enabled: !nils(vault) }),
    q_vaultskins({ vault }, { enabled: !nils(vault) }),
  ]);

  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);
  const hids = useMemo(() => {
    let vaultbikes = getv(qo_vaultbikes, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultbikes.dataUpdatedAt]);
  const skinids = useMemo(() => {
    let vaultbikes = getv(qo_vaultskins, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultskins.dataUpdatedAt]);

  const qst_bdocs = useStepQuery({
    q_: q_bikeinfo,
    par_ar: hids.map((hid) => [{ hid }]),
    lim: 5,
  });
  const qst_bdocs_ob = useMemo(() => {
    let o = {};
    for (let i = 0; i < hids.length; i++) {
      let hid = hids[i];
      o[hid] = qst_bdocs.qs[i];
    }
    return o;
  }, [jstr(hids)]);
  const bikesob = useMemo(() => {
    let b = {};
    for (let q of qst_bdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      b[d.hid] = d;
    }
    return b;
  }, [jstr(_.map(qst_bdocs.qs, "dataUpdatedAt"))]);

  const qst_sdocs = useStepQuery({
    q_: q_skininfo,
    par_ar: skinids.map((skinid) => [{ skinid }]),
    lim: 5,
  });
  const qst_sdocs_ob = useMemo(() => {
    let o = {};
    for (let i = 0; i < skinids.length; i++) {
      let skinid = skinids[i];
      o[skinid] = qst_sdocs.qs[i];
    }
    return o;
  }, [jstr(skinids)]);
  const skinssob = useMemo(() => {
    let b = {};
    for (let q of qst_sdocs.qs) {
      let d = getv(q, "data.result");
      if (nils(d)) continue;
      b[d.skinid] = d;
    }
    return b;
  }, [jstr(_.map(qst_sdocs.qs, "dataUpdatedAt"))]);

  const [selhid, set_selhid] = useState(appcon.psearch?.selhid ?? false);
  const [selskin, set_selskin] = useState(appcon.psearch?.selskin ?? false);
  const [seltrail, set_seltrail] = useState(appcon.psearch?.seltrail ?? false);

  const rem = { selhid, selskin, seltrail };
  useEffect(() => {
    appcon.upd_psearch(rem);
  }, [jstr(rem)]);

  /*  useEffect(() => {
    console.log({ selhid, selskin, seltrail });
  }, [selhid, selskin, seltrail]); */

  const skinsmapob = useMemo(() => {
    let o = _.chain(skinsmap)
      .map((e) => {
        return {
          skin: e[1],
          name: e[1],
          rarity: e[0],
          acc: e[2],
          previewlink: gets_skinurl(e[1]),
          baselink: gets_skinbaseurl(e[1]),
        };
      })
      .keyBy("skin")
      .value();
    // console.log("skins.len", _.keys(o).length);
    return o;
  }, [skinsmap]);

  const [pageblockpopup, set_pageblockpopup] = useState(false);

  const refresh_skin = async (skinid) => {
    await q_skinupdate({ skinid }).queryFn();
    await cdelay(1000);
    await q_skinclear({ skinid }).queryFn();
    await cdelay(1000);
    if (qst_sdocs_ob[skinid]) await qst_sdocs_ob[skinid].refetch();
  };
  const refresh_bike = async (hid) => {
    await q_bikeinfoclear({ hid: hid }).queryFn();
    await cdelay(1000);
    if (qst_bdocs_ob[hid]) await qst_bdocs_ob[hid].refetch();
  };

  const vcon = {
    qo_vaultinfo,
    qo_vaultbikes,
    vdoc,

    hids,
    qst_bdocs,
    qst_bdocs_ob,
    bikesob,

    skinids,
    qst_sdocs,
    qst_sdocs_ob,
    skinssob,

    skinsmapob,

    selhid,
    set_selhid,
    selskin,
    set_selskin,
    seltrail,
    set_seltrail,

    pageblockpopup,
    set_pageblockpopup,

    refresh_skin,
    refresh_bike,
  };
  return (
    <Vault3DContext.Provider value={vcon}>
      <Helmet>
        <title>{"My Vault 3D || FBike DNA"}</title>
      </Helmet>
      <PopUp openstate={pageblockpopup !== false} overlayclose={false}>
        <Card className={"max-w-[95vw]"}>
          <Loader01c />
          <p className="my-2 font-digi resp-text--1 text-acc0">
            {pageblockpopup}
          </p>
        </Card>
      </PopUp>
      <div className="h-page overflow-hidden">
        <div className="xs:hidden md:block">
          <div className="grid grid-rows-[9fr,3fr]  grid-cols-12 max-w-[99.9vw] w-full h-[96vh] max-h-[100%] overflow-hidden mx-auto gap-2 p-2">
            <div className="col-start-1 col-end-4 h-full">
              <AssetsList />
            </div>
            <div className="col-start-4 col-end-13 h-full ">
              <Canvas3D />
            </div>
            <div className="col-start-1 col-end-13 h-full">
              {qissuccesss(qo_vaultbikes) && <BottomRoller />}
            </div>
          </div>
        </div>
        <div className="xs:block md:hidden">
          <div className="h-[25vh]">
            {qissuccesss(qo_vaultbikes) && <BottomRoller />}
          </div>
          <div className="h-[28vh] w-full">
            <Canvas3D />
          </div>
          <div className="h-[35vh] resp-my-2">
            <AssetsList />
          </div>
        </div>
      </div>
    </Vault3DContext.Provider>
  );
};

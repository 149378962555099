import {
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import _ from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAppContext, useNowContext } from "../../App";
import { Loader01c } from "../../components/anims";
import { PopUp, PopupCloseBtn } from "../../components/popup";
import { Card, Img, Tag } from "../../components/utilityComps";
import {
  qiserr,
  qissuccesss,
  q_factions_bingo_get,
  q_factions_bingo_history,
} from "../../queries/queries";
import { tablecn, elementmap, gendermap } from "../../utils/cn_map";
import { MoVariants } from "../../utils/motion_helper";
import {
  cdelay,
  from_time_mini,
  getv,
  iso_format,
  jparse,
  jstr,
  nano,
  nils,
} from "../../utils/utils";
import { useAuthContext } from "../../wrappers/AuthWrapper";

const BingoContext = createContext({});
export const useBingoContext = () => useContext(BingoContext);

const gen_facimg = (f) =>
  `https://dna-run-public.s3.us-east-2.amazonaws.com/factions-logos/${f}.png`;
const facmap = _.chain([
  "ordinem",
  "project-paragon",
  "root-prime",
  "the-divine-wind",
])
  .map((k) => {
    let v = _.chain(k).split("-").map(_.capitalize).join(" ").value();
    let img = gen_facimg(k);
    return [k, { name: v, img }];
  })
  .fromPairs()
  .value();

const LogsTable = ({ faction, t, task, p }) => {
  const { bdoc } = useBingoContext();
  const f = facmap[faction];
  return (
    <div class="w-full">
      <div class="fr-sc resp-gap-2 resp-p-1 border-b border-acc4/40">
        <span className="font-bold font-digi resp-text--1 text-acc4">
          {p.n}
        </span>
        <span>/</span>
        <span className="font-bold resp-text-1 font-digi">{t[0]}</span>
        <span className="resp-text-1 font-digi">{task?.name}</span>
        <div class="flex-1"></div>
        <div class="w-[3rem] aspect-[2/1]">
          <Img img={f.img} />
        </div>
        <span className="resp-text-0">{f?.name}</span>
      </div>
      {_.isEmpty(p.logs) ? null : (
        <div class="h-[25rem] overflow-auto w-full">
          <table className="thintdrowp4-table overflow-auto resp-text--2">
            <thead>
              <tr className="resp-text-0 font-digi text-acc4 text-center">
                <td>Race</td>
                <td>Agent</td>
                <td>Bike</td>
                <td>Vault</td>
              </tr>
            </thead>
            <tbody>
              {p.logs.map((l, idx) => {
                let ag = getv(bdoc, `agmap.${l.agid}`) || {};
                let h = getv(bdoc, `hsmap.${l.hid}`) || {};
                let v = getv(bdoc, `vsmap.${l.vault}`) || "";

                return (
                  <tr>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`/race/${l.rid}`}
                        className="fr-sc resp-gap-1 text-slate-300"
                      >
                        <span>#{idx + 1}</span>
                        <FontAwesomeIcon
                          icon={faLink}
                          className="resp-text-1"
                        />
                        <div class="fc-ss">
                          <span>Race</span>
                          <span className="resp-text--1 font-mono ">
                            {l.rid}
                          </span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <div class="fc-ss">
                        <span className="text-acc4 font-digi resp-text--1">
                          {ag.agent_name}
                        </span>
                        <div class="fr-sc">
                          <span>Ag#{l.agid}</span>
                          <span>-</span>
                          <span>{ag.rarity}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="fc-ss">
                        <span className="text-acc4 font-digi resp-text--1">
                          {h?.name}
                        </span>
                        <div class="fr-sc resp-gap-1">
                          <span>#{l.hid}</span>
                          <FontAwesomeIcon
                            className={elementmap[h.element]?.text}
                            icon={elementmap[h.element]?.icon}
                          />
                          <FontAwesomeIcon
                            className={gendermap[h.gender]?.text}
                            icon={gendermap[h.gender]?.icon}
                          />
                          <span>F{h.fno}</span>
                          <span className="capitalize">{h.type}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="fc-ss">
                        <span>{v}</span>
                        <span className="text-acc4 resp-text--3">
                          {l.vault}
                        </span>
                        <span className="w-max text-slate-300">
                          {iso_format(l.date)}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
const BingoTaskCard = ({ faction, t, task, p = {}, winhighlight }) => {
  const { bdoc } = useBingoContext();
  const [pop, set_pop] = useState(false);
  return (
    <div
      onClick={() => {
        if (nils(t)) return;
        set_pop(true);
      }}
      class={twMerge(
        "cursor-pointer",
        "bg-r2dark/80 rounded-md text-center resp-p-2 border-2 border-transparent",

        winhighlight
          ? "bg-orange-500/40 border-orange-500"
          : p.done
            ? "bg-r2reg/40 border-acc4"
            : "border-slate-600",
      )}
    >
      {nils(t) ? (
        <div class="fc-cc w-full h-full">
          <span>BLANK</span>
        </div>
      ) : (
        <div class="fc-cc resp-gap-2">
          <div class="fr-cc resp-gap-1">
            {p.done ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="resp-text-1 text-acc4"
              />
            ) : (
              <span class="text-acc4 resp-text--2">{p.n}</span>
            )}
            <span>/</span>
            <span class="resp-text-1">{t[0]}</span>
          </div>
          <span class="resp-text--1">{task?.name}</span>
        </div>
      )}
      <PopUp
        wrapcn={"top-[6rem]"}
        innercn={"translate-y-[0%]"}
        openstate={pop}
        overlayclose={true}
        onClose={() => set_pop(false)}
      >
        <Card className="w-[60rem] card-dark-bg backdrop-blur-md max-w-[98vw] mx-auto border border-acc4">
          <div class="fr-sc">
            <div class="flex-1"></div>
            <PopupCloseBtn
              closepopup={() => {
                set_pop(false);
              }}
            />
          </div>
          <LogsTable {...{ faction, t, task, p }} />
        </Card>
      </PopUp>
    </div>
  );
};

const BingoCard = ({ faction }) => {
  const { bdoc } = useBingoContext();
  const fmap = getv(bdoc, `factions_map.${faction}`) || {};

  const f = facmap[faction];
  const winmap = useMemo(() => {
    let winmap = !_.isEmpty(getv(bdoc, `winmap`))
      ? _.cloneDeep(getv(bdoc, `winmap`))
      : null;

    if (nils(winmap)) return null;
    if (winmap.faction !== faction) return null;

    winmap.matpath = jparse(winmap.matpath);
    if (!_.isEmpty(winmap.matpath))
      winmap.matpath = winmap.matpath.map(([i, j]) => `${i}-${j}`);
    // console.log("winmap", winmap);
    return winmap;
  }, [jstr(bdoc)]);

  return (
    <div class="w-full h-full resp-text--1 resp-gap-2 my-2">
      <div class="fr-cc resp-gap-2">
        <div class="w-[4rem] aspect-[2/1]">
          <Img img={f.img} className="" />
        </div>
        <p class="text-center resp-text-2 font-digi my-1 text-white">
          {f?.name}
        </p>
      </div>
      <div class="grid grid-cols-5 resp-gap-2 rounded-md ">
        {_.range(0, 5).map((i) => {
          return _.range(0, 5).map((j) => {
            let t = getv(bdoc, `card_layout.${i}.${j}`);
            let task = getv(bdoc, `tasksmap.${t?.[1]}`);
            let p = getv(fmap, `progress.${i}.${j}`);
            let winhighlight = _.isEmpty(winmap?.matpath)
              ? false
              : winmap.matpath.includes(`${i}-${j}`);
            return <BingoTaskCard {...{ faction, t, task, p, winhighlight }} />;
          });
        })}
      </div>
    </div>
  );
};

const BingoHistory = () => {
  const { history = [], set_id } = useBingoContext();
  const aucon = useAuthContext();
  const { vault } = aucon;
  const { now } = useNowContext();
  return (
    <Card className="w-full card-dark-bg border border-acc4 my-2 resp-text--2 overflow-auto">
      <div class="fr-sc">
        <p class="text-white font-digi resp-text-1">History</p>
      </div>

      <table class="thintdrowp4-table w-max mx-auto resp-text--1">
        <thead>
          <tr>
            <td>id</td>
            <td>Started</td>
            <td>Ended</td>
            <td>Winner</td>
          </tr>
        </thead>
        <tbody>
          {history.map((b) => {
            let faction = getv(b, "winmap.faction");
            return (
              <tr>
                <td>
                  <Tag
                    onClick={() => {
                      console.log("bingo:setid", b.id);
                      set_id(b.id);
                    }}
                    className="bg-acc4/40 -skew-x-12"
                  >
                    {b.id}
                  </Tag>
                </td>
                <td>{iso_format(b.starts_at)}</td>
                <td>
                  {b.ended == true ? (
                    <span>{iso_format(b.starts_at)}</span>
                  ) : (
                    <>
                      {nano(b.starts_at) > now ? (
                        <span className="text-yellow-300">
                          Starts in {from_time_mini(b.starts_at)}
                        </span>
                      ) : (
                        <span className="text-yellow-300">Ongoing</span>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {b.ended == true && !nils(faction) ? (
                    <div className="fr-sc resp-gap-2 ">
                      <div class="w-[3rem] aspect-[2/1]">
                        <Img img={facmap[faction]?.img} />
                      </div>
                      <span>{facmap[faction]?.name}</span>
                    </div>
                  ) : (
                    <div>
                      <span>---</span>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const Rules = ({ bdoc }) => {
  // const { f } = useFQualContext();
  const rules = bdoc.rules || [];
  const title_rules = bdoc.title_rules || [];

  return (
    <>
      <>
        <div className="w-full">
          {_.isEmpty(title_rules) &&
            rules.map((c, i) => {
              return (
                <div className="flex flex-row justify-start items-start resp-gap-1 my-1">
                  <span className="resp-text-0">{i + 1}.</span>
                  <p className="resp-text-0 whitespace-pre flex-1">{c}</p>
                </div>
              );
            })}
        </div>
        <div className="w-full">
          <table className={twMerge(tablecn.table, "w-full")}>
            {title_rules.map(([a, b]) => (
              <tr className="thintdrow">
                <td className="resp-text-0 text-acc0">
                  <p className=" whitespace-pre-line resp-px-2 resp-text--2 font-digi">
                    {a}
                  </p>
                </td>
                <td className="resp-text-0">
                  <p className="whitespace-pre-line resp-px-2 resp-text--2 font-mon">
                    {b}
                  </p>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </>
    </>
  );
};

const Show_Drop = ({ show_txt = "show", children }) => {
  const [show, set_show] = useState(false);

  return (
    <Card className={"bg-r2lig/20 backdrop-blur-md resp-p-2 w-full"}>
      {show ? (
        <div class="fr-sc">
          <div class="flex-1"></div>
          <div onClick={() => set_show(false)} className="p-1 cursor-pointer">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            set_show(true);
          }}
          class="fr-sc resp-gap-2"
        >
          <p class="resp-text--1 flex-1">{show_txt}</p>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
      <motion.div
        variants={MoVariants.show_hide}
        initial="hidden"
        animate={show ? "visible" : "hidden"}
      >
        <>{children}</>
      </motion.div>
    </Card>
  );
};

export const FactionsBingo = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const [id, set_id] = useState("current");
  const [qobin, qobhis] = useQueries([
    q_factions_bingo_get(
      { id },
      { staleTime: 60 * 1e3, refetchInterval: 60 * 1e3 },
    ),
    q_factions_bingo_history({ id }, { staleTime: 2 * 60 * 1e3 }),
  ]);
  const bdoc = useMemo(() => getv(qobin, "data.result"), [qobin.dataUpdatedAt]);
  const history = useMemo(
    () => getv(qobhis, "data.result"),
    [qobhis.dataUpdatedAt],
  );
  const bcon = {
    id,
    set_id,
    bdoc,
    qobin,
    history,
  };

  return (
    <BingoContext.Provider value={bcon}>
      <div class="">
        {qobin.isLoading ? (
          <Loader01c />
        ) : qiserr(qobin) ? (
          <p class="text-center text-red-300">{qiserr(qobin)}</p>
        ) : qissuccesss(qobin) ? (
          <>
            {!nils(bdoc?.name) && (
              <p class="text-center text-acc0 resp-text-2 font-digi my-1 text-white">
                {bdoc?.name}
              </p>
            )}
            <p class="text-center resp-text--1 font-digi my-1 text-white">
              Bingo#{bdoc.id}
            </p>
            {!_.isEmpty(bdoc.title_rules) && (
              <div class="mx-auto xs:max-w-[98vw] lg:max-w-[40rem]">
                <Show_Drop show_txt="Show Rules">
                  <Rules bdoc={bdoc} />
                </Show_Drop>
              </div>
            )}
            <div class="grid xs:grid-cols-1 lg:grid-cols-2 gap-[4rem]">
              {_.keys(bdoc.factions_map).map((k) => {
                return <BingoCard {...{ faction: k, key: k }} />;
              })}
            </div>
          </>
        ) : null}

        <BingoHistory />
      </div>
    </BingoContext.Provider>
  );
};

import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router";
import qs from "query-string";
import { createContext } from "react";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import HomePage from "./views/HomePage.js";
import Layout, { clearSiteData } from "./components/Layout.js";
import Races from "./views/Races.js";
import AuthWrapper from "./wrappers/AuthWrapper.js";
import Login from "./views/Login.js";
import Vault from "./views/Vault.js";
import NoColor from "./wrappers/NoColor.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import RacePage from "./views/RacePage.js";
import Leaderboard from "./views/Leaderboard.js";
import { useQueries } from "react-query";
import { q_token_prices } from "./queries/queries.js";
import { copy_clip, getv, nils } from "./utils/utils.js";
import Ledger from "./views/Ledger.js";
import { HelmetProvider, Helmet } from "react-helmet-async";
import BikePage from "./views/BikePage.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Jackpot from "./views/Jackpot.js";
import { FQual, FQualActives } from "./views/FQual.js";
import Ranks from "./views/Ranks.js";
import { Vault3D } from "./views/Vault3D.js";
import Races_Special from "./views/Races_Special.js";
import SpliceArena, { SpliceArenaPage } from "./views/SpliceArena.js";
import SplicePage from "./views/SplicePage.js";
import { RacesNext } from "./views/RacesNext.js";
import {
  HalloweenSkinTeamsListPage,
  HalloweenSkinTeamsPage,
} from "./views/HaloweenSkinTeams.js";
import { SatellitePage, Satellites } from "./views/Satellite.js";
import Allowance from "./views/Allowance.js";
import { SatelliteV2Page, SatellitesV2 } from "./views/SatellitesV2.js";
import { BikesPage } from "./views/BikesPage.js";
import { MarketListingsPage } from "./views/MarketListings.js";
import { ClearCache } from "./views/ClearCache.js";
import TrophiesPage from "./views/Trophies.js";
import { MaidenSatellitePage, MaidenSatellites } from "./views/Maiden.js";
import Swap_DEZ_WETH from "./views/Swap_DEZ_WETH.js";
import { FinishedRacesPage } from "./views/FinishedRacesPage.js";
import { ArcadeRace } from "./views/ArcadeRace.js";
import { CreatorsPage } from "./views/Creators.js";
import { SpliceActivity } from "./views/SpliceActivity.js";
import HelpPage from "./views/HelpPage.js";
import { Inbox } from "./views/Inbox.js";
import { ClaimTrainerPage } from "./views/ClaimTrainerPage.js";
import { StakingPage } from "./views/StakingPage.js";
import { Season0Page } from "./views/Season0Page.js";
import { ListRallyPage, RallyPage } from "./views/Rally.js";
import { TokenConverterPage } from "./views/TokenConverter.js";
import { AutoFillerPage } from "./views/AutoFiller.js";
import { LCTokenSwapPage } from "./views/LCTokenSwap.js";
import { Season1Page } from "./views/Season1Page.js";
import { SponsorPage } from "./views/SponsorPage.js";
import Factions from "./views/Factions/Factions.js";
import { TestPage } from "./views/TestPage.js";
import { DigHStimmyPage } from "./views/DigHStimmyPage.js";
import { ChainPlayPage } from "./views/ChainPlayPage.js";
import { Season2Page } from "./views/Season2Page.js";
import { Scoreboard } from "./views/Scoreboard.js";
import QuestSelectPage from "./views/Quest/QuestSelect.js";
import { TrainerLeaderboard } from "./views/TrainerLeaderboard.js";
import RacingDashboard from "./views/RacingDashboard.js";
import { QuestCards } from "./views/QuestCards.js";
import { ThirdWebLoginWrapper } from "./views/ThirdWebLogin.js";
import { TrailsCardPage, TrailsCardsListPage } from "./views/TrailsCards.js";
import { ErrorBoundary } from "./utils/errbou.js";
import { Tag } from "./components/utilityComps.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);
// console.log("App.js");

const tokdecnmap = {
  WETH: 6,
  weth: 6,
  ETH: 6,
  DEZ: 0,
  USD: 2,
  BGC: 2,
  bgc: 2,
  "weth-mock01": 6,
  lc01: 6,
  lc02: 2,
  lc: 3,
  LC: 3,
};

export const tokdecn = (token) => tokdecnmap[token] ?? 2;
const tokedecn2map = {
  WETH: 3,
  weth: 3,
  ETH: 3,
  DEZ: 0,
  USD: 2,
  BGC: 2,
  bgc: 2,
  "weth-mock01": 3,
  lc01: 3,
  lc02: 2,
  lc: 2,
  LC: 2,
};
export const tokdecn2 = (token) => tokedecn2map[token] ?? 2;

const ErrorPage = () => {
  return (
    <div class="h-page">
      <div class="h-[5rem]"></div>
      <div class="fc-cc max-w-[95vw] mx-auto">
        <p class="resp-text-2">OH NO! Something went wrong!</p>
        <p class="resp-text-1">Please try again later.</p>
        <hr class="min-w-[50%] mx-auto my-[1rem]" />
        <p class="resp-text-1">Or is just cache messing up</p>
        <div class="fr-cc">
          <Tag
            onClick={() => {
              clearSiteData();
            }}
            className={"bg-red-500 -skew-x-12 font-digi"}
          >
            <span class="resp-text-1">Clear Cache</span>
          </Tag>
        </div>
      </div>
    </div>
  );
};

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tokmap = useMemo(
    () => getv(qo_tokpri, "data.result") || { ethusd: 0, dezusd: 0 },
    [qo_tokpri.dataUpdatedAt],
  );

  const tokkey = (c) =>
    c == "WETH"
      ? "ethusd"
      : c == "DEZ"
        ? "dezusd"
        : c == "BGC"
          ? "bgcusd"
          : null;
  const tok_to_usd_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(tokp) * parseFloat(cost);
  };
  const usd_to_tok_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(cost) / parseFloat(tokp);
  };

  useEffect(() => {
    let ref = psearch?.ref;
    if (nils(ref)) return;
    localStorage.setItem("ref", ref);
    console.log({ ref });
  }, [psearch?.ref]);

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,

    tokmap,
    tok_to_usd_val,
    usd_to_tok_val,
  };

  const maintenance = false;

  useEffect(() => {
    let body = document.querySelector("body");
    const fn = (e) => {
      if (e.altKey && e.code == "KeyC") {
        let href = window.location.href;
        href = href.replace(
          window.location.origin,
          "https://fbike.dnaracing.run",
        );
        copy_clip(href);
      }
    };
    document.addEventListener("keydown", fn);
    return () => {
      document.removeEventListener("keydown", fn);
    };
  }, []);

  return (
    <div className="App text-white">
      <div className="h-1"></div>
      <ErrorBoundary err_comp={<ErrorPage />}>
        <NowContext.Provider value={{ now }}>
          <AppContext.Provider value={appcon}>
            <MetaMaskWrapper>
              <ThirdWebLoginWrapper>
                <AuthWrapper>
                  <AccountWrapper>
                    <>
                      <Layout>
                        <HelmetProvider>
                          <Helmet>
                            <title>{"FBike || DNA Racing"}</title>
                          </Helmet>
                          {maintenance ? (
                            <div className="fc-cc text-red-300 my-[30vh] resp-gap-2">
                              <FontAwesomeIcon
                                className="text-[3rem]"
                                icon={faTriangleExclamation}
                              />
                              <p className="resp-text-2">
                                App Under Maintenance
                              </p>
                              <p className="resp-text-0">
                                We will be back soon
                              </p>
                            </div>
                          ) : (
                            <Routes>
                              <Route
                                path="/clear-cache"
                                exact
                                element={<ClearCache />}
                              />
                              <Route
                                path="/allowance"
                                exact
                                element={<Allowance />}
                              />
                              <Route path="/races" exact element={<Races />} />
                              <Route
                                path="/races-special-class"
                                exact
                                element={<Races_Special />}
                              />
                              <Route
                                path="/races-next"
                                exact
                                element={<RacesNext />}
                              />
                              <Route
                                path="/races-finished"
                                exact
                                element={<FinishedRacesPage />}
                              />
                              <Route
                                path="/race/:rid"
                                exact
                                element={<RacePage />}
                              />

                              <Route
                                path="/racing-dash/:vault"
                                exact
                                element={<RacingDashboard />}
                              />
                              <Route
                                path="/vault/:vault"
                                exact
                                element={<Vault />}
                              />
                              <Route
                                path="/my-vault-3d"
                                exact
                                element={<Vault3D />}
                              />
                              <Route
                                path="/market-listings"
                                exact
                                element={<MarketListingsPage />}
                              />
                              <Route
                                path="/cores"
                                exact
                                element={<BikesPage />}
                              />
                              <Route
                                path="/bike/:hid"
                                exact
                                element={<BikePage />}
                              />
                              <Route
                                path="/ledger"
                                exact
                                element={<Ledger />}
                              />
                              <Route
                                path="/jackpot"
                                exact
                                element={<Jackpot />}
                              />
                              <Route
                                path="/fqual/:fid"
                                exact
                                element={<FQual />}
                              />
                              <Route
                                path="/fqual"
                                exact
                                element={<FQualActives />}
                              />
                              <Route
                                path="/scoreboard"
                                exact
                                element={<Scoreboard />}
                              />

                              <Route path="/ranks" exact element={<Ranks />} />
                              <Route
                                path="/splice-arena"
                                exact
                                element={<SpliceArenaPage />}
                              />
                              <Route
                                path="/splice-activity"
                                exact
                                element={<SpliceActivity />}
                              />
                              <Route
                                path="/splice"
                                exact
                                element={<SplicePage />}
                              />
                              <Route
                                path="/satellites"
                                exact
                                element={<Satellites />}
                              />
                              <Route
                                path="/satellite/:mega_rid"
                                exact
                                element={<SatellitePage />}
                              />
                              <Route
                                path="/satellitesv2/:satid"
                                exact
                                element={<SatelliteV2Page />}
                              />
                              <Route
                                path="/satellitesv2"
                                exact
                                element={<SatellitesV2 />}
                              />
                              <Route
                                path="/maiden/:satid"
                                exact
                                element={<MaidenSatellitePage />}
                              />

                              <Route
                                path="/swap-dez-weth"
                                exact
                                element={<Swap_DEZ_WETH />}
                              />

                              <Route
                                path="/trophies/:vault"
                                exact
                                element={<TrophiesPage />}
                              />

                              <Route
                                path="/arcaderace/:rid"
                                exact
                                element={<RacePage />}
                              />
                              <Route
                                path="/arcaderace"
                                exact
                                element={<ArcadeRace />}
                              />

                              <Route
                                path="/creators"
                                exact
                                element={<CreatorsPage />}
                              />

                              <Route
                                path="/help"
                                exact
                                element={<HelpPage />}
                              />
                              <Route path="/inbox" exact element={<Inbox />} />
                              <Route
                                path="/staking/:vault"
                                exact
                                element={<StakingPage />}
                              />
                              <Route
                                path="/claim-trainer"
                                exact
                                element={<ClaimTrainerPage />}
                              />

                              <Route
                                path="/trainer-leaderboard"
                                exact
                                element={<TrainerLeaderboard />}
                              />
                              {/* sheet pages */}
                              <Route
                                path="/season0-page"
                                exact
                                element={<Season0Page />}
                              />
                              <Route
                                path="/season1-page"
                                exact
                                element={<Season1Page />}
                              />
                              <Route
                                path="/season2-page"
                                exact
                                element={<Season2Page />}
                              />
                              <Route
                                path="/chainplay"
                                exact
                                element={<ChainPlayPage />}
                              />
                              <Route
                                path="/rally/:ralid"
                                exact
                                element={<RallyPage />}
                              />
                              <Route
                                path="/rally"
                                exact
                                element={<ListRallyPage />}
                              />

                              <Route
                                path="/token-converter"
                                exact
                                element={<TokenConverterPage />}
                              />

                              <Route
                                path="/races-auto-filler"
                                exact
                                element={<AutoFillerPage />}
                              />

                              <Route
                                path="/lc-token-swap"
                                exact
                                element={<LCTokenSwapPage />}
                              />

                              <Route
                                path="/sponsor-package"
                                exact
                                element={<SponsorPage />}
                              />

                              <Route
                                path={`/factions/:vault`}
                                exact
                                element={<Factions />}
                              />

                              <Route
                                path={`/digh_stimmy`}
                                exact
                                element={<DigHStimmyPage />}
                              />

                              <Route
                                path="/quest-enter"
                                exact
                                element={<QuestSelectPage />}
                              />

                              <Route
                                path="/quest-cards"
                                exact
                                element={<QuestCards />}
                              />

                              <Route
                                path="/trails-cards"
                                exact
                                element={<TrailsCardsListPage />}
                              />

                              <Route
                                path="/trails-cards/:id"
                                exact
                                element={<TrailsCardPage />}
                              />

                              <Route
                                path="/test"
                                exact
                                element={<TestPage />}
                              />
                              <Route path="/" exact element={<HomePage />} />
                            </Routes>
                          )}
                        </HelmetProvider>
                      </Layout>
                    </>
                  </AccountWrapper>
                </AuthWrapper>
              </ThirdWebLoginWrapper>
            </MetaMaskWrapper>
            <NoColor />
          </AppContext.Provider>
        </NowContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;

import _ from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { Loader01c } from "../components/anims";
import { Card, Img, InpText, Tag } from "../components/utilityComps";
import {
  qiserr,
  q_bikeinfo,
  q_bikeinfoclear,
  q_bikename_set,
  q_bikename_validate,
  q_trainer_generate_trainer,
  q_trainer_parent_options,
  q_trainer_vault_stage,
  q_vault_claim_training_bike,
} from "../queries/queries";
import { metamaskimg } from "../utils/links";
import { cdelay, dec, getv, iso, jstr, nils } from "../utils/utils";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { Link, useNavigate } from "react-router-dom";
import { ContactTag, Footer, HomeBannerVideo } from "./HomePage.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper";
import { useLayoutContext } from "../components/Layout";
import {
  faTwitch,
  faTwitter,
  faDiscord,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckCircle,
  faCircle,
  faMapMarkedAlt,
  faQuestionCircle,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RectBtn, RectBtn_SMLong } from "../utils/raceutils2";
import { useAccountContext } from "../wrappers/AccountWrapper";
import { useQueries } from "react-query";
import { useMemo } from "react";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { twMerge } from "tailwind-merge";
import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightLong,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { BikeImg } from "../components/BikeImg";
import { colormap } from "../wrappers/colormap";
import { elementmap, gendermap } from "../utils/cn_map";

const mxstage = 7;

const SetTrainerName = ({ h, after_setting = () => {} }) => {
  const [edit, set_edit] = useState(false);
  const [name, set_name] = useState("");
  const [qvalid] = useQueries([
    q_bikename_validate(
      { name },
      {
        enabled: name.length > 3,
      },
    ),
  ]);
  const valid = getv(qvalid, "data.result.valid") && name.length > 3;
  const invalid_msg = getv(qvalid, "data.result.msg") ?? null;

  const [loading, set_loading] = useState(false);
  const set_name_fn = async () => {
    set_loading(true);
    try {
      let resp = await q_bikename_set({ hid: h.hid, name }).queryFn();
      await cdelay(2000);
      set_edit(false);
      await q_bikeinfoclear({ hid: h.hid }).queryFn();
      await cdelay(2000);
      await q_bikeinfo({ hid: h.hid }).queryFn();
      if (after_setting)
        after_setting({
          hid: h.hid,
          name: `Trainer ${name}`,
        });
    } catch (err) {}
    set_loading(false);
  };

  if (
    !(
      nils(h?.name) ||
      h.name == "Unnamed DNA Splice" ||
      h.name == "Unnamed Trainer"
    )
  ) {
    return (
      <div class="fr-sc my-2 resp-text-1">
        <span className="font-digi resp-text-1">{h.name}</span>
      </div>
    );
  }

  return (
    <div class="my-2">
      <div className="fr-sc flex-wrap resp-gap-4 mb-1">
        <InpText
          inpprops={{
            className: `resp-text--2 xs:w-[60vw] lg:w-[25rem] ${""} `,
          }}
          id={`inp_bikename:${h.hid}`}
          setter={(v) => {
            set_name(v);
          }}
        />
        <div className="flex-1"></div>
        {qvalid.isLoading ? (
          <>
            <FontAwesomeIcon className="text-acc4 spin-anim" icon={faSpinner} />
          </>
        ) : valid ? (
          <>
            <FontAwesomeIcon className="text-green-400" icon={faCheckCircle} />
            {loading ? (
              <Loader01c size="s" />
            ) : (
              <Tag
                onClick={() => {
                  set_name_fn();
                }}
                className="bg-acc0/40 resp-text--2 -skew-x-12"
              >
                Set Name
              </Tag>
            )}
          </>
        ) : (
          <FontAwesomeIcon className="text-red-400" icon={faTimesCircle} />
        )}
      </div>
      <div class="fr-sc">
        <div class="flex-1"></div>
        {!nils(invalid_msg) && (
          <p className="resp-text-1 text-red-400">{invalid_msg}</p>
        )}
      </div>
    </div>
  );
};

const ClaimSection = () => {
  const lcon = useLayoutContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const history = useNavigate();

  const { vault, auth = false } = aucon;
  const mmcon = useMetaMaskContext();
  const { refresh_bikeslist } = accon;

  const [resp, set_resp] = useState(null);
  const [data, set_data] = useState({});

  const start_claim = async () => {
    try {
      set_resp({ loading: true });
      await cdelay(1000);
    } catch (err) {
      set_resp({ loading: false, err: err.message });
    }
  };

  const [stage, set_stage] = useState(null);
  const go_prev = () => {
    if (stage == 1) return;
    set_stage(stage - 1);
  };
  const go_next = () => {
    set_stage(stage + 1);
  };

  const [qostage] = useQueries([
    q_trainer_vault_stage({ vault }, { enabled: true }),
  ]);
  const vstage = useMemo(() => {
    let v = getv(qostage, "data.result", { stage: 1 });
    set_stage(v?.stage);
    let dets = getv(qostage, "data.result.dets");
    if (!_.isEmpty(dets)) set_data((o) => ({ ...o, ...dets }));
    return v;
  }, [qostage.dataUpdatedAt]);
  const [qops] = useQueries([q_trainer_parent_options({})]);
  const ps = useMemo(() => {
    return getv(qops, "data.result", { dads: [], moms: [] });
  }, [qops.dataUpdatedAt]);

  const generate_trainer = async () => {
    try {
      set_resp({ status: "loading", msg: "Generating Trainer Bike..." });
      let re = await q_trainer_generate_trainer({
        dad: data.dad,
        mom: data.mom,
        vault,
      }).queryFn();
      if (re.err) throw new Error(re.err);
      if (nils(getv(re, "result.hid")))
        throw new Error("failed to generate trainer");

      console.log("response:trainer", re);

      set_resp({ status: "success", msg: `Generated Trainer Bike` });
      set_data((o) => ({ ...o, h_trainer: getv(re, "result.h") }));
    } catch (err) {
      console.log(err);
      set_resp({ status: "error", msg: err.message });
    }
  };

  const validate_next = useMemo(() => {
    if (stage == 1) return true;
    if (stage == 2) {
      if (nils(data.dad)) return false;
    } else if (stage == 3) {
      if (nils(data.mom)) return false;
    }
    if (stage == 4) {
      if (nils(data.h_trainer)) generate_trainer();
    }
    if (stage == 5) {
      if (
        nils(getv(data, "h_trainer.name")) ||
        ["Unnamed DNA Splice", "Unnamed Trainer"].includes(
          getv(data, "h_trainer.name"),
        )
      )
        return false;
    }
    if (stage == 6) {
      refresh_bikeslist();
      return false;
    }
    if (stage >= mxstage) return false;
    return true;
  }, [stage, jstr(data)]);

  const [pop, set_pop] = useState(false);
  const open_popup = async () => {
    await cdelay(0.5 * 1e3);
    set_pop(true);
  };
  const close_popup = () => {
    set_pop(false);
  };

  useEffect(() => {
    console.log("data", data);
  }, [jstr(data)]);
  useEffect(() => {
    console.log("resp", resp);
  }, [jstr(resp)]);

  const headcn =
    "w-full my-[2rem] text-center font-digi resp-text-2 text-white";

  return (
    <div className="fc-cc resp-gap-2 resp-text--1">
      <PopUp
        wrapcn={"xs:top-[1rem] lg:top-[5rem]"}
        innercn={"translate-y-[0%]"}
        openstate={pop}
      >
        <PopupCloseBtn {...{ closepopup: close_popup }} />
        <Card
          className={twMerge(
            "xs:w-[95vw] lg:w-[60rem] bg-r2dark/40 backdrop-blur-md border border-acc4 p-4 overflow-auto lg:h-[65vh] xs:h-[80vh]",
          )}
        >
          <div class="w-full fr-sc gap-1">
            {!nils(data.dad) && (
              <>
                <Tag
                  onClick={() => {
                    set_stage(1);
                  }}
                  className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc"
                >
                  <span className="resp-text--1 font-digi ">
                    DAD: {data.dad}-{data.dad_dptxt}
                  </span>
                </Tag>
              </>
            )}
            {!nils(data.mom) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag
                  onClick={() => {
                    set_stage(2);
                  }}
                  className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc"
                >
                  <span className="resp-text--1 font-digi ">
                    MOM: {data.mom}-{data.mom_dptxt}
                  </span>
                </Tag>
              </>
            )}

            {!nils(data.quest_type) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <p className={"text-white resp-text--1"}>{data.quest_type}</p>
                </Tag>
              </>
            )}

            {!nils(getv(data, "h_trainer.hid")) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <p className={"text-white resp-text--1"}>
                    Generated #{getv(data, "h_trainer.hid")}
                  </p>
                </Tag>
              </>
            )}
          </div>
          <div class="p-2 w-full fc-cc overflow-auto xs:min-h-[60vh] lg:min-h-[50vh] overflow-auto">
            {qostage.isLoading ? (
              <Loader01c size="s" />
            ) : qiserr(qostage) ? (
              <>
                <div class="fc-cc h-full">
                  <FontAwesomeIcon
                    className="text-[2rem] text-red-400"
                    icon={faTriangleExclamation}
                  />
                  <p className="resp-text-1 text-red-400">
                    Unexpeted Error Occured
                  </p>
                </div>
              </>
            ) : stage == -1 ? (
              <>
                <p className={twMerge(headcn, "text-red-400")}>
                  Trainer Expired
                </p>
                <p className="">
                  Trainer Bike: #{getv(data, "h_trainer.hid")}{" "}
                  {getv(data, "h_trainer.name")}
                </p>
                <p>has expired now after completing its lifeline</p>
              </>
            ) : stage == 1 ? (
              <>
                <div class="font-digi resp-text--1 fc-sc text-justify gap-2">
                  <p class="text-center resp-text-1 text-acc4">
                    Time to begin your DNA Journey
                  </p>
                  <p>
                    To start, we'll need to choose a{" "}
                    <span className="text-acc4">"Dad Bike"</span> and a
                    <span className="text-acc4">"Mom Bike"</span>. These bikes
                    will influence your new bike's performance and appearance.
                  </p>
                  <p>
                    <span className="text-acc4"> Performance:</span> The
                    combination of Dad and Mom bikes will determine the type of
                    races your bike excels in: short, mid, or long distances.
                  </p>
                  <p>
                    <span className="text-acc4">Appearance: </span>
                    The colors of the Dad and Mom bikes will help shape the
                    color of your new bike. There's a 33% chance of inheriting
                    each parent's color, or a 33% chance of getting a completely
                    random color.
                  </p>
                  <p>
                    Keep your choices in mind, as they'll impact your new bike's
                    unique characteristics.
                  </p>
                </div>
              </>
            ) : stage == 2 ? (
              <>
                <p className={headcn}>
                  Select a<span className="text-blue-400">{" DAD "}</span>
                  Bike
                </p>
                <div class="grid xs:grid-cols-2 lg:grid-cols-4 gap-4">
                  {!_.isEmpty(ps?.dads) &&
                    ps.dads.map((h) => {
                      let is_active = data?.dad == h.name;
                      return (
                        <div
                          onClick={async () => {
                            set_data((o) => ({
                              ...o,
                              dad: h.name,
                              dad_dptxt: h.dptxt,
                            }));
                            await cdelay(0.5 * 1e3);
                            go_next();
                          }}
                          style={{
                            border: `2px solid #${getv(h, "hex_code")}`,
                            background: is_active
                              ? `#${h.hex_code}`
                              : `${colormap["r2dark"]}80`,
                          }}
                          class={twMerge(
                            "cursor-pointer transition duration-300 w-[8rem] aspect-[1/1] rounded-md  p-2",
                          )}
                        >
                          <div class="px-2">
                            <BikeImg
                              className={"xs:w-full lg:w-full aspect-[1/1]"}
                              hex_code={is_active ? "ffffff" : h.hex_code}
                            />
                          </div>
                          <div class="">
                            <p class="text-white font-digi resp-text--1 text-center resp-my-2">
                              {h.name}
                            </p>
                            <p class="text-white font-digi resp-text--1 uppercase text-center resp-my-2">
                              {h.dptxt}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div class="fc-ss resp-text--1 font-digi resp-gap-2 p-2 mt-2">
                  <p>
                    Select a DAD and MOM bike to create your new BIKE. Your
                    parents' bikes influence your bike's racing performance and
                    color
                  </p>
                  <p>
                    <span className="text-acc4">Short DAD + Short MOM:</span>
                    Short-distance bike (1000-1400 meters)
                  </p>
                  <p>
                    <span className="text-acc4">Short DAD + Long MOM:</span>
                    Mid-distance bike (1400-1800 meters)
                  </p>
                  <p>
                    <span className="text-acc4">Long DAD + Short MOM:</span>
                    Mid-distance bike (1400-1800 meters)
                  </p>
                  <p>
                    <span className="text-acc4">Long DAD + Long MOM:</span>
                    Long-distance bike (2000-2200 meters)
                  </p>
                  <p>
                    <span class="text-acc4">Bike color:</span>
                    33% chance of DAD's color, 33% chance of MOM's color, 33%
                    chance of ANY color.
                  </p>
                </div>
              </>
            ) : stage == 3 ? (
              <>
                <p className={headcn}>
                  Select a<span className="text-pink-400">{" MOM "}</span>
                  Bike
                </p>

                <div class="grid xs:grid-cols-2 lg:grid-cols-4 gap-4">
                  {!_.isEmpty(ps?.moms) &&
                    ps.dads.map((h) => {
                      let is_active = data?.mom == h.name;
                      return (
                        <div
                          onClick={async () => {
                            set_data((o) => ({
                              ...o,
                              mom: h.name,
                              mom_dptxt: h.dptxt,
                            }));
                            await cdelay(0.5 * 1e3);
                            go_next();
                          }}
                          style={{
                            border: `2px solid #${getv(h, "hex_code")}`,
                            background: is_active
                              ? `#${h.hex_code}`
                              : `${colormap["r2dark"]}80`,
                          }}
                          class={twMerge(
                            "cursor-pointer transition duration-300 w-[8rem] aspect-[1/1] rounded-md  p-2",
                          )}
                        >
                          <div class="px-2">
                            <BikeImg
                              className={"xs:w-full lg:w-full aspect-[1/1]"}
                              hex_code={is_active ? "ffffff" : h.hex_code}
                            />
                          </div>
                          <div class="">
                            <p class="text-white font-digi resp-text--1 text-center resp-my-2">
                              {h.name}
                            </p>
                            <p class="text-white font-digi resp-text--1 uppercase text-center resp-my-2">
                              {h.dptxt}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div class="fc-ss resp-text--1 font-digi resp-gap-2 p-2 mt-2">
                  <p>
                    Select a DAD and MOM bike to create your new BIKE. Your
                    parents' bikes influence your bike's racing performance and
                    color
                  </p>
                  <p>
                    <span className="text-acc4">Short DAD + Short MOM:</span>
                    Short-distance bike (1000-1400 meters)
                  </p>
                  <p>
                    <span className="text-acc4">Short DAD + Long MOM:</span>
                    Mid-distance bike (1400-1800 meters)
                  </p>
                  <p>
                    <span className="text-acc4">Long DAD + Short MOM:</span>
                    Mid-distance bike (1400-1800 meters)
                  </p>
                  <p>
                    <span className="text-acc4">Long DAD + Long MOM:</span>
                    Long-distance bike (2000-2200 meters)
                  </p>
                  <p>
                    <span class="text-acc4">Bike color:</span>
                    33% chance of DAD's color, 33% chance of MOM's color, 33%
                    chance of ANY color.
                  </p>
                </div>
              </>
            ) : stage == 4 ? (
              <>
                {nils(resp) ? null : resp.status == "loading" ? (
                  <>
                    <p className={headcn}>Generating Trainer Bike</p>
                    <Loader01c />
                  </>
                ) : resp.status == "success" ? (
                  <>
                    <p className="resp-text-1 text-acc4 font-digi">
                      Here is your new bike
                    </p>
                    <div class="grid grid-cols-2 w-full max-w-[40rem] mx-auto p-2 gap-4">
                      <div class="">
                        <BikeImg
                          className={"xs:w-full lg:w-full aspect-[1/1]"}
                          hex_code={getv(data, "h_trainer.hex_code")}
                        />
                      </div>
                      <div class="fc-cc gap-2 resp-text-1">
                        <div class="fr-sc gap-2">
                          <span class="min-w-[8rem]">Element</span>
                          <FontAwesomeIcon
                            className={twMerge(
                              "resp-text-2",
                              getv(
                                elementmap,
                                `${getv(data, "h_trainer.element")}.text`,
                              ),
                            )}
                            icon={getv(
                              elementmap,
                              `${getv(data, "h_trainer.element")}.icon`,
                            )}
                          />
                          <span
                            className={twMerge(
                              "font-digi",
                              getv(
                                elementmap,
                                `${getv(data, "h_trainer.element")}.text`,
                              ),
                            )}
                          >
                            {_.upperFirst(getv(data, "h_trainer.element"))}
                          </span>
                        </div>

                        <div class="fr-sc gap-2">
                          <span class="min-w-[8rem]">Gender</span>
                          <FontAwesomeIcon
                            className={twMerge(
                              "resp-text-2",
                              getv(
                                gendermap,
                                `${getv(data, "h_trainer.gender")}.text`,
                              ),
                            )}
                            icon={getv(
                              gendermap,
                              `${getv(data, "h_trainer.gender")}.icon`,
                            )}
                          />
                          <span
                            className={twMerge(
                              "font-digi",
                              getv(
                                gendermap,
                                `${getv(data, "h_trainer.gender")}.text`,
                              ),
                            )}
                          >
                            {_.upperFirst(getv(data, "h_trainer.gender"))}
                          </span>
                        </div>

                        <div class="fr-sc gap-2">
                          <span class="min-w-[8rem]">Color</span>
                          <FontAwesomeIcon
                            className={twMerge(
                              "resp-text--1",
                              getv(
                                gendermap,
                                `${getv(data, "h_trainer.gender")}.text`,
                              ),
                            )}
                            icon={faCircle}
                          />
                          <span className={twMerge("font-digi")}>
                            {_.upperFirst(getv(data, "h_trainer.color"))}
                          </span>
                        </div>
                        <div class="fr-cc">
                          <Tag
                            onClick={() => {
                              go_next();
                            }}
                            className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc"
                          >
                            <span className="resp-text-2 font-digi">
                              Give it a Name
                            </span>
                          </Tag>
                        </div>
                      </div>
                    </div>
                  </>
                ) : resp.status == "error" ? (
                  <>
                    <p className="text-red-400">
                      {resp?.msg ? jstr(resp?.msg) : ""}
                    </p>
                  </>
                ) : null}
              </>
            ) : stage == 5 ? (
              <>
                <p className="resp-text-1 text-acc4 font-digi">
                  Give your bike an amazing name
                </p>
                <SetTrainerName
                  {...{
                    h: getv(data, "h_trainer"),
                    after_setting: ({ hid, name }) => {
                      set_data((o) => ({
                        ...o,
                        h_trainer: { ...o.h_trainer, hid, name },
                      }));
                      go_next();
                    },
                  }}
                />
              </>
            ) : stage == 6 ? (
              <>
                <p className="resp-text-1 text-acc4 font-digi">
                  Congratulations!!
                </p>
                <p className="resp-text-1 font-digi resp-text-1 text-white">
                  You have a new Trainer Bike.
                </p>
                <p className="resp-text-1 font-digi resp-text-1 text-white">
                  #{getv(data, "h_trainer.hid")} {getv(data, "h_trainer.name")}
                </p>
                <div class="h-[0.1rem] my-[1rem] bg-white/40 mx-auto w-[60%]"></div>
                <p
                  onClick={() => {
                    go_next();
                  }}
                  className="cursor-pointer resp-text-1 underline underline-acc0 text-acc0 font-digi resp-text-1"
                >
                  Click here to race now
                </p>
              </>
            ) : stage == 7 ? (
              <div className="fc-cc resp-gap-2 font-digi resp-text-1">
                <p className="resp-text-1 my-2 text-acc4 font-digi">
                  Life Goals
                </p>
                <p>Your journey to the Trainer Tourney starts here:</p>
                <p>
                  Qualify for our trainer tourney and claim your share of cash
                  and glory
                </p>
                <Tag
                  onClick={() => {
                    history("/trainer-leaderboard?tab=races");
                  }}
                  className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc"
                >
                  <span className="resp-text-1 font-digi">
                    Start Racing Now
                  </span>
                </Tag>
              </div>
            ) : null}
          </div>
          <div class="cursor-pointer fr-sc w-full gap-2">
            <div
              onClick={() => {
                go_prev();
              }}
              class={twMerge(
                "cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]",
                stage > 1 ? "" : "opacity-0",
              )}
            >
              <FontAwesomeIcon
                className="xs:text-[1rem] lg:text-[3rem]"
                icon={faArrowLeftLong}
              />
            </div>

            <div class="flex-1"></div>
            {[...new Array(mxstage)].map((e, i) => {
              return (
                <div
                  class={twMerge(
                    "xs:w-[0.5rem] lg:w-[1rem] aspect-[1/1] rounded-full bg-white",
                    i < stage ? "" : "opacity-20",
                  )}
                ></div>
              );
            })}
            <div class="flex-1"></div>

            <div
              onClick={() => {
                if (!validate_next) return;
                go_next();
              }}
              class={twMerge(
                "cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]",
                validate_next ? "" : "opacity-0",
              )}
            >
              <FontAwesomeIcon
                className="xs:text-[1rem] lg:text-[3rem]"
                icon={faArrowRightLong}
              />
            </div>
          </div>
        </Card>
      </PopUp>

      <div class="fr-sc resp-gap-1">
        {resp?.loading && <Loader01c size="s" />}
        {true || auth === true ? (
          <div class="mx-auto w-max">
            <Tag
              id="claim-trainer-btn"
              onClick={async () => {
                if (!auth) lcon.set_loginpop(true);
                else open_popup();
              }}
              className="bg-r2lig/60 pulse-anim font-digi"
            >
              Claim Trainer Now
            </Tag>
          </div>
        ) : (
          <Tag
            onClick={() => lcon.set_loginpop(true)}
            className="italic text-acc0 underline-offset-4 underline-orange-400 text-orange-400 fr-sc resp-gap-1"
          >
            <div class="xs:w-[1rem] lg:w-[2rem]">
              <Img img={metamaskimg} />
            </div>
            <span>Connect to Metamask to Claim</span>
          </Tag>
        )}
      </div>
      {!nils(resp?.err) && <p className="text-red-400">{resp?.err}</p>}
      {!nils(resp?.msg) && <p className="text-acc0">{resp?.msg}</p>}
    </div>
  );
};

const jsxlink = (url, text) => {
  if (nils(text)) text = url;
  return (
    <Link to={url} className="text-acc0 underline-offset-4 underline-acc0 ">
      {text}
    </Link>
  );
};

const HowItWorks = () => {
  return (
    <div class="w-[50rem] mx-auto max-w-full resp-text--2">
      <p className="text-white font-digi resp-text-1 text-center resp-my-2">
        How it works?
      </p>
      {[
        "1. New users can claim a free bike. Remember, you can only claim once per wallet",
        "2. Unlock your competitive side! Every new bike is eligible to race in our Trainer Series Tournament, featuring exciting races and awesome cash prizes.",
        <>
          3.
          {jsxlink(
            "https://fbike.dnaracing.run/fqual?mybikes=false",
            " Go here ",
          )}
          Look for trainer series and click those, click scoring to see how the
          races are scored and click rules to see the details on the tourney.
        </>,
        <>
          Races for the trainer bikes have a yellow tag called Trainer series
          you can find them in the open frees tag in the{" "}
          {jsxlink(
            "https://fbike.dnaracing.run/races?c=90&f=eyJjbGFzcyI6WzkwXX0&peek",
            "main lobby here",
          )}{" "}
        </>,
        <>
          4. Trainer Bikes are eligible for all Trainer series tourneys, they
          are 100% free{" "}
        </>,
        <>
          5. Trainer bikes have 50 races before they automatically go to the
          burn pit! Use the races wisely.
        </>,
        <>
          6. Once you are ready to dive into DNA racing you have two options, A
          go to the{" "}
          {jsxlink(
            "https://market.dnaracing.run/",
            "market https://market.dnaracing.run/",
          )}{" "}
          and buy a core or B go to the{" "}
          {jsxlink("/splice-arena", "splicing (breeding)")} arena and match up a
          male and female from the arena to splice (breed) a new core.
        </>,
      ].map((t) => (
        <p class="resp-text--2 font-digi my-1">{t}</p>
      ))}
    </div>
  );
};

export const ClaimTrainerPage = () => {
  const { auth } = useAuthContext();

  return (
    <div class="h-page">
      <div class="h-[2rem]"></div>
      <Card className="w-[80rem] max-w-[95vw] mx-auto bg-r2lig/20 backdrop-blur-md border border-acc4">
        <div class="grid grid-cols-2">
          <div class="xs:col-span-2 lg:col-span-1">
            <div className="mx-auto xs:w-full lg:max-w-full aspect-[16/10]  bg-r2lig/20 backdrop-blur-md rounded-[2rem] border border-acc4 overflow-hidden">
              <HomeBannerVideo />
            </div>
          </div>
          <div class="xs:col-span-2 lg:col-span-1">
            <div class="resp-p-2">
              <p class="text-white font-digi resp-text-2 text-center resp-mb-4">
                Claim your FREE Trainer Bike Now!!
              </p>
              <div class="fc-cc resp-gap-2">
                {[
                  "Unleash your inner racer with a FREE Trainer Bike at DNA Racing!",
                  "Gear up for an adrenaline-pumping ride! We're offering you a FREE Trainer Bike to experience the electrifying world of DNA Racing.",
                ].map((t) => {
                  return (
                    <p class="resp-text--1 text-center px-2 font-digi">{t}</p>
                  );
                })}
                <ClaimSection />

                <hr className="w-[30rem] max-w-[90vw] mx-auto my-2" />

                {[
                  "This isn't just any bike; it's your ticket to 50 thrilling races against other speed demons. Hone your skills, compete for real cash prizes, and discover the exhilaration of DNA Racing!",
                  "No strings attached, just pure racing fun.",
                  "Ready to put your pedal to the metal? Claim your FREE Trainer Bike now!",
                ].map((t) => (
                  <p class="resp-text--1 font-digi">{t}</p>
                ))}
              </div>
              <div className="m-[0.5rem] h-1 w-full bg-white/40"></div>
            </div>
          </div>
          <div class="col-span-2 resp-p-2">
            <HowItWorks />
          </div>
          <div class="col-span-2">
            <div class="flex flex-row resp-text-1 justify-around items-center w-full resp-my-[1rem]">
              <Link
                to="https://www.youtube.com/watch?v=Ac6WADzXMEI"
                target={"_blank"}
              >
                <RectBtn cont_cn="xs:w-[10rem] lg:w-[20rem]">
                  <div class="fr-cc resp-gap-1 resp-text--1">
                    <FontAwesomeIcon icon={faYoutube} className="p-1" />
                    <span>Quick Video Tutorial</span>
                  </div>
                </RectBtn>
              </Link>
              <Link
                to="https://fbike.dnaracing.run/help?tab=getting%20started"
                target={"_blank"}
              >
                <RectBtn cont_cn="xs:w-[10rem] lg:w-[20rem]">
                  <div class="fr-cc resp-gap-1 resp-text--1">
                    <FontAwesomeIcon icon={faQuestionCircle} className="p-1" />
                    <span className="">Help Page</span>
                  </div>
                </RectBtn>
              </Link>
            </div>
          </div>
        </div>
      </Card>
      <Footer joinmsg="DNA Links" />
    </div>
  );
};

export const trainer_market_txt = (
  <>
    Once you are ready to dive into DNA racing you have two options, A go to the{" "}
    {jsxlink(
      "https://market.dnaracing.run/",
      "market https://market.dnaracing.run/",
    )}{" "}
    and buy a core or go to the{" "}
    {jsxlink("/splice-arena", "splicing (breeding)")} arena and match up a male
    and female from the arena to splice (breed) a new core.
  </>
);

export const FqualTrainerInfo = () => {
  return (
    <div className="w-full resp-p-2 resp-text--1">
      <p className="text-center">{trainer_market_txt}</p>
      <div class="h-[0.1rem] bg-white/60 max-w-[95vw] w-[30rem] mx-auto my-2"></div>
      <div className="grid xs:grid-cols-2 md:grid-cols-4 justify-center items-start p-2">
        <ContactTag
          link="https://www.twitch.tv/hale_end_ra"
          icon={faTwitch}
          txt="danshan11"
        />
        <ContactTag
          link="https://twitter.com/Spotonparts"
          icon={faTwitter}
          txt="@Spotonparts"
        />
        <ContactTag
          link={"https://discord.gg/j4k3NdY6St"}
          icon={faDiscord}
          txt={<>Discord</>}
        />
        <ContactTag
          link={"https://market.dnaracing.run"}
          icon={faMapMarkedAlt}
          txt={
            <div className="fc-cc">
              <span>DNA Market</span>
              <span className="resp-text--2 text-acc0">Buy A Core Now</span>
            </div>
          }
        />
      </div>
      <div class="h-[0.1rem] bg-white/60 max-w-[95vw] w-[30rem] mx-auto my-2"></div>
    </div>
  );
};

const MiniLinkTag = ({ link, icon, txt }) => {
  return (
    <Link
      to={link}
      className="fr-sc w-full resp-gap-2 resp-text--1 p-2 bg-dark/50 hover:bg-acc0/30 rounded-md"
    >
      <FontAwesomeIcon icon={icon} className="p-1" />
      <span>{txt}</span>
    </Link>
  );
};
export const RaceLinksTrainerInfo = () => {
  return (
    <div className="">
      <div className="grid xs:grid-cols-2 lg:grid-cols-2 gap-1 ">
        <MiniLinkTag
          link="https://www.twitch.tv/hale_end_ra"
          icon={faTwitch}
          txt="danshan11"
        />
        <MiniLinkTag
          link="https://twitter.com/Spotonparts"
          icon={faTwitter}
          txt="@Spotonparts"
        />
        <MiniLinkTag
          link={"https://discord.gg/j4k3NdY6St"}
          icon={faDiscord}
          txt={<>Discord</>}
        />
        <MiniLinkTag
          link={"https://market.dnaracing.run"}
          icon={faMapMarkedAlt}
          txt={<span>DNA Market</span>}
        />
      </div>
    </div>
  );
};

import { twMerge } from "tailwind-merge";
import { getv, jparse, nils } from "./utils.js";
import _ from "lodash";
import {
  faEarth,
  faFire,
  faHammer,
  faMars,
  faVenus,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import { cprofile_key } from "../wrappers/AccountWrapper.js";

const tclassmap = {
  1: [1, `Class 1`, "C1", "bg-c1"],
  2: [2, `Class 2`, "C2", "bg-c2"],
  3: [3, `Class 3`, "C3", "bg-c3"],
  4: [4, `Class 4`, "C4", "bg-c4"],
  5: [5, `Class 5`, "C5", "bg-c5"],
  72: [72, `Special Class`, "Special", "bg-c72"],

  30: [40, `Training`, "Tr", "bg-c30"],
  40: [40, `Arcade`, "Arcade", "bg-c40"],

  50: [50, "Claiming", "CLM", "bg-c50"],
  60: [60, "Satellites", "SAT", "bg-c60"],
  70: [70, `Tourney`, "Tour", "bg-c70"],
  80: [80, "Paid", "Paid", "bg-c80"],
  90: [90, "Frees", "FREE", "bg-c90 text-black"],
};
export const classes = _.keys(tclassmap);
export const class_text = (code, format = "t") => {
  format =
    (format == "t" && 2) ||
    (format == "c" && 0) ||
    (format == "T" && 1) ||
    null;
  return getv(tclassmap, `${code}.${format}`);
};

export const class_cn = (code, cn) => {
  return twMerge(getv(tclassmap, `${code}.${3}`), cn);
};

export const cb_cn = (cb) => {
  return cb <= 14 ? "text-acc2" : cb <= 18 ? "text-acc4" : "text-acc0";
};

const payout_cnob = {
  na: ["na", "bg-dark"],
  wta: ["WTA", "bg-purple-500/40"],
  "1v1": ["1 v 1", "bg-purple-500/40"],
  top2: ["TOP2", "bg-purple-500/40"],
  top3: ["TOP3", "bg-purple-500/40"],
  top4: ["TOP4", "bg-purple-500/40"],
  dblup: ["DBL UP", "bg-teal-500/40"],
  dblup6: ["DBL UP", "bg-teal-500/40"],
  dblup12: ["DBL UP", "bg-teal-500/40"],
  pity: ["PITY", "bg-pink-600/40"],
  pitylow: ["PITY-LOW", "bg-pink-600/40"],
  pity6: ["PITY", "bg-pink-600/40"],
  pity12: ["PITY", "bg-pink-600/40"],
  spin_n_go: ["SPIN N GO", "bg-pink-500/40"],
  lowspin: ["LOW SPIN", "bg-yellow-500/40"],
  pityspin: ["PITY SPIN", "bg-blue-500/40"],
  hybrid: ["HYBRID", "bg-orange-400/40"],
  rounds: ["-", "bg-transparent"],
};

export const get_payout_cn = (p) => {
  return getv(payout_cnob, `${p}.1`) || "bg-black";
};

export const get_payout_txt = (p) => {
  return getv(payout_cnob, `${p}.0`) || _.upperCase(p);
};

export const cbs = [
  ...[9, 11, 13, 19, 21, 23],
  ...[10, 12, 14, 16, 18, 20, 22],
  ...[15, 17],
];
// export const cb_cn = (code, cn) => {
//   return twMerge("bg-acc0 bg-opacity-30", cn);
// };
export const cb_txt = (cb, rvmode = "bike") => {
  if (!cb) return "--";
  const prefix =
    rvmode == "bike"
      ? "CB"
      : rvmode == "horse"
        ? "CH"
        : rvmode == "car"
          ? "CC"
          : "";
  return `${prefix}${cb}`;
};

export const cb_cn_hex = (cb) => {
  let hex =
    cb <= 14
      ? get_cprofile_hex("r2lig")
      : cb <= 18
        ? get_cprofile_hex("acc4")
        : cb <= 20
          ? get_cprofile_hex("acc0")
          : null;
  if (nils(hex)) return "";
  else return hex.slice(1);
};

export const tablecn = {
  td: "border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[5rem]",
  th: "border-b border-acc0/40 resp-px-2 resp-py-1 text-center xs:min-w-[1rem] lg:min-w-[5rem] text-acc0 font-bold",
  tr: "tdrow",
  table: "border-b border-slate-500 resp-my-2 mx-auto",
};

export const pos_txt = (p) => {
  return (
    (p == 1 && `1st`) || (p == 2 && `2nd`) || (p == 3 && `3rd`) || `${p}th`
  );
};

export const pos_cn = (p) => {
  return p <= 3 ? `bg-pos${p} text-black` : "bg-slate-800";
};

export const elementmap = _.chain([
  ["water", faWater, "text-blue-400", "bg-blue-400"],
  ["earth", faEarth, "text-amber-700", "bg-orange-900"],
  ["fire", faFire, "text-orange-400", "bg-orange-400"],
  ["metal", faHammer, "text-slate-500", "bg-slate-500"],
])
  .map((e) => {
    return {
      element: e[0],
      icon: e[1],
      text: e[2],
      bg: e[3],
    };
  })
  .keyBy("element")
  .value();

export const gendermap = _.chain([
  ["male", faMars, "text-blue-400", "bg-blue-400"],
  ["female", faVenus, "text-pink-400", "bg-pink-900"],
])
  .map((e) => {
    return {
      gender: e[0],
      icon: e[1],
      text: e[2],
      bg: e[3],
    };
  })
  .keyBy("gender")
  .value();

export const rvmode_s = ["car", "bike", "horse"];

export const jtype_txt = (jtype) => {
  if (jtype == "minorpot") return "Minor Jackpot";
  else if (jtype == "majorpot") return "Major Jackpot";
  else if (jtype == "maxpot") return "Max Jackpot";
};

export const get_cprofile_hex = (k) => {
  let o = jparse(localStorage.getItem(cprofile_key), {});
  return getv(o, `${k}.hex`);
};

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { InpAutoWrap } from "../components/input.js";
import {
  base64_to_json,
  dec,
  emp,
  getv,
  json_to_base64,
  jstr,
  nils,
} from "../utils/utils.js";
import { useAppContext } from "../App.js";
import _ from "lodash";
import {
  class_cn,
  class_text,
  elementmap,
  gendermap,
  get_cprofile_hex,
  rvmode_s,
  tablecn,
} from "../utils/cn_map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
} from "../utils/filt.js";
import { racecn } from "./Races.js";
import { Card, HeadC2L, InpText, Tag } from "../components/utilityComps.js";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import { useQueries } from "react-query";
import {
  q_splicing_arena,
  qiserr,
  qissuccesss,
  q_splicing_bestpairs,
} from "../queries/queries.js";
import { Loader01 } from "../components/anims.js";
import {
  ElementTag,
  FNoTag,
  GenderTag,
  TypeTag,
} from "../components/ShortComps.js";
import { twMerge } from "tailwind-merge";
import {
  faChevronRight,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { active_cn_map } from "../utils/raceutils2.js";
import { RVImg } from "../components/BikeImg.js";

const SpliceArenaContext = createContext();
const useSpliceArenaContext = () => useContext(SpliceArenaContext);

const QuickSelect = () => {
  const history = useNavigate();
  const scon = useSpliceArenaContext();

  const [rvmode, set_rvmode] = useState(null);

  const costrangesob = {
    "<50": { name: "< $50" },
    "50-100": { name: "$50 - $100" },
    ">100": { name: "> $100" },
  };
  const costranges = _.keys(costrangesob);

  const qs = useQueries(
    costranges.map((c) => {
      return q_splicing_bestpairs(
        { costrange: c, rvmode },
        {
          enabled: !nils(rvmode),
        },
      );
    }),
  );
  const pairs = useMemo(() => {
    let ob = {};
    let idx = 0;
    for (let q of qs) {
      let costrange = costranges[idx++];
      ob[costranges] = [];
      if (qissuccesss(q)) {
        let ear = getv(q, "data.result");
        ob[costrange] = ear;
      }
    }
    return ob;
  }, [jstr(_.map(qs, (q) => q.dataUpdatedAt)), jstr(qs.map((q) => q.data))]);

  const select_pair = (costrange) => {
    if (_.isEmpty(pairs[costrange])) return;
    let ps = pairs[costrange];
    let rand = Math.floor(Math.random() * ps.length);
    let pair = ps[rand];
    if (pair) {
      console.log("costrange", pair);
      let toback = json_to_base64({
        url: window.location.pathname + window.location.search,
      });
      history(`/splice?male=${pair.fid}&female=${pair.mid}&toback=${toback}`);
    }
  };

  const c4 = get_cprofile_hex("acc4");
  const dark = get_cprofile_hex("r2dark");

  return (
    <div class="my-2 fc-cc w-full gap-1">
      <p class="resp-text-2 font-digi text-white text-center">Quick Select</p>

      <div class="fr-cc resp-gap-2 my-2">
        {rvmode_s.map((rv) => {
          let active = rvmode === rv;
          return (
            <div
              onClick={() => {
                set_rvmode(rv);
              }}
              className={twMerge(
                "cursor-pointer resp-p-2 h-full rounded-md",
                active ? "bg-acc4 shadow-acc0 shadow-md" : "bg-r2dark/20",
              )}
            >
              <RVImg
                {...{ rvmode: rv }}
                hex_code={!active ? c4 : "#FFFFFF"}
                // className={"xs:w-[4rem] lg:w-[8rem]"}
              />
            </div>
          );
        })}
      </div>
      <div
        class={twMerge(
          "fr-cc w-full gap-2",
          nils(rvmode) ? "grayscale opacity-50 cursor-not-allowed	" : "",
        )}
      >
        {costranges.map((c) => {
          return (
            <div
              onClick={() => select_pair(c)}
              class="w-[15rem] h-[3rem] fc-cc rounded-md cursor-pointer transition-all duration-300 bg-acc4/30 hover:bg-acc4/60 "
            >
              <p className="font-digi text-white resp-text-2 text-center w-full">
                {getv(costrangesob, `${c}.name`)}
              </p>
            </div>
          );
        })}
      </div>
      <p className="text-right  w-full text-acc4">
        {nils(rvmode) ? (
          <p className="mx-auto resp-text-0 lg:w-[50%] xs:w-[90%] text-center">
            Select Core Type to see pairings
          </p>
        ) : (
          <p className="mx-auto resp-text-0 lg:w-[50%] xs:w-[90%] text-center">
            Suggested {_.capitalize(rvmode ?? "core")} pairings based on racing
            data. Use at your own risk.
          </p>
        )}
      </p>
    </div>
  );
};

const FiltSection = () => {
  const scon = useSpliceArenaContext();
  const { filt, set_filt, clear_filt, searchtxt, set_searchtxt, my, set_my } =
    scon;

  const inpargs = {
    fkey: "splarena",
    filters: filt,
    set_filters: set_filt,
  };

  return (
    <Card
      className={
        "bg-r2lig/20 backdrop-blur-md border border-acc4 w-reg w-[98%] resp-px-4"
      }
    >
      <div className="w-full mx-auto fr-cc wrap max-w-full my-2">
        <div className="fr-cc w-full resp-gap-1 resp-px-1 resp-my-1">
          <div className="flex-1">
            <InpText
              {...{
                id: `search-splarena`,
                placeholder: "Search Core",
                inpprops: {
                  className: "resp-text-1 font-digi text-acc0 w-full",
                },
                contprops: {
                  className:
                    "resp-text-1 font-digi bg-r2dark/80 text-acc0 w-full",
                },
                setter: (v) => {
                  console.log("setter", v);
                  if (nils(v)) v = null;
                  set_searchtxt(v);
                },
              }}
            />
          </div>
        </div>{" "}
      </div>

      <div className="fc-cc w-full h-[5rem]">
        <HeadC2L>
          <span className="resp-text-2 text-white">OR</span>
        </HeadC2L>
      </div>

      <QuickSelect />

      <div className="fc-cc w-full h-[5rem]">
        <HeadC2L>
          <span className="resp-text-2 text-white">OR</span>
        </HeadC2L>
      </div>

      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "type" }} />
      </div>
      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "element" }} />
      </div>
      <div className="w-max mx-auto fr-cc wrap max-w-full">
        <InpAutoWrap {...{ ...inpargs, idd: "gender" }} />
      </div>
      {
        <>
          <div className="grid sm:grid-cols-2 md:sm:grid-cols-4">
            <div className="col-span-2">
              <p className="text-center font-digi text-slate-500 resp-my-1">
                F#No
              </p>
              <div className="w-max mx-auto">
                <InpAutoWrap {...{ ...inpargs, idd: "fno" }} />
              </div>
            </div>
            <div className="col-span-2">
              <p className="text-center font-digi text-slate-500 resp-my-1">
                Price [USD]
              </p>
              <div className="w-max mx-auto">
                <InpAutoWrap {...{ ...inpargs, idd: "price" }} />
              </div>
            </div>
          </div>
        </>
      }
      <div className="fr-sc">
        <Tag className="fr-sc resp-gap-1" onClick={() => set_my(!my)}>
          <FontAwesomeIcon
            className="resp-text-2"
            icon={my ? faToggleOn : faToggleOff}
          />
          <span className="resp-text-0">{"My"} Bikes</span>
        </Tag>
      </div>
    </Card>
  );
};

const HRow = ({ h }) => {
  const { fno, element, gender, type } = h || {};
  const { on_selection } = useSpliceArenaContext();
  const { pathname } = useLocation();
  return (
    <tr className="thintdrow resp-text--1">
      <td>
        <Link target="_blank" to={`/bike/${h.hid}`}>
          <div className="fr-sc resp-gap-1 xs:min-w-[8rem] lg:min-w-[15rem] font-digi text-white ">
            <span className="resp-text-1">{h.name}</span>
            <span className="resp-text--2">
              {" - "}
              {h.hid}
            </span>
          </div>
        </Link>
      </td>
      <td>
        <span>F{h.fno}</span>
      </td>
      <td>
        <span className="uppercase">{h.type}</span>
      </td>
      <td>
        <div
          className={twMerge("fr-sc resp-gap-1", elementmap[h.element]?.text)}
        >
          <FontAwesomeIcon icon={elementmap[h.element]?.icon} />
          <span className="text-white">{h.element}</span>
        </div>
      </td>
      <td>
        <div className={twMerge("fr-sc resp-gap-1", gendermap[h.gender]?.text)}>
          <FontAwesomeIcon icon={gendermap[h.gender]?.icon} />
          <span className="text-white">{h.gender}</span>
        </div>
      </td>
      <td>
        <Tag className={twMerge("text-acc0")}>
          <span className="resp-text-1">${dec(h.price_usd, 2)}</span>
        </Tag>
      </td>
      <td>
        {pathname == "/splice-arena" ? (
          <Link to={`/splice?${h.gender}=${h.hid}`}>
            <Tag className="border border-r2lig text-r2lig">
              <FontAwesomeIcon icon={faChevronRight} />
            </Tag>
          </Link>
        ) : (
          <Tag
            onClick={() => on_selection(h.hid)}
            className="border border-r2lig text-r2lig fr-sc"
          >
            <span>{"Select "}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>
        )}
      </td>
    </tr>
  );
};

const ViewList = () => {
  const scon = useSpliceArenaContext();
  const { hs = [] } = scon;
  return (
    <Card
      className={
        "w-full bg-r2lig/20 backdrop-blur-md border border-acc4 overflow-auto"
      }
    >
      <table className={twMerge(tablecn.table, "w-full table-basic-border")}>
        <thead className="text-center text-r2lig resp-text--1">
          <tr>
            <td>Name</td>
            <td>F.No</td>
            <td>Type</td>
            <td>Element</td>
            <td>Gender</td>
            <td>Price[USD]</td>
          </tr>
        </thead>
        <tbody className={""}>
          {hs.map((h) => {
            return <HRow h={h} key={h.hid} />;
          })}
        </tbody>
      </table>
    </Card>
  );
};

function SpliceArena({ on_selection, init_filt = {}, update_href = true }) {
  const appcon = useAppContext();
  const { s_acc_config, g_acc_config } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  // Filters
  const basefilt = useMemo(() => {
    let f = psearch.f;
    f = base64_to_json(f);
    f = { ...f, ...init_filt };
    f = f ?? {};
    if (_.isEmpty(f)) {
      let cac_f = g_acc_config("splarena.basefilt", {});
      f = cac_f;
    }
    return f;
  }, [psearch]);

  const [searchtxt, set_searchtxt] = useState(null);
  const [my, set_my] = useState(basefilt?.my == "true");

  const [filt, set_filt] = useState({
    type: {
      type: "options",
      path: "type",
      cfilter: true,
      options: ["genesis", "morphed", "freak", "xclass"],
      vals: basefilt?.type,
      txt_fn: (o) => {
        return _.upperCase(_.kebabCase(o));
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.default,
    },
    element: {
      type: "options",
      path: "element",
      cfilter: true,
      options: ["metal", "fire", "earth", "water"],
      vals: basefilt?.element,
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={elementmap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      label: false,
      show_label: false,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.element,
    },
    gender: {
      type: "options-only-ar",
      path: "gender",
      cfilter: true,
      label: "gender",
      show_label: false,
      options: _.keys(gendermap),
      filter_exception: [],
      vals: _.compact(basefilt?.gender),
      txt_fn: (o) => {
        return (
          <div className="fr-sc resp-gap-1">
            <FontAwesomeIcon icon={gendermap[o].icon} />
            <span>{_.upperCase(o)}</span>
          </div>
        );
      },
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${mainfiltbtn} lg:min-w-[7rem] xs:min-w-[3rem] w-max`,
      active_cn: active_cn_map.gender,
    },
    price: {
      type: "range",
      path: "price",
      cfilter: true,
      label: "Price [in WETH]",
      show_label: false,
      options: [2, 6, 12],
      filter_exception: [],
      vals: emp(basefilt?.price)
        ? { mi: undefined, mx: undefined }
        : basefilt?.price,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem] font-mon`,
    },
    fno: {
      type: "range",
      path: "fno",
      cfilter: true,
      label: "F,No",
      show_label: false,
      filter_exception: [],
      vals: emp(basefilt?.fno)
        ? { mi: undefined, mx: undefined }
        : basefilt?.fno,
      cont_cn: "justify-center",
      inner_cont_cn: "justify-center",
      base_cn: `${racecn.mainfiltbtn} min-w-[7rem] font-mon`,
    },
  });

  const valfilt = useMemo(() => {
    let valfilt = gen_valob_from_filters(filt);
    s_acc_config("splarena.basefilt", valfilt);
    return valfilt;
  }, [filt]);

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const rem = useMemo(() => {
    if (update_href == true) {
      let rem = {};
      rem.f = json_to_base64(valfilt);
      rem.search = searchtxt;
      rem.my = my;
      upd_psearch(rem);
      return rem;
    }
  }, [jstr(valfilt)]);

  const { vault } = useAccountContext();

  const [q_filt] = useQueries([
    q_splicing_arena({
      ...(my == true ? { vault } : {}),
      ...valfilt,
      search: searchtxt,
    }),
  ]);
  const hs = useMemo(() => {
    if (qissuccesss(q_filt)) return getv(q_filt, "data.result", []);
    else return [];
  }, [q_filt.dataUpdatedAt]);

  const scon = {
    filt,
    set_filt,
    clear_filt,
    searchtxt,
    set_searchtxt,

    hs,

    on_selection,

    my,
    set_my,
  };

  return (
    <SpliceArenaContext.Provider value={scon}>
      <FiltSection />

      <div className="h-[2rem]"></div>

      {q_filt.isLoading ? (
        <Loader01 />
      ) : qiserr(q_filt) ? (
        <p className="text-center text-red-400">{qiserr(q_filt)}</p>
      ) : _.isEmpty(hs) ? (
        <p className="text-center">No Bikes Found in Splice Arena</p>
      ) : (
        <ViewList />
      )}
    </SpliceArenaContext.Provider>
  );
}

export const SpliceArenaPage = () => {
  const pagetitle = "Splice Arena";

  return (
    <>
      <Helmet>
        <title>{pagetitle}</title>
      </Helmet>
      <div className="h-page">
        <div className="max-w-[98vw] w-[60rem] mx-auto">
          <div className="h-[2rem]"></div>
          <div className="">
            <p className="text-center text-white font-digi resp-text-2">
              Splice Arena
            </p>
          </div>
          <SpliceArena />
        </div>
      </div>
    </>
  );
};

export default SpliceArena;
